import "./PrimeQK.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, soundEffect } from "../GameCommand";

import { Button, Chip, MenuItem, Select } from "@material-ui/core/";

import { Random, RandomBig, shuffle } from "../lib/xs";

interface Setting {
  seat: string[];
  orderRandom: boolean;
}

const settingDef = [{ kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true }];

interface Card {
  suit: string;
  power: number; // 1～13
}

type CardID = number;

const suits = ["Spade", "Club", "Heart", "Diamond"]; //+Jocker

interface Player {
  state: string;
  name: string;
  hand: CardID[];
  draw: boolean;
}

interface Factor {
  kind: string; //Card, Mul, Exp
  card: number;
}

interface FieldSet {
  field: CardID[];
  factor: Factor[];
  jocker: number[];
}

interface FieldResult {
  player: number;
  result: string;
  valid: boolean;
  cut: boolean;
  revo: boolean;
}

const initFS = { field: [], factor: [], jocker: [0, 0] };
const FSContext = React.createContext({} as FieldSet);

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  cards: Card[];
  player: Player[];
  deck: number[];
  deckReserve: number[];
  lastField: FieldSet;
  lastResult: FieldResult;
  fixField: FieldSet;
  playNum: number;
  revo: boolean;
  turnPlayer: number;
  trickPlayer: number;
  playOrder: number[];
  wait: any[];
}

const GameContext = React.createContext({} as Game);

interface Play {
  table: string;
  step: number;
  action: string;
}

interface UI {
  selectPrime: boolean;
  fs: FieldSet;
}

function initUI(): UI {
  return { selectPrime: true, fs: { ...initFS } };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function UICheck(game, dispatch) {
  dispatch({ type: "Init" });
}

function UIReducer(state: UI, action: any): UI {
  // console.log("Reducer", state, action);
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "SelectPrime":
      nUI.selectPrime = action.select;
      break;

    case "Field":
      nUI.fs.field = nUI.fs.field.includes(action.select) ? nUI.fs.field.filter((s) => s !== action.select) : nUI.fs.field.concat(action.select);
      break;

    case "AddFactor":
      nUI.fs.factor.push(action.factor);
      break;

    case "DelFactor":
      nUI.fs.factor = nUI.fs.factor.filter((_, i) => i !== action.i);
      break;

    case "Jocker":
      nUI.fs.jocker[action.i] = action.jocker;
      break;

    case "Init":
      return initUI();
  }
  // console.log("nUI", nUI);
  return nUI;
}

function sortPrimeQK(a: Card, b: Card): number {
  if (a.suit === "Jocker" && b.suit === "Jocker") return a.power - b.power;
  if (a.suit === "Jocker") return 1;
  if (b.suit === "Jocker") return -1;
  if (a.power !== b.power) return a.power - b.power;
  else return suits.indexOf(a.suit) - suits.indexOf(b.suit);
}

function card2filename(c: Card): string {
  let name = "";
  if (c.suit === "Jocker") name = "jk0";
  else name = ["s", "c", "h", "d"][suits.indexOf(c.suit)] + ("00" + c.power).slice(-2);
  return process.env.PUBLIC_URL + "/trump/" + name + ".png";
}

function nextPlayer(game: Game, base: number): number {
  let npi: number = game.playOrder.indexOf(base);
  npi = (npi + 1) % game.player.length;
  return game.playOrder[npi];
}

function getCardPower(game: Game, fs: FieldSet, c: CardID) {
  return game.cards[c].suit === "Jocker" ? fs.jocker[game.cards[c].power] : game.cards[c].power;
}

function getFieldStr(game: Game, fs: FieldSet) {
  const str = fs.field.map((c) => getCardPower(game, fs, c)).join("") + "n";
  return str;
}

function getFactorStr(game: Game, fs: FieldSet): string {
  const str = fs.factor.map((f) => (f.kind === "Card" ? getCardPower(game, fs, f.card).toString() : f.kind === "Mul" ? "n*" : "n**")).join("") + "n";
  return str;
}

function validField(game: Game, fs: FieldSet) {
  if (game.fixField.field.length !== 0) {
    if (game.fixField.field.length !== fs.field.length) return { result: false, reason: game.fixField.field.length + "枚になるよう選択してください" };
  } else if (fs.field.length === 0) return { result: false, reason: "1枚以上選択してください" };
  const str = getFieldStr(game, fs);
  if (fs.field.length === 1 && game.cards[fs.field[0]].suit === "Jocker") {
    if (fs.factor.length === 0) {
      return { result: true, reason: "ジョーカー1枚出し" };
    } else {
      return { result: false, reason: "ジョーカー1枚出しの時は合成数場に出さないでください" };
    }
  }
  const found = str.match("(^0)|\\*0");
  if (found !== null) return { result: false, reason: "最上位桁を0にすることはできません" };
  if (game.fixField.field.length !== 0) {
    const fixNum = calc(getFieldStr(game, game.fixField));
    if (fixNum === -1n) {
      return { result: false, reason: "扱える数を超えています" };
    }
    if (game.revo) {
      if (game.fixField.field.length !== 0 && fixNum <= calc(str)) return { result: false, reason: fixNum + "より小さい数を選択してください" };
    } else {
      if (game.fixField.field.length !== 0 && fixNum >= calc(str)) return { result: false, reason: fixNum + "より大きい数を選択してください" };
    }
  }
  return { result: true, reason: str };
}

function validFactor(game: Game, fs: FieldSet) {
  if (fs.factor.length === 0) return { result: true, reason: "" };
  const str = getFactorStr(game, fs);
  let found = str.match("^(\\d+n)((\\*|\\*\\*)(\\d+n))+$");
  if (found === null) return { result: false, reason: "数x数 数^数の式にしてください" };
  found = str.match("\\*\\*(0|1)n");
  if (found !== null) return { result: false, reason: "指数を0または1にすることはできません" };
  found = str.match("(^0)|\\*0");
  if (found !== null) return { result: false, reason: "最上位桁を0にすることはできません" };

  return { result: true, reason: str };
}

function getFieldStatus(game: Game, fs: FieldSet) {
  const v = validField(game, fs);
  if (!v.result) return v.reason;
  let ns = v.reason;
  ns = ns.replace(RegExp("n", "g"), "");
  return ns;
}

function getFactorStatus(game: Game, fs: FieldSet) {
  const v = validFactor(game, fs);
  if (!v.result) return v.reason;
  let ns = v.reason;
  ns = ns.replace(RegExp("n", "g"), "");
  ns = ns.replace(RegExp("\\*\\*", "g"), "^");
  ns = ns.replace(RegExp("\\*", "g"), "x");
  return ns;
}

function getFSResult(game: Game, fs: FieldSet, player: number): FieldResult {
  if (fs.factor.length === 0) {
    //素数出し
    if (fs.field.length === 1 && game.cards[fs.field[0]].suit === "Jocker")
      return { valid: true, result: "ジョーカー1枚出し", cut: true, revo: false, player: player };
    const fieldNum = calc(getFieldStr(game, fs));
    if (fieldNum === -1n) return { valid: false, result: "扱える数を超えています", cut: false, revo: false, player: player };
    if (fieldNum === 57n) return { valid: true, result: "グロタンディーク素数切り", cut: true, revo: false, player: player };
    if (fieldNum === 1729n) return { valid: true, result: "ラマヌジャン革命", cut: false, revo: true, player: player };
    if (isPrime(fieldNum)) return { valid: true, result: fieldNum + "は素数", cut: false, revo: false, player: player };
    return { valid: false, result: fieldNum + "は素数ではない(" + factorize(fieldNum) + ")", cut: false, revo: false, player: player };
  }
  //合成数出し
  const fieldNum = calc(getFieldStr(game, fs));
  const factorStr = getFactorStr(game, fs);
  const factorNum = calc(factorStr);
  if (fieldNum !== factorNum) {
    if (factorNum === -1n) {
      return { valid: false, result: "素因数場の計算結果が大きすぎます", cut: false, revo: false, player: player };
    }
    return { valid: false, result: "素因数場の計算結果" + factorNum + "は" + fieldNum + "ではない", cut: false, revo: false, player: player };
  }
  const facts = factorStr.replace(RegExp("\\*\\*\\d+n", "g"), "");
  const found = facts.match(RegExp("\\d+", "g"));
  const notPrime = found?.map((f) => BigInt(f)).find((f) => !isPrime(f));
  if (notPrime !== undefined)
    return {
      valid: false,
      result: "素因数の" + notPrime + "は素数ではない(" + factorize(notPrime) + ")",
      cut: false,
      revo: false,
      player: 0,
    };

  return { valid: true, result: fieldNum + "の合成数出し", cut: false, revo: false, player: player };
}

function calc(str: string): bigint {
  const funcStr = "return " + str + ";";
  // eslint-disable-next-line
  const func = new Function(funcStr);
  let ret = 0n;
  try {
    ret = func();
  } catch {
    ret = -1n;
    console.log("calc error");
  }
  return ret;
}

// function isPrime_old(num: bigint): boolean {
//   if (num <= 1n) return false;
//   if (num === 2n) return true;
//   if (num % 2n === 0n) return false;

//   for (let i = 3n; i * i <= num; i += 2n) {
//     if (num % i === 0n) return false;
//   }

//   return true;
// }

// a^n%mod
function modPow(a: bigint, n: bigint, mod: bigint) {
  let ret = 1n;
  while (n > 0n) {
    if (n & 1n) ret = (ret * a) % mod;
    a = (a * a) % mod;
    n >>= 1n;
  }
  return ret;
}

function isPrime(n: bigint): boolean {
  if (n <= 1n) return false;
  if (n === 2n) return true;
  if (n % 2n === 0n) return false;

  let d = n - 1n;
  let s = 0n;
  while (d % 2n === 0n) {
    s++;
    d /= 2n;
  }

  let rand = new RandomBig();
  let k = 1000;
  for (let i = 0; i < k; i++) {
    const a = rand.nextBig(1n, n - 1n);
    let y = modPow(a, d, n);
    if (y === 1n) continue;
    let notComposit = false;
    for (let r = 0n; r < s; r++) {
      if (y === n - 1n) {
        notComposit = true;
        break;
      }
      y = (y * y) % n;
    }
    if (notComposit) continue;
    return false;
  }

  return true;
}

function factorize(num: bigint): string {
  if (num <= 1n) return num.toString();
  if (num > 10000000000000000000n) return "巨大なため素因数計算は省略";
  let ret: string[] = [];
  for (let i = 2n; i * i <= num; i++) {
    let j = 0;
    while (num % i === 0n) {
      num /= i;
      j++;
    }
    if (j > 0) ret.push(j === 1 ? i.toString() : i.toString() + "^" + j);
  }
  if (num !== 1n) ret.push(num.toString());
  return ret.join("x");
}

function CCard(props: { c: CardID; class?: string; options?: any }) {
  const game = useContext(GameContext);
  const fs = useContext(FSContext);
  return (
    <div className={"Card " + (props.class ?? "")} {...props.options}>
      <img src={card2filename(game.cards[props.c])} alt="Card" />
      {game.cards[props.c].suit === "Jocker" && <div className="JockerPower">{fs.jocker[game.cards[props.c].power]}</div>}
    </div>
  );
}

function CFactor(props: { f: Factor; options?: any }) {
  return (
    <div className="Factor" {...props.options}>
      {props.f.kind === "Card" && <CCard c={props.f.card} />}
      {props.f.kind === "Mul" && <div className="Ope">×</div>}
      {props.f.kind === "Exp" && <div className="Ope">^</div>}
    </div>
  );
}

function OtherPlayer(props: any) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  const c = props.id === game.turnPlayer ? "OtherTurn" : "Other";
  return (
    <div key={info.name} className={c}>
      <div className="Name">{info.name}</div>
      {info.state === "Win" && "[あがり]"}
      {info.state === "Play" && <div>手札{info.hand.length}</div>}
    </div>
  );
}

function CFixField() {
  const game = useContext(GameContext);
  return (
    <div className="Field">
      <div>
        <div>
          {"場　　　"}
          {game.revo && <Chip label="革命中" />}
        </div>
      </div>
      <FSContext.Provider value={game.fixField}>
        <div className="Cards">
          {game.fixField.field.map((f) => (
            <CCard key={f} c={f} />
          ))}
        </div>
      </FSContext.Provider>
    </div>
  );
}

function CLastField() {
  const game = useContext(GameContext);

  return (
    <div className="LastField" style={{ visibility: game.lastField.field.length > 0 ? "visible" : "hidden" }}>
      <div>{game.player[game.lastResult.player].name + "が出したカード"}</div>
      <div>{game.lastResult.result}</div>
      <FSContext.Provider value={game.lastField}>
        <div className="Field">
          <div>場</div>
          <div className="Cards">
            {game.lastField.field.map((f) => (
              <CCard key={f} c={f} />
            ))}
          </div>
        </div>
        <div className="Field">
          <div>素因数場</div>
          <div className="Cards">
            {game.lastField.factor.map((f, i) => (
              <CFactor key={i} f={f} />
            ))}
          </div>
        </div>
      </FSContext.Provider>
    </div>
  );
}

function CPreField() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);

  return (
    <div className="Pre" style={{ visibility: game.state === "Game" && game.turnPlayer === game.you ? "visible" : "hidden" }}>
      <FSContext.Provider value={ui.fs}>
        <div className={"Field" + (ui.selectPrime ? " Selected" : "")} onClick={() => uiDispatch({ type: "SelectPrime", select: true })}>
          <div>場に出す {"　　" + getFieldStatus(game, ui.fs)}</div>
          <div className="Cards">
            {ui.fs.field.map((f) => (
              <CCard key={f} c={f} options={{ onClick: () => uiDispatch({ type: "Field", select: f }) }} />
            ))}
          </div>
        </div>
        <div className={"Field" + (!ui.selectPrime ? " Selected" : "")} onClick={() => uiDispatch({ type: "SelectPrime", select: false })}>
          <div>素因数場に出す {"　　" + getFactorStatus(game, ui.fs)}</div>
          <div className="Cards">
            {ui.fs.factor.map((f, i) => (
              <CFactor key={i} f={f} options={{ onClick: () => uiDispatch({ type: "DelFactor", i: i }) }} />
            ))}
          </div>
        </div>
      </FSContext.Provider>
    </div>
  );
}

function CPlayer() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const info = game.player[game.you];
  const hand = info.hand
    .filter((h) => !ui.fs.field.includes(h) && ui.fs.factor.every((f) => f.card !== h))
    .sort((a, b) => sortPrimeQK(game.cards[a], game.cards[b]));

  const mul = { kind: "Mul", card: -1 };
  const exp = { kind: "Exp", card: -1 };

  function getOp(c: CardID, i: number) {
    if (game.state === "Game" && game.turnPlayer === game.you) {
      if (ui.selectPrime) {
        return { onClick: () => uiDispatch({ type: "Field", select: c }) };
      } else {
        return { onClick: () => uiDispatch({ type: "AddFactor", factor: { kind: "Card", card: c } }) };
      }
    }
    return {};
  }
  return (
    <div>
      <div>
        <div>{info.name}</div>
      </div>
      <FSContext.Provider value={ui.fs}>
        <div className="Hand Cards">
          {hand.map((c, i) => (
            <CCard key={c} c={c} options={getOp(c, i)} />
          ))}
          {!ui.selectPrime && <CFactor f={mul} options={{ onClick: () => uiDispatch({ type: "AddFactor", factor: mul }) }} />}
          {!ui.selectPrime && <CFactor f={exp} options={{ onClick: () => uiDispatch({ type: "AddFactor", factor: exp }) }} />}
          {game.turnPlayer === game.you &&
            info.hand
              .filter((c) => game.cards[c].suit === "Jocker")
              .sort((a, b) => sortPrimeQK(game.cards[a], game.cards[b]))
              .map((c, i) => (
                <div key={c + "Jocker"} className="JockerUI">
                  <div>ジョーカー{i + 1}</div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ui.fs.jocker[game.cards[c].power]}
                    onChange={(evt) => uiDispatch({ type: "Jocker", i: game.cards[c].power, jocker: evt.target.value })}
                  >
                    {[...Array(14)].map((_, i) => (
                      <MenuItem value={i} key={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ))}
        </div>
      </FSContext.Provider>
    </div>
  );
}

function CMessage() {
  const game = useContext(GameContext);

  let txt = "";
  if (game.state === "Game" && game.turnPlayer === game.you) txt = "カードを選択してください";
  if (game.state === "Game" && game.turnPlayer !== game.you) txt = game.player[game.turnPlayer].name + "のターンです";
  if (game.state === "End") txt = game.player.find((p) => p.hand.length === 0)?.name + "の勝利!";
  return <div className="Message">{txt}</div>;
}

function getPlayOption(game: Game) {
  if (game.deckReserve.length === 0) {
    return { reserve: newDeck(game) };
  }
  return {};
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);

  const isTurn = game.turnPlayer === game.you;

  return (
    <div className="Action">
      {game.state === "Game" && (
        <>
          {isTurn && (
            <div>
              <Button
                onClick={() => {
                  play(game, { command: "Pass", player: game.you });
                }}
                variant="contained"
              >
                パス
              </Button>
            </div>
          )}
          {isTurn && game.player[game.you].draw && (
            <div>
              <Button
                onClick={() => {
                  play(game, { command: "Draw", player: game.you });
                }}
                variant="contained"
              >
                1枚引く
              </Button>
            </div>
          )}
          {validField(game, ui.fs).result && validFactor(game, ui.fs).result && (
            <div>
              <Button
                onClick={() => {
                  play(game, { command: "Play", player: game.you, fs: ui.fs, result: getFSResult(game, ui.fs, game.you), ...getPlayOption(game) });
                }}
                variant="contained"
              >
                場に出す
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  let others = [] as JSX.Element[];
  let otherId = nextPlayer(game, game.you);
  while (otherId !== game.you) {
    others.push(<OtherPlayer key={otherId} id={otherId} />);
    otherId = nextPlayer(game, otherId);
  }
  return (
    <div className="Game">
      <div className="Others">{others}</div>
      <div className="Fields">
        <div className="FieldLeft">
          <CFixField />
          <CPreField />
        </div>
        <CLastField />
      </div>
      <CPlayer />
      <CMessage />
      <CAction />
    </div>
  );
}

function initDeck(): Card[] {
  let deck = suits.flatMap((s) => [...Array(13)].map((_, i) => ({ suit: s, power: i + 1 })));
  deck.push({ suit: "Jocker", power: 0 });
  deck.push({ suit: "Jocker", power: 1 });
  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const sdeck = shuffle(
    [...Array(initDeck().length)].map((_, i) => i),
    r
  );
  return sdeck;
}

function newOrder(game: Game) {
  let order = game.setting.seat.map((_, i) => i);
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(order, r);
  } else {
    return order;
  }
}

function startGame(game: Game, sdeck: number[], order: number[]) {
  game.cards = initDeck();
  game.lastField = { ...initFS };
  game.fixField = { ...initFS };
  game.revo = false;
  const handN = game.setting.seat.length <= 4 ? 11 : 7;
  game.player = game.setting.seat.map((p, i) => ({ name: p, rank: 0, hand: sdeck.splice(0, handN), state: "Play", draw: true }));
  game.deck = sdeck;
  game.deckReserve = [];
  game.playOrder = order;
  game.turnPlayer = game.playOrder[0];
  game.playNum = 0;
  game.state = "Game";
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [], orderRandom: true },
    state: "Init",
    you: -1,
    cards: [],
    step: 0,
    deck: [],
    deckReserve: [],
    fixField: { ...initFS },
    lastField: { ...initFS },
    lastResult: { player: 0, cut: false, result: "", valid: false, revo: false },
    revo: false,
    player: [],
    turnPlayer: 0,
    trickPlayer: 0,
    playNum: 0,
    playOrder: [],
    wait: [],
  };
}

function draw(game: Game, player: number, dnum: number) {
  if (game.deck.length >= dnum) {
    game.player[player].hand = game.player[player].hand.concat(game.deck.splice(0, dnum));
  } else {
    const rest = dnum - game.deck.length;
    game.player[player].hand = game.player[player].hand.concat(game.deck);
    game.deck = game.deckReserve.filter((d) => game.player.every((p) => p.hand.every((h) => h !== d)) && !game.fixField.field.includes(d));
    game.deckReserve = [];
    game.player[player].hand = game.player[player].hand.concat(game.deck.splice(0, rest));
  }

  return game;
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  game = JSON.parse(JSON.stringify(game));

  console.assert(game.step < play.step, game.toString(), play);
  if (game.step >= play.step) return game;

  game.step++;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    game.state = "CloseGame";
    return game;
  }

  if (action.hasOwnProperty("reserve")) {
    game.deckReserve = action.reserve;
  }

  switch (game.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          game.setting = JSON.parse(action.setting);
          game.you = game.setting.seat.indexOf(game.env.name);
          return game;

        case "Start":
          startGame(game, action.sdeck, action.order);
          return game;

        default:
          console.error("Unknown State");
          break;
      }
      break;

    case "Game":
      switch (action.command) {
        case "Play":
          game.lastField = action.fs;
          game.lastResult = action.result;
          if (game.lastResult.valid) {
            game.player[action.player].hand = game.player[action.player].hand.filter(
              (h) => !game.lastField.field.includes(h) && game.lastField.factor.every((f) => f.card !== h)
            );
            game.fixField = game.lastField;
            game.trickPlayer = game.lastResult.player;
            if (game.player[action.player].hand.length === 0) {
              game.state = "End";
              return game;
            }
          } else {
            draw(game, action.player, game.lastField.field.length + game.lastField.factor.filter((f) => f.kind === "Card").length);
          }
          if (game.lastResult.revo) {
            game.revo = !game.revo;
          }

          if (game.lastResult.cut) {
            game.fixField = initFS;
          } else {
            game.turnPlayer = nextPlayer(game, game.turnPlayer);
            if (game.turnPlayer === game.trickPlayer) {
              game.fixField = initFS;
            }
          }
          game.player[game.turnPlayer].draw = true;
          game.playNum++;
          return game;

        case "Draw":
          game = draw(game, action.player, 1);
          game.player[action.player].draw = false;
          game.playNum++;
          return game;

        case "Pass":
          game.turnPlayer = nextPlayer(game, game.turnPlayer);
          if (game.turnPlayer === game.trickPlayer) {
            game.fixField = initFS;
          }
          game.player[game.turnPlayer].draw = true;
          game.playNum++;
          return game;
      }
      break;
  }
  console.error("Unknown State or Action");

  return game;
}

export const CPrimeQK = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useEffect(() => {
    console.log("Render", game);
  }, [game]);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    UICheck(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.playNum]);

  useEffect(() => {
    soundEffect();
  }, [game.playNum]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", sdeck: newDeck(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="PrimeQK">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="素数大富豪" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>素数大富豪</h2>
      <p>
        ゲームデザイン:
        <a href="https://sites.google.com/view/shinichiro-seki/" target="_blank" rel="noreferrer">
          関真一朗
        </a>
      </p>
      <p>
        素数大富豪は素数を作って遊ぶトランプゲームです。
        <br />
        基本的なルールは{" "}
        <a href="https://ja.wikipedia.org/wiki/%E7%B4%A0%E6%95%B0%E5%A4%A7%E5%AF%8C%E8%B1%AA" target="_blank" rel="noreferrer">
          素数大富豪 - Wikipedia
        </a>{" "}
        をご覧ください。
      </p>
      <div>
        <h4>TAGAでの素数大富豪</h4>
        ジョーカー2枚を含む、トランプ1セット54枚を使用します。
        <br />
        4人以下の場合は手札11枚、5人～7人の場合は手札7枚でスタートします。
        <br />
        素数出し、合成数出し、グロタンディーク素数切り（57）、ラマヌジャン革命（1729）に対応しています。
      </div>
      <div>
        <h4>制約</h4>
        素数判定に
        <a
          href="https://ja.wikipedia.org/wiki/%E3%83%9F%E3%83%A9%E3%83%BC%E2%80%93%E3%83%A9%E3%83%93%E3%83%B3%E7%B4%A0%E6%95%B0%E5%88%A4%E5%AE%9A%E6%B3%95"
          target="_blank"
          rel="noreferrer"
        >
          ミラー–ラビン素数判定法
        </a>
        (k=1000)を使用しています。
        <br />
        非常に低い確率で合成数を素数として判定することがあります。
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/primeqk/help1.png"} /> <br />
      </div>
      <div>
        <h4>操作方法</h4>
        <p>「場に出す」と「素因数場に出す」の枠内をクリックすると、出したい場を選択することができます。 </p>
        <p>手札のカードをクリックすると、選択された場の末尾に追加されます。</p>
        <p>場のカードをクリックすると、手札に戻ってきます。</p>
        <p>ジョーカーを何の数字として扱うかは、プルダウンメニューで変更できます。ジョーカー1枚出しの時は数字に関係なく最強(流れ)です。</p>
        <p>合成数出しの場合は、素因数場の式を[x][^]ボタンで作成してください。[x]は乗算、[^]はべき演算(指数表記)を表します。</p>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/primeqk/help2.png"} /> <br />
        <p>場に出せる場合は[場に出す]ボタンが表示されるので押してください。</p>
      </div>
    </div>
  );
}

export const PrimeQK = {
  name: "素数大富豪",
  id: "PrimeQK",
  people: [1, 7],
  settingDef: settingDef,
  component: CPrimeQK,
  help: CHelp,
};
