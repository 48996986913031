/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRoom = /* GraphQL */ `
  query GetRoom($id: String!) {
    getRoom(id: $id) {
      id
      pass
      game
      setting
      seed
      ttl
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $id: String
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRooms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pass
        game
        setting
        seed
        ttl
      }
      nextToken
    }
  }
`;
export const getRoomLog = /* GraphQL */ `
  query GetRoomLog($dummy: Int!, $createdAt: AWSDateTime!) {
    getRoomLog(dummy: $dummy, createdAt: $createdAt) {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const listRoomLogs = /* GraphQL */ `
  query ListRoomLogs(
    $dummy: Int
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelRoomLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoomLogs(
      dummy: $dummy
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dummy
        createdAt
        env
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlay = /* GraphQL */ `
  query GetPlay($table: String!, $step: Int!) {
    getPlay(table: $table, step: $step) {
      table
      step
      action
      ttl
    }
  }
`;
export const listPlays = /* GraphQL */ `
  query ListPlays(
    $table: String
    $step: ModelIntKeyConditionInput
    $filter: ModelPlayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlays(
      table: $table
      step: $step
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        table
        step
        action
        ttl
      }
      nextToken
    }
  }
`;
