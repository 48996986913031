/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      pass
      game
      setting
      seed
      ttl
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      pass
      game
      setting
      seed
      ttl
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      pass
      game
      setting
      seed
      ttl
    }
  }
`;
export const createRoomLog = /* GraphQL */ `
  mutation CreateRoomLog(
    $input: CreateRoomLogInput!
    $condition: ModelRoomLogConditionInput
  ) {
    createRoomLog(input: $input, condition: $condition) {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const updateRoomLog = /* GraphQL */ `
  mutation UpdateRoomLog(
    $input: UpdateRoomLogInput!
    $condition: ModelRoomLogConditionInput
  ) {
    updateRoomLog(input: $input, condition: $condition) {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const deleteRoomLog = /* GraphQL */ `
  mutation DeleteRoomLog(
    $input: DeleteRoomLogInput!
    $condition: ModelRoomLogConditionInput
  ) {
    deleteRoomLog(input: $input, condition: $condition) {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const createPlay = /* GraphQL */ `
  mutation CreatePlay(
    $input: CreatePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    createPlay(input: $input, condition: $condition) {
      table
      step
      action
      ttl
    }
  }
`;
export const updatePlay = /* GraphQL */ `
  mutation UpdatePlay(
    $input: UpdatePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    updatePlay(input: $input, condition: $condition) {
      table
      step
      action
      ttl
    }
  }
`;
export const deletePlay = /* GraphQL */ `
  mutation DeletePlay(
    $input: DeletePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    deletePlay(input: $input, condition: $condition) {
      table
      step
      action
      ttl
    }
  }
`;
