import React, { useState, useEffect } from "react";
import "./App.css";
import Amplify from "@aws-amplify/core";
import PubSub from "@aws-amplify/pubsub";
import API, { graphqlOperation } from "@aws-amplify/api";
import config from "./aws-exports";
import { ADs } from "./AD";

import { createRoom, updateRoom } from "./graphql/mutations";
import { listRooms } from "./graphql/queries";
import { onUpdateRoom } from "./graphql/subscriptions";

import { TextField, Button } from "@material-ui/core/";
import { Grid, Card, CardActions, CardContent, IconButton } from "@material-ui/core/";
import { FormControl, FormControlLabel, Radio, RadioGroup, Checkbox } from "@material-ui/core/";
import { PlayArrow } from "@material-ui/icons/";
import { People } from "@material-ui/icons/";
import { Add, Remove } from "@material-ui/icons/";

import Bar from "./Bar";
import { CHowTo, CAbout } from "./About";
import { GamesList, CGame, peopleString, CList } from "./Games";
import { Env, createGame, getTTL } from "./GameCommand";

Amplify.configure(config);
PubSub.configure(config);

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

interface AppProps {
  env: Env;
  setEnv: React.Dispatch<React.SetStateAction<Env>>;
}

function CTopPage(props: AppProps) {
  return (
    <div className="NormalMode">
      <NameMode {...props} />
      <hr />
      <ADs id="top1" />
      <hr />
      <CList />
      <hr />
      <CHowTo />
      <hr />
      <ADs id="top2" />
      <hr />
      <CAbout />
      <hr />
      <ADs id="top3" />
      <hr />
    </div>
  );
}

function NameMode(props: AppProps) {
  const [values, setValues] = React.useState({
    user: "",
    room: props.env.room,
    password: props.env.pass,
  });
  const [msg, setMsg] = React.useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log("values:", values);
  };

  function randomRoom() {
    const s = Math.random().toString(36).substring(2);
    setValues({ ...values, room: s });
  }
  console.log(values);

  const enterRoom = (evt: any) => {
    evt.preventDefault();
    //既存の部屋検索
    (API.graphql(graphqlOperation(listRooms, { id: values.room, filter: { pass: { eq: values.password } } })) as any)
      .then((res: any) => {
        console.log(res);
        if (res.data.listRooms.items.length === 0) {
          //部屋作成
          (
            API.graphql(
              graphqlOperation(createRoom, {
                input: { id: values.room, pass: values.password, game: "", setting: "", seed: -1, ttl: getTTL() },
                condition: { pass: { attributeExists: false } },
              })
            ) as any
          )
            .then((res: any) => {
              //部屋作成
              console.log("部屋作成", res);
              props.setEnv((s) => ({ ...s, name: values.user, room: values.room, pass: values.password }));
            })
            .catch((e: any) => {
              console.log(JSON.stringify(e));
              setMsg("パスワードが間違っています。");
            });
        } else {
          //部屋発見
          props.setEnv((s) => ({ ...s, name: values.user, room: values.room, pass: values.password }));
        }
      })
      .catch((e: any) => {
        console.log(e);
        setMsg("接続エラーです。");
      });
  };

  return (
    <>
      <div>
        <div>TAGAへようこそ！ TAGAはオンラインでボードゲームを遊ぶことができるサービスです。</div>
        <div>会員登録不要、無料で遊ぶことができます。</div>
        <div>現在は {GamesList.length}種類のゲームが実装されています。</div>
        <br />
        <br />
        <form onSubmit={enterRoom}>
          ユーザ名と部屋情報を入力してください。
          <br />
          部屋が無い場合は自動で作成されます。
          <br />
          <br />
          <div>
            <TextField
              id="taga-user-name"
              variant="outlined"
              label="ユーザ名"
              required
              autoComplete="taga username"
              onChange={handleChange("user")}
            />
          </div>
          <br />
          <div>
            <TextField
              id="taga-room-name"
              variant="outlined"
              label="部屋名"
              required
              autoComplete="taga roomname"
              value={values.room}
              onChange={handleChange("room")}
              disabled={props.env.room !== ""}
            />
            {props.env.room === "" && (
              <Button onClick={randomRoom} variant="contained">
                ランダム生成
              </Button>
            )}
          </div>
          <br />
          <div>
            <TextField
              id="taga-password"
              variant="outlined"
              type="password"
              label="部屋パスワード"
              required
              defaultValue={values.password}
              autoComplete="taga roompass"
              onChange={handleChange("password")}
              disabled={props.env.pass !== ""}
            />
          </div>
          <br />
          <Button type="submit" variant="contained">
            決定
          </Button>
        </form>
        <div>{msg}</div>
      </div>
    </>
  );
}

function createSetting(gameName: string) {
  const game = GamesList.find((g) => g.id === gameName) ?? GamesList[0];
  let setting = {};
  Object.defineProperty(setting, "seat", { value: [...Array(game.people[0])].map(() => ""), enumerable: true, writable: true });
  game.settingDef.forEach((def) => {
    Object.defineProperty(setting, def.id, { value: def.default, enumerable: true, writable: true });
  });
  console.log(setting, JSON.stringify(setting));
  return setting;
}

function getInviteUrl(env: Env) {
  let url = new URLSearchParams();
  url.append("room", env.room);
  url.append("pass", env.pass);
  return window.location.origin + "/invite/?" + url.toString();
}

function CGameSelect(env: Env) {
  const createGame = (game: string) => {
    (
      API.graphql(
        graphqlOperation(updateRoom, { input: { id: env.room, game: game, setting: JSON.stringify(createSetting(game)), ttl: getTTL() } })
      ) as any
    )
      .then((res: any) => {
        console.log(res);
      })
      .catch((e: any) => {
        console.log(e);
        alert("error");
      });
  };

  return (
    <div className="NormalMode">
      <p>
        部屋 {env.room} に居ます　
        <Button onClick={() => navigator.clipboard.writeText(getInviteUrl(env))} variant="contained">
          招待用URLをコピー
        </Button>
      </p>
      <p>プレイするゲームを選択してください。</p>
      <Grid container spacing={1}>
        {GamesList.map((game) => (
          <Grid item key={game.name}>
            <Card style={{ width: 150, height: 250 }}>
              <CardContent>
                <div>
                  <b>{game.name}</b>
                </div>
                <div>
                  <img className="GameIcon" alt="Icon" src={"/icon/" + game.id + ".png"} />
                </div>
                <div>
                  <People />
                  {peopleString(game)}
                </div>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => createGame(game.id)}>
                  <PlayArrow />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <hr />
      <ADs id="room1" />
    </div>
  );
}

function CGameSetting(env: Env) {
  const game = GamesList.find((g) => g.id === env.game) ?? GamesList[0];
  let setting = JSON.parse(env.setting);
  const you = setting.seat.indexOf(env.name);
  console.log(setting);

  function exitGame() {
    (API.graphql(graphqlOperation(updateRoom, { input: { id: env.room, game: "", ttl: getTTL() } })) as any)
      .then((res: any) => {
        console.log(res);
      })
      .catch((e: any) => {
        console.log(e);
        alert("error");
      });
  }

  function changeSetting() {
    (API.graphql(graphqlOperation(updateRoom, { input: { id: env.room, setting: JSON.stringify(setting), ttl: getTTL() } })) as any)
      .then((res: any) => {
        console.log(res);
      })
      .catch((e: any) => {
        console.log(e);
        alert("error");
      });
  }

  function addPlayer() {
    if (setting.seat.length < game.people[1]) {
      setting.seat.push("");
      changeSetting();
    }
  }
  function subPlayer() {
    if (setting.seat.length > game.people[0]) {
      setting.seat.pop();
      changeSetting();
    }
  }
  function getSeat(i: number) {
    setting.seat[i] = env.name;
    changeSetting();
  }
  function leaveSeat(i: number) {
    setting.seat[i] = "";
    changeSetting();
  }
  function changeBoolean(id: string) {
    setting[id] = !setting[id];
    changeSetting();
  }
  function changeChoice(id: string, select: string) {
    setting[id] = select;
    changeSetting();
  }
  function start() {
    const seed = getRandomInt(1 << 30);
    createGame({ ...env, seed: seed });
  }

  return (
    <>
      <div className="NormalMode">
        <div>{game.name}</div>
        <div className="PlayerSetting">
          {game.people[0] !== game.people[1] && (
            <div>
              プレイ人数
              <IconButton onClick={subPlayer}>
                <Remove />
              </IconButton>
              <IconButton onClick={addPlayer}>
                <Add />
              </IconButton>
            </div>
          )}
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            {setting.seat.map((p, i) => (
              <Grid item key={i}>
                <Card style={{ width: 150 }}>
                  <CardContent>
                    プレイヤー{i + 1} <br />
                    {p === "" ? "空席" : p}
                  </CardContent>
                  <CardActions>
                    {p === "" && you === -1 && (
                      <Button onClick={() => getSeat(i)} variant="contained">
                        着席
                      </Button>
                    )}
                    {you === i && (
                      <Button onClick={() => leaveSeat(i)} variant="contained">
                        離席
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          {game.settingDef.length > 0 && (
            <>
              <div>オプション</div>
              {game.settingDef.map((def) =>
                def.kind === "boolean" ? (
                  <div key={def.id}>
                    <FormControlLabel
                      control={<Checkbox checked={setting[def.id]} onChange={() => changeBoolean(def.id)} name={def.id} />}
                      label={def.name}
                    />
                  </div>
                ) : (
                  <div key={def.id}>
                    <FormControl margin="dense" component="fieldset">
                      {def.name}
                      <RadioGroup
                        aria-label="seet-order"
                        name="seet-order"
                        value={setting[def.id]}
                        row
                        onChange={(event) => changeChoice(def.id, event.target.value)}
                      >
                        {def.choice?.map((c) => (
                          <FormControlLabel value={c.id} control={<Radio />} label={c.name} key={c.id} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )
              )}
            </>
          )}
        </div>
        {setting.seat.every((p) => p !== "") && you !== -1 && (
          <div>
            <Button onClick={start} variant="contained">
              ゲーム開始
            </Button>
          </div>
        )}
        <div>
          <Button onClick={exitGame} variant="contained" style={{ marginTop: 100 }}>
            一覧に戻る
          </Button>
        </div>
      </div>
      <hr />
      <ADs id="Setting1" />
      <hr />
      {game.help(env)}
      <hr />
      <ADs id="Setting2" />
    </>
  );
}

function RoomMode(props: AppProps) {
  const [roomEnv, setRoomEnv] = useState(props.env);

  useEffect(() => {
    const updateEnv = (room: any) => {
      setRoomEnv((s) => ({ ...s, game: room.game, setting: room.setting, seed: room.seed }));
    };

    (API.graphql(graphqlOperation(listRooms, { id: props.env.room })) as any)
      .then((res: any) => {
        console.log("listRooms", JSON.stringify(res));
        if (res.data.listRooms.items.length !== 0) {
          updateEnv(res.data.listRooms.items[0]);
        } else {
          alert("error");
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    const subscription = (API.graphql(graphqlOperation(onUpdateRoom, { id: props.env.room })) as any).subscribe({
      next: (msg: any) => {
        console.log("subscription fired", msg);
        updateEnv(msg.value.data.onUpdateRoom);
      },
    });
    return () => subscription.unsubscribe();
  }, [props]);

  let watch = false;
  let canStart = false;
  if (roomEnv.seed !== -1) {
    const setting = JSON.parse(roomEnv.setting);
    if (setting.seat.includes(props.env.name)) {
      canStart = true;
    } else {
      watch = true;
    }
  }

  useEffect(() => {
    if (canStart) props.setEnv(roomEnv);
  });

  if (watch) {
    return <div className="NormalMode">ゲームが進行中です。終了をお待ちください。</div>;
  }
  return <div>{roomEnv.game === "" ? <CGameSelect {...roomEnv} /> : <CGameSetting {...roomEnv} />}</div>;
}

App.defaultProps = {
  name: "",
  room: "",
  game: "",
  password: "",
  seed: -1,
};

function App(props: any) {
  const [env, setEnv] = useState({
    name: props.name,
    room: props.room,
    pass: props.password,
    game: props.game,
    setting: "",
    seed: props.seed,
  } as Env);
  const cprops = { env: env, setEnv: setEnv };
  return (
    <div>
      <Bar jump={env.name !== ""} />
      <div className="App">{env.name === "" ? <CTopPage {...cprops} /> : env.seed !== -1 ? <CGame {...cprops} /> : <RoomMode {...cprops} />}</div>
    </div>
  );
}

export default App;
