import "./Daifugo.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, soundEffect } from "../GameCommand";

import { Button, Chip, Avatar } from "@material-ui/core/";

import { Random, shuffle } from "../lib/xs";
import { divide } from "../lib/deck";

interface Setting {
  seat: string[];
  orderRandom: boolean;
  miyako: boolean;
  kiri8: boolean;
  bind: boolean;
  back11: boolean;
  spe3: boolean;
}

const settingDef = [
  { kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true },
  { kind: "boolean", id: "miyako", name: "都落ち", default: true },
  { kind: "boolean", id: "kiri8", name: "8切り", default: true },
  { kind: "boolean", id: "bind", name: "マーク縛り", default: true },
  { kind: "boolean", id: "spe3", name: "スペード3返し", default: true },
  { kind: "boolean", id: "back11", name: "Jバック", default: true },
];

interface DCard {
  id: number;
  suit: string;
  power: number; // 0～12 = 3,4,,,10,J,Q,K,A,2
}

type CardID = number;

const suits = ["Spade", "Club", "Heart", "Diamond"];

interface Player {
  state: string;
  name: string;
  rank: number;
  hand: CardID[];
}

interface Field {
  top: CardID[];
  second: CardID[];
  player: number;
  bind: string[];
  back11: boolean;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  cards: DCard[];
  player: Player[];
  field: Field;
  revo: boolean;
  turnPlayer: number;
  playOrder: number[];
  winner: number[];
  loser: number[];
  wait: any[];
}

const GameContext = React.createContext({} as Game);

interface Play {
  table: string;
  step: number;
  action: string;
}

interface UI {
  selects: CardID[];
}

function initUI() {
  return { selects: [] };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function sortDaifugo(a: DCard, b: DCard): number {
  if (a.power !== b.power) return a.power - b.power;
  else return suits.indexOf(a.suit) - suits.indexOf(b.suit);
}

function card2filename(c: DCard): string {
  let name = "";
  if (c.suit === "Jocker") name = "jk0";
  else name = ["s", "c", "h", "d"][suits.indexOf(c.suit)] + ("00" + (((c.power + 13 + 2) % 13) + 1)).slice(-2);
  return process.env.PUBLIC_URL + "/trump/" + name + ".png";
}

function backfilename(): string {
  return process.env.PUBLIC_URL + "/trump/bk0.png";
}

function nextTurnPlayer(game: Game, base: number): number {
  let npi: number = game.playOrder.indexOf(base);
  do {
    npi = (npi + 1) % game.player.length;
    if (game.playOrder[npi] === base) break; // 無限ループ対策
  } while (game.player[game.playOrder[npi]].state !== "Play");
  return game.playOrder[npi];
}

function nextPlayer(game: Game, base: number): number {
  let npi: number = game.playOrder.indexOf(base);
  npi = (npi + 1) % game.player.length;
  return game.playOrder[npi];
}

function CCard(props: { c: CardID; options?: any }) {
  const game = useContext(GameContext);
  return <img src={card2filename(game.cards[props.c])} alt={props.c} {...props.options} />;
}

function CPlayer(props: Player) {
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const game = useContext(GameContext);

  function getOp(c: CardID, i: number) {
    if (game.state === "Change" && props.rank < 0) {
      return props.hand.length - i <= Math.abs(props.rank) ? { className: "TurnSelected" } : {};
    }

    if (game.turnPlayer === game.you || (game.state === "Change" && props.rank > 0)) {
      return { className: ui.selects.includes(c) ? "TurnSelected" : "Turn", onClick: () => uiDispatch({ type: "ChangeSelect", card: c }) };
    }
    return {};
  }
  return (
    <div>
      <div>
        <div>{rankName(props.rank)}</div>
        <div>{props.name}</div>
      </div>
      {props.state === "Win" && "[あがり]"}
      {props.state === "Fall" && "[都落ち]"}
      <div className="Hand">
        {props.hand.map((c, i) => (
          <CCard key={c} c={c} options={getOp(c, i)} />
        ))}
      </div>
    </div>
  );
}

function rankName(rank: number): string {
  switch (rank) {
    case 1:
      return "[富豪]";
    case 2:
      return "[大富豪]";
    case -1:
      return "[貧民]";
    case -2:
      return "[大貧民]";
  }

  return "[平民]";
}

function OtherPlayer(props: any) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  const c = props.id === game.turnPlayer ? "OtherTurn" : "Other";
  return (
    <div key={info.name} className={c}>
      <div>{rankName(info.rank)}</div>
      <div>{info.name}</div>
      {info.state === "Win" && "[あがり]"}
      {info.state === "Fall" && "[都落ち]"}
      {info.state === "Play" && (
        <div>
          <img src={backfilename()} alt="back" />
          {info.hand.length}
        </div>
      )}
    </div>
  );
}

function CField() {
  const game = useContext(GameContext);
  return (
    <div className="Field">
      <div className="FieldStatus">
        {game.revo && <Chip label="革命" />}
        {game.field.back11 && <Chip label="Ｊバック" />}
        {game.field.bind.includes("Spade") && <Chip avatar={<Avatar src={process.env.PUBLIC_URL + "/trump/Icons.png"} />} label="縛り" />}
        {game.field.bind.includes("Club") && <Chip avatar={<Avatar src={process.env.PUBLIC_URL + "/trump/Iconc.png"} />} label="縛り" />}
        {game.field.bind.includes("Heart") && <Chip avatar={<Avatar src={process.env.PUBLIC_URL + "/trump/Iconh.png"} />} label="縛り" />}
        {game.field.bind.includes("Diamond") && <Chip avatar={<Avatar src={process.env.PUBLIC_URL + "/trump/Icond.png"} />} label="縛り" />}
      </div>
      {game.field.top.length !== 0 ? (
        <div className="FieldTop">
          {game.field.top.map((c) => (
            <CCard key={c} c={c} />
          ))}
        </div>
      ) : (
        game.field.second.length !== 0 && (
          <div className="FieldSecond" id={game.field.second[0].toString()}>
            {game.field.second.map((c) => (
              <CCard key={c} c={c} />
            ))}
          </div>
        )
      )}
    </div>
  );
}

function DaifugoUIReducer(state: UI, action: any): UI {
  console.log("Reducer", state, action);
  let nUI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "ChangeSelect":
      if (nUI.selects.includes(action.card)) {
        nUI.selects.splice(nUI.selects.indexOf(action.card), 1);
      } else {
        nUI.selects.push(action.card);
      }
      break;

    case "Init":
      return initUI();
  }

  return nUI;
}

interface KindHand {
  kind: string;
  num: number;
  power: number;
  suits: string[];
}

function getKindHand(cards: DCard[]): KindHand {
  const num = cards.length;
  if (num === 0) return { kind: "Nothing", num: num, power: 0, suits: [] };

  cards.sort(sortDaifugo);

  if (cards[cards.length - 1].suit === "Jocker") {
    if (num === 1) return { kind: "Jocker", num: num, power: cards[0].power, suits: [cards[0].suit] };
    cards.pop();
  }

  if (cards.every((c) => c.power === cards[0].power)) {
    return { kind: "Same", num: num, power: cards[0].power, suits: cards.map((c) => c.suit) };
  } else {
    if (num < 3) return { kind: "Nothing", num: num, power: 0, suits: [] };
    if (cards.some((c) => c.suit !== cards[0].suit)) return { kind: "Nothing", num: num, power: 0, suits: [] };
    if (cards[cards.length - 1].power <= cards[0].power + num - 1) {
      return { kind: "Seq", num: num, power: cards[0].power + num - 1, suits: [cards[0].suit] };
    }
  }
  return { kind: "Nothing", num: num, power: 0, suits: [] };
}

function canPlay(game: Game, selects: CardID[]) {
  const hand = getKindHand(selects.map((id) => game.cards[id]));
  const top = getKindHand(game.field.top.map((id) => game.cards[id]));

  if (hand.kind === "Nothing") return false;

  if (top.kind === "Nothing") return true;

  if (top.kind === hand.kind && top.num === hand.num) {
    //縛りチェック
    //ジョーカー以外が縛りに適合していたらOK
    if (game.field.bind.length !== 0 && hand.suits.some((s) => !game.field.bind.includes(s))) return false;

    const reverse = game.revo !== game.field.back11;
    if (reverse) {
      //反転中
      if (top.power > hand.power) {
        return true;
      }
    } else {
      //通常時
      if (top.power < hand.power) {
        return true;
      }
    }
  }

  if (top.num === 1 && top.kind !== "Jocker" && hand.kind === "Jocker") return true;

  //スぺ3
  if (game.setting.spe3 && top.num === 1 && top.kind === "Jocker" && hand.num === 1 && hand.power === 0 && hand.suits[0] === "Spade") return true;

  return false;
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);

  const uiCallback = () => {
    uiDispatch({ type: "Init" });
  };

  function playField() {
    play(game, { command: "Hand", hand: ui.selects, player: game.you }, uiCallback);
  }
  function passField() {
    play(game, { command: "Pass", player: game.you }, uiCallback);
  }
  function checkField() {
    play(game, { command: "Check", player: game.you, sdeck: newDeck(game) }, uiCallback);
  }
  function changeCard() {
    play(game, { command: "Change", hand: game.player[game.you].hand.slice(game.player[game.you].rank), player: game.you }, uiCallback);
  }
  const isTurn = game.turnPlayer === game.you;

  return (
    <div className="Action">
      {game.state === "Game" && (
        <>
          {isTurn && "あなたの番です カードを選択してください"} <br />
          {isTurn && canPlay(game, ui.selects) && (
            <Button onClick={playField} variant="contained">
              場に出す
            </Button>
          )}
          {isTurn && ui.selects.length === 0 && game.field.top.length !== 0 && (
            <Button onClick={passField} variant="contained">
              パス
            </Button>
          )}
        </>
      )}
      {game.state === "End" &&
        (game.wait[game.you] ? (
          "確認待ち"
        ) : (
          <Button onClick={checkField} variant="contained">
            次のゲームを始める
          </Button>
        ))}
      {game.state === "Change" &&
        (game.wait[game.you] !== null ? (
          "交換待ち"
        ) : game.player[game.you].rank > 0 ? (
          ui.selects.length === Math.abs(game.player[game.you].rank) ? (
            <Button onClick={playField} variant="contained">
              交換する
            </Button>
          ) : (
            Math.abs(game.player[game.you].rank) + "枚選択してください"
          )
        ) : (
          <Button onClick={changeCard} variant="contained">
            交換する
          </Button>
        ))}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  let others = [] as JSX.Element[];
  let otherId = nextPlayer(game, game.you);
  while (otherId !== game.you) {
    others.push(<OtherPlayer key={otherId} id={otherId} />);
    otherId = nextPlayer(game, otherId);
  }
  return (
    <div className="Game">
      <div className="Others">{others}</div>
      <CField />
      <CAction />
      <CPlayer key={game.you} {...game.player[game.you]} />
    </div>
  );
}

function initDeck(): DCard[] {
  let id = 0;
  let deck = suits.map((s) => [...Array(13)].map((_, i) => ({ id: id++, suit: s, power: i }))).flat();
  deck.push({ id: id++, suit: "Jocker", power: 100 });
  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const sdeck = shuffle(
    [...Array(initDeck().length)].map((_, i) => i),
    r
  );
  return sdeck;
}

function newOrder(game: Game) {
  let order = game.setting.seat.map((_, i) => i);
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(order, r);
  } else {
    return order;
  }
}

function resetGame(game: Game, sdeck: number[]) {
  const order = game.winner.concat(game.loser).reverse();

  game.state = "Change";
  const hands: CardID[][] = divide(sdeck, order.length)[1].map((d) => d.sort((ai, bi) => sortDaifugo(game.cards[ai], game.cards[bi])));

  game.field = { top: [], second: [], player: 0, bind: [], back11: false };
  game.revo = false;
  game.player = game.player.map((p, i) => ({ ...p, rank: 0, hand: hands[i], state: "Play" }));
  game.player[order[0]].rank -= 2;
  game.player[order[1]].rank -= 1;
  game.player[order[order.length - 2]].rank += 1;
  game.player[order[order.length - 1]].rank += 2;
  game.playOrder = order;
  game.turnPlayer = order[0];
  game.winner = [];
  game.loser = [];
  game.wait = game.player.map((p) => (p.rank === 0 ? [] : null));
}

function startGame(game: Game, sdeck: number[], order: number[]) {
  const deck = initDeck();
  const hands: CardID[][] = divide(sdeck, order.length)[1].map((d) => d.sort((ai, bi) => sortDaifugo(deck[ai], deck[bi])));
  const players: Player[] = game.setting.seat.map((p, i) => ({ name: p, rank: 0, hand: hands[i], state: "Play" }));
  const playOrder: number[] = order;
  game.cards = deck;
  game.field = { top: [], second: [], player: 0, bind: [], back11: false };
  game.revo = false;
  game.player = players;
  game.turnPlayer = playOrder[0];
  game.playOrder = playOrder;
  game.state = "Game";
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [], orderRandom: false, miyako: false, bind: false, kiri8: false, spe3: false, back11: false },
    state: "Init",
    you: -1,
    cards: [],
    step: 0,
    field: { top: [], second: [], player: 0, bind: [], back11: false },
    revo: false,
    player: [],
    turnPlayer: 0,
    playOrder: [],
    winner: [],
    loser: [],
    wait: [],
  };
}

function stepNagare(game: Game) {
  game.field.second = game.field.top;
  game.field.top = [];
  game.field.bind = [];
  game.field.back11 = false;

  return game;
}

function stepDaifugo(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;

  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          startGame(ng, action.sdeck, action.order);
          return ng;

        default:
          console.error("Unknown State");
          break;
      }
      break;

    case "Game":
      switch (action.command) {
        case "Hand":
          //場に出す
          ng.field.second = ng.field.top;
          ng.field.top = action.hand.map((id) => ng.player[action.player].hand.splice(ng.player[action.player].hand.indexOf(id), 1)).flat();
          ng.field.top.sort((ai, bi) => sortDaifugo(ng.cards[ai], ng.cards[bi]));
          ng.field.player = action.player;

          const top = getKindHand(ng.field.top.map((id) => game.cards[id]));
          const second = getKindHand(ng.field.second.map((id) => game.cards[id]));

          //縛り
          if (ng.setting.bind && ng.field.bind.length === 0 && top.suits.every((s) => second.suits.includes(s))) {
            ng.field.bind = top.suits;
          }
          //革命
          if (top.kind === "Same" && top.num >= 4) ng.revo = !ng.revo;
          //Jバック
          if (ng.setting.back11 && ng.field.top.some((c) => ng.cards[c].power === 8)) {
            ng.field.back11 = !ng.field.back11;
          }

          //上がり判定
          if (ng.player[action.player].hand.length === 0) {
            //上がり
            ng.player[action.player].state = "Win";
            ng.winner.push(action.player);
            //都落ち
            if (game.setting.miyako) {
              const fall = ng.player.findIndex((p) => p.state === "Play" && p.rank === 2);
              if (fall !== -1) {
                ng.player[fall].state = "Fall";
                ng.loser.push(fall);
              }
            }
            ng.field.player = nextTurnPlayer(ng, ng.turnPlayer); // 次のパス判定のため、注意
            //ゲーム終了
            if (ng.player.filter((p) => p.state === "Play").length === 1) {
              ng.loser.unshift(ng.player.findIndex((p) => p.state === "Play"));
              ng.state = "End";
              ng.wait = [...Array(ng.player.length)].map(() => false);
            }
          }

          //8切り
          if (ng.setting.kiri8 && ng.field.top.map((id) => game.cards[id]).some((c) => c.power === 5)) {
            //流れ
            ng = stepNagare(ng);
            return ng;
          }

          if (second.kind === "Jocker") {
            //ジョーカーに対して出せるのはスぺ3だけなので流れ
            ng = stepNagare(ng);
            return ng;
          }

          ng.turnPlayer = nextTurnPlayer(ng, ng.turnPlayer);
          return ng;

        case "Pass":
          ng.turnPlayer = nextTurnPlayer(ng, ng.turnPlayer);
          //流れ
          if (ng.turnPlayer === ng.field.player) {
            ng = stepNagare(ng);
          }
          return ng;

        default:
          console.error("Unknown Action");
          break;
      }
      break;

    case "End":
      switch (action.command) {
        case "Check":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            resetGame(ng, action.sdeck);
            console.log("newgame", ng);
          }
          return ng;

        default:
          console.error("Unknown Action");
          break;
      }
      break;

    case "Change":
      switch (action.command) {
        case "Hand":
        case "Change":
          ng.wait[action.player] = action.hand;
          if (ng.wait.every((w) => w !== null)) {
            //交換
            for (let i = 0; i < ng.player.length; i++) {
              if (ng.player[i].rank === 0) continue;
              ng.wait[i].map((id: number) => ng.player[i].hand.splice(ng.player[i].hand.indexOf(id), 1));
              ng.player[i].hand = ng.player[i].hand
                .concat(ng.wait[ng.player.findIndex((p) => p.rank === ng.player[i].rank * -1)])
                .sort((ai, bi) => sortDaifugo(ng.cards[ai], ng.cards[bi]));
            }
            ng.state = "Game";
          }
          return ng;

        default:
          console.error("Unknown Action");
          break;
      }
      break;

    default:
      console.error("Unknown State");
      break;
  }

  return ng;
}

export const CDaifugo = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(DaifugoUIReducer, undefined, initUI);

  console.log("Daifugo Render", game);

  useDB({ env: props.env, setGame: setGame, step: stepDaifugo });

  useEffect(() => {
    soundEffect();
  }, [game.state, game.field.top, game.turnPlayer]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", sdeck: newDeck(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Daifugo">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="大富豪" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>大富豪</h2>
      <p>大富豪は日本の伝統的なトランプゲームです。</p>
      <p>
        基本的なルールは{" "}
        <a href="https://ja.wikipedia.org/wiki/%E5%A4%A7%E5%AF%8C%E8%B1%AA" target="_blank" rel="noreferrer">
          大富豪 - Wikipedia
        </a>{" "}
        をご覧ください。
      </p>
      <div>
        <h4>対応ルール</h4>
        <p>
          使用カードは1セット52枚+ジョーカー1枚です。
        </p>
        <p>
          シークエンス(階段)
          <br />
          3枚以上の同じマークで連続した数字の札を出すことができる。
          <br />
          数字は場の札を1枚でも上回っていれば出すことができる。(5,6,7の後は6,7,8を出すことができる)
        </p>
        <p>
          革命
          <br />
          4枚以上の同じ数字の札(ジョーカーを含む)を出したとき、数字の強さが逆になる。
          <br />
          もう一度発生した時、数字の強さが元に戻る。
          <br />
          シークエンス(階段)では発生しない。
        </p>
      </div>
      <div>
        <h4>対応オプション</h4>
        <p>
          都落ち
          <br />
          大富豪より先に他のプレイヤーがあがった場合、大富豪が次の大貧民になる。
        </p>
        <p>
          8切り
          <br />
          出した札に8が含まれていれば、場を流す。
        </p>
        <p>
          マーク縛り
          <br />
          場に出ている札と同じマークの組み合わせで札を出したとき、場が流れるまでそのマークの組み合わせしか出せなくなる。
        </p>
        <p>
          スペード3返し
          <br />
          ジョーカー1枚出しに対して、♠3で場を流すことができる。
        </p>
        <p>
          Jバック
          <br />
          出した札にJが含まれていれば、場が流れるまで数字の強さが逆になる。(革命中は元に戻る)
        </p>
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/daifugo/help1.png"} /> <br />
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/daifugo/help2.png"} /> <br />
      </div>
    </div>
  );
}

export const Daifugo = {
  name: "大富豪",
  id: "Daifugo",
  people: [2, 12],
  settingDef: settingDef,
  component: CDaifugo,
  help: CHelp,
};
