import { ErrorBoundary } from "react-error-boundary";
import { People } from "@material-ui/icons/";
import { Grid, Card, CardContent, Button } from "@material-ui/core/";
import { BugReportButton } from "./GameCommand";
import { ADs } from "./AD";

import { Daifugo } from "./component/Daifugo";
import { Goita } from "./component/Goita";
import { Mahjong, MahjongPa } from "./component/Mahjong";
import { Backgammon } from "./component/Backgammon";
import { Poker } from "./component/Poker";
import { HimeSyogi } from "./component/HimeSyogi";
import { HakyokuDice } from "./component/HakyokuDice";
import { PrimeQK } from "./component/PrimeQK";
import { Uma } from "./component/Uma";

export const GamesList = [PrimeQK, HakyokuDice, HimeSyogi, Uma, MahjongPa, Mahjong, Goita, Poker, Backgammon, Daifugo];

export function CGame(props: any) {
  console.log(props);
  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <div className="NormalMode">
            予期せぬエラーが発生しました。
            <br />
            お手数ですが、下のボタンからリロードし、同じ名前・部屋名での再入室をお試しください。 <br />
            <Button onClick={() => window.location.reload()} variant="contained">
              リロード
            </Button>
            <br />
            エラーが解消しない場合は、下のボタンでゲームをリセットしてください。(同時にバグ報告が行われます)
            <br />
            <BugReportButton env={props.env} error={error} setEnv={props.setEnv} />
          </div>
        )}
      >
        <CGameBody {...props} />
      </ErrorBoundary>
    </>
  );
}

export function CGameBody(props: any) {
  const game = GamesList.find((g) => g.id === props.env.game);

  return (
    <>
      <div id="GameBody">{game?.component(props) || "ゲームが見つかりません"}</div>
      <hr />
      <ADs id="game1" />
      <hr />
      {game?.help(props) || "ヘルプが見つかりません"}
      <hr />
      <ADs id="game2" />
    </>
  );
}

export function peopleString(game: any) {
  if (game.people[0] === game.people[1]) return game.people[0];
  if (game.people[1] === 99) return game.people[0] + "-";
  return game.people[0] + "-" + game.people[1];
}

export function CList(props: any) {
  return (
    <div>
      <div>
        <p>TAGAで遊べるゲーム一覧です。詳細はゲーム内から確認できます。</p>
        <Grid container spacing={1}>
          {GamesList.map((game) => (
            <Grid item key={game.name}>
              <Card style={{ width: 150 }}>
                <CardContent>
                  <div>
                    <b>{game.name}</b>
                  </div>
                  <div>
                    <img className="GameIcon" alt="Icon" src={"/icon/" + game.id + ".png"} />
                  </div>
                  <div>
                    <People />
                    {peopleString(game)}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
