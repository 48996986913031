import { Button, Checkbox } from "@material-ui/core/";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { play, useDB, GameSettingButton, Env, soundEffect } from "../GameCommand";
import { Random, shuffle } from "../lib/xs";
import "./Mahjong.css";

interface Setting {
  seat: string[];
  orderRandom: boolean;
  aka: boolean;
  clear: string;
  haipai: string;
}

const settingDef: any[] = [
  { kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true },
  { kind: "boolean", id: "aka", name: "赤ドラ", default: true },
  {
    kind: "string",
    id: "clear",
    name: "透明牌(4牌中)",
    default: "0",
    choice: [
      { id: "0", name: "0" },
      { id: "1", name: "1" },
      { id: "2", name: "2" },
      { id: "3", name: "3" },
      { id: "4", name: "4" },
    ],
  },
  {
    kind: "string",
    id: "haipai",
    name: "配牌",
    default: "Random",
    choice: [
      { id: "Random", name: "ランダム(通常)" },
      { id: "Draft", name: "ドラフト" },
      { id: "Select", name: "選択(デバッグ用)" },
    ],
  },
];

const suits = ["マンズ", "ピンズ", "ソーズ", "東", "南", "西", "北", "白", "発", "中"];

interface Hai {
  id: number;
  suit: string;
  num: number;
  aka: boolean;
  clear: boolean;
}

const blankHai: Hai = {
  id: -1,
  suit: "",
  num: -1,
  aka: false,
  clear: false,
};

type HaiID = number;

interface Ba {
  kaze: string;
  num: number;
  bar100: number;
  bar1000: number;
}

interface Wanpai {
  hais: HaiID[];
  dora: number;
  doraReserv: number;
}

interface Mentsu {
  kind: string;
  hais: HaiID[];
  from: number; //鳴き牌のIndex
}

interface Hand {
  tehai: HaiID[];
  naki: Mentsu[];
  last: HaiID;
}

interface Huriten {
  sute: boolean;
  reach: boolean;
  yama: boolean;
}

interface Tenpai {
  agari: Hai;
  mentsu: Mentsu[];
  sute: HaiID;
  mati: string;
}

interface Yaku {
  name: string;
  han: number;
}

interface Agari {
  player: number;
  kind: string;
  hai: HaiID;
  yakus: Yaku[];
  total: string;
  scores: number[];
}

interface Player {
  name: string;
  hand: Hand;
  kawa: HaiID[];
  huri: HaiID[];
  reach: boolean;
  reachIdx: number;
  double: boolean;
  ippatsu: boolean;
  tenpais: Tenpai[];
  huriten: Huriten;
  score: number;
  kaze: string;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  order: number[];
  hais: Hai[];
  ba: Ba;
  yama: HaiID[];
  wanpai: Wanpai;
  player: Player[];
  turnPlayer: number;
  startPlayer: number;
  wait: any[];
  now: HaiID;
  kan: boolean;
  haitei: boolean;
  first: boolean;
  draft: HaiID[][];
  draftCnt: number;
  tmp: any;
  parallel: boolean;
}

const GameContext = React.createContext({} as Game);

interface Play {
  table: string;
  step: number;
  action: string;
}

interface UI {
  select: HaiID;
  reach: boolean;
  display: boolean;
  nakinashi: boolean;
}

function initUI() {
  return { select: -1, reach: false, display: true, nakinashi: false };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function sortMahjong(a: Hai, b: Hai): number {
  const [as, bs] = [a.suit, b.suit].map((s) => ["マンズ", "ピンズ", "ソーズ", "東", "南", "西", "北", "白", "発", "中"].indexOf(s));
  if (as !== bs) return as - bs;
  if (a.num !== b.num) return a.num - b.num;
  return a.id - b.id;
}

function sortMahjongClear(a: Hai, b: Hai): number {
  const [as, bs] = [a.suit, b.suit].map((s) => ["マンズ", "ピンズ", "ソーズ", "東", "南", "西", "北", "白", "発", "中"].indexOf(s));
  if (a.clear !== b.clear) return a.clear ? -1 : 1;
  if (as !== bs) return as - bs;
  if (a.num !== b.num) return a.num - b.num;
  return a.id - b.id;
}

function nextPlayer(game: Game, base: number, diff: number = 1): number {
  const basei = game.order.indexOf(base);
  return game.order[(basei + diff) % game.setting.seat.length];
}

function seatPos(game: Game, fromP: number, toP: number) {
  const fromi = game.order.indexOf(fromP);
  const toi = game.order.indexOf(toP);
  return (toi + 4 - fromi) % 4;
}

function getKaze(game: Game, start: number, you: number) {
  let i = 0;
  let p = start;
  while (p !== you) {
    i++;
    p = nextPlayer(game, p);
  }
  return ["東", "南", "西", "北"][i];
}

function hai2filename(h: Hai, down: boolean, side: boolean) {
  let fname = "";
  fname = ["m", "p", "s", "j1", "j2", "j3", "j4", "j5", "j6", "j7"][suits.indexOf(h.suit)];

  if (h.aka) fname += "e";
  else if (h.num !== 0) fname += h.num;

  if (down) fname = "2" + fname;

  if (!side && !h.clear) fname = "j9";
  const folder = h.clear ? "clear/" : "normal/";
  return process.env.PUBLIC_URL + "/mahjong/" + folder + fname + ".png";
}

function barfilename(num: number) {
  return process.env.PUBLIC_URL + "/mahjong/bar" + num + ".png";
}

function CHai(props: { h: HaiID; down: boolean; side: boolean; class?: string; options?: any }) {
  const game = useContext(GameContext);
  let cname = (props?.class ?? "") + " Hai";
  if (props.down) cname += " Down";
  if (props.h === game.now) cname += " Now";

  return (
    <div className={cname} {...props.options}>
      <img src={hai2filename(game.hais[props.h], props.down, props.side)} alt={"hai"} />
    </div>
  );
}

function CNaki(props: { m: Mentsu }) {
  let cname = props.m.kind;
  if (props.m.kind === "Kakan") {
    cname += props.m.from + 1;
  }
  function side(i: number) {
    if (props.m.kind === "Ankan" && (i === 0 || i === 3)) return false;
    return true;
  }
  function down(i: number) {
    if (i === props.m.from) return true;
    if (props.m.kind === "Kakan" && i === 3) return true;
    return false;
  }

  return (
    <div className={"Naki " + cname}>
      {props.m.hais.map((h, i) => (
        <CHai key={h} h={h} side={side(i)} down={down(i)} />
      ))}
    </div>
  );
}

function CEnd() {
  const game = useContext(GameContext);

  return (
    <div className="End">
      終局
      {game.player.map((p) => (
        <div key={p.name}>
          <div className="Name">{p.name}</div> <div>{p.score}</div>
        </div>
      ))}
    </div>
  );
}

function CDraft() {
  const game = useContext(GameContext);
  if (game.state !== "Draft") return <></>;
  const hais = game.draft[nextPlayer(game, game.you, 13 - game.draftCnt)].sort((ai, bi) => sortMahjong(game.hais[ai], game.hais[bi]));

  function draftSelect(id: HaiID) {
    if (!game.wait[game.you]) {
      play(game, { command: "Select", hai: id, player: game.you });
    }
  }

  return (
    <div className="Draft">
      {hais.map((h, i) => (
        <CHai key={h} h={h} side={true} down={false} options={{ onClick: () => draftSelect(h) }} />
      ))}
    </div>
  );
}

function CDebug() {
  const game = useContext(GameContext);
  if (game.state !== "Debug") return <></>;
  const hais = [...game.yama].sort((ai, bi) => sortMahjong(game.hais[ai], game.hais[bi]));

  function debugSelect(id: HaiID) {
    if (game.player[game.you].hand.tehai.length < (game.you === game.startPlayer ? 14 : 13)) {
      play(game, { command: "Select", hai: id, player: game.you });
    }
  }

  return (
    <div className="Debug">
      {hais.map((h, i) => (
        <CHai key={h} h={h} side={true} down={false} options={{ onClick: () => debugSelect(h) }} />
      ))}
    </div>
  );
}

function CAgari() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const agari: Agari = game.tmp;

  if (game.state !== "Agari" || !ui.display) return <></>;

  function CScore(props: any) {
    let score = agari.scores[props.id];
    return (
      <div className={props.cname}>
        <div className="Name">{game.player[props.id].name}</div>
        <div>{score > 0 ? "+" + score : score}</div>
      </div>
    );
  }

  return (
    <div className="Agari">
      <div className="AgariHai">
        {agari.kind}
        {agari.hai !== -1 && <CHai h={agari.hai} side={true} down={false} />}
      </div>
      <div className="Dora">
        <div className="Hais">
          {"　ドラ"}
          {game.wanpai.hais.slice(0, game.wanpai.dora).map((h, i) => (
            <CHai key={h} h={h} side={true} down={false} />
          ))}
        </div>
        <div className="Hais">
          {"裏ドラ"}
          {game.wanpai.hais.slice(5, 5 + game.wanpai.dora).map((h, i) => (
            <CHai key={h} h={h} side={true} down={false} />
          ))}
        </div>
      </div>
      <table className="Yaku">
        <tbody>
          {agari.yakus.map((y) => (
            <tr key={y.name} className="Detail">
              <td>{y.name}</td>
              <td>{y.han === 13 ? "役満" : y.han + "翻"}</td>
            </tr>
          ))}
          <tr className="Total">
            <td colSpan={2}>{agari.total}</td>
          </tr>
        </tbody>
      </table>

      <div className="Score">
        {game.setting.seat
          .map((_, i) => nextPlayer(game, game.you, i))
          .map((p) => (
            <CScore cname={"S" + seatPos(game, game.you, p)} id={p} />
          ))}
      </div>
    </div>
  );
}

function CMati() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);

  if (game.turnPlayer !== game.you || game.state !== "Turn" || ui.select === -1) return <></>;
  return (
    <div className="Mati">
      {getAgariHais(game, ui.select).map((a) => (
        <img key={a.suit + a.num} src={hai2filename(a, false, true)} alt={"hai"} />
      ))}
    </div>
  );
}

function CBa() {
  const game = useContext(GameContext);

  return (
    <div className="Ba">
      <div>
        <div className="Kyoku">{game.ba.kaze + game.ba.num + "局"}</div>
        <div>
          <img alt="bar" src={barfilename(100)} /> {game.ba.bar100}
        </div>
        <div>
          <img alt="bar" src={barfilename(1000)} /> {game.ba.bar1000}
        </div>
      </div>
    </div>
  );
}

function CWanpai() {
  const game = useContext(GameContext);

  return (
    <div className="Wanpai">
      ドラ
      {game.wanpai.hais.slice(0, game.wanpai.dora).map((h, i) => (
        <CHai key={h} h={h} side={true} down={false} />
      ))}
    </div>
  );
}

function CHuriten() {
  const game = useContext(GameContext);
  const huriten = game.player[game.you].huriten;

  return <div className="Huriten">{(huriten.yama || huriten.reach || huriten.sute) && <>フリテン</>}</div>;
}

function CKawa(props: { id: number }) {
  const game = useContext(GameContext);
  return (
    <div className="Kawa">
      {game.player[props.id].kawa.map((h, i) => (
        <CHai key={h} h={h} side={true} down={i === game.player[props.id].reachIdx} />
      ))}
    </div>
  );
}

function CPlayerStatus(props: { id: number }) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  let isTurn = game.turnPlayer === props.id;
  if (game.state === "Draft") isTurn = !game.wait[props.id];
  let cname = isTurn ? "Name Turn" : "Name";

  return (
    <div className="Status">
      <div className="Zikaze">{p.kaze}</div>
      <div>
        <div className={cname}>{p.name}</div>
        <div>{p.score}</div>
      </div>
      {p.reach && (
        <div className="Reach">
          <img alt="bar" src={barfilename(1000)} />
        </div>
      )}
    </div>
  );
}

function CHand(props: { id: number }) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  let ptehai = [...p.hand.tehai];

  let open = props.id === game.you;
  if (game.state === "Agari" && !open) {
    const agari: Agari = game.tmp;
    if (agari.kind === "流局") {
      open = p.tenpais.length > 0;
    } else if (agari.kind === "四家立直") {
      open = true;
    } else {
      open = props.id === agari.player;
    }
  }

  if (open) {
    ptehai.sort((ai, bi) => sortMahjong(game.hais[ai], game.hais[bi]));
  } else {
    ptehai.sort((ai, bi) => sortMahjongClear(game.hais[ai], game.hais[bi]));
  }

  function playHai(id: HaiID) {
    play(game, { command: "Dahai", hai: id, reach: ui.reach, player: game.you });
  }

  function canPlay(id: HaiID) {
    if (game.you !== props.id) return false;
    if (game.state !== "Turn") return false;
    if (game.turnPlayer !== game.you) return false;
    if (p.reach && id !== p.hand.last) return false;

    if (ui.reach) {
      if (p.tenpais.some((t) => t.sute === id)) return true;
    } else {
      return true;
    }
    return false;
  }

  function cname(id: HaiID) {
    let ret = "";
    if (canPlay(id)) ret += ui.reach ? " Reach" : " CanPlay";
    if (id === p.hand.last) ret += " Last";
    return ret;
  }

  function option(id: HaiID) {
    return canPlay(id)
      ? {
          onClick: () => playHai(id),
          onMouseOver: () => uiDispatch({ type: "AddSelect", hai: id }),
          onMouseOut: () => uiDispatch({ type: "DelSelect" }),
        }
      : {};
  }

  return (
    <div className="Hand">
      {ptehai.map((h, i) => (
        <CHai key={h} h={h} side={open} down={false} class={cname(h)} options={option(h)} />
      ))}
    </div>
  );
}

function CPlayer(props: any) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  const pos = seatPos(game, game.you, props.id);
  const cname = pos === 0 ? "You" : "Other" + pos.toString();
  return (
    <div className={"Player " + cname}>
      <CPlayerStatus id={props.id} />
      <CKawa id={props.id} />
      <CHand id={props.id} />
      <div className="Nakis">
        {p.hand.naki.map((m, i) => (
          <CNaki key={i} m={m} />
        ))}
      </div>
    </div>
  );
}

function UIStateChange(game, dispatch) {
  if (game.state === "Agari") {
    dispatch({ type: "EndGame" });
  } else {
    dispatch({ type: "StateChange" });
  }
}

function UIReducer(state: UI, action: any): UI {
  // console.log("Reducer", state, action);
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "AddSelect":
      nUI.select = action.hai;
      break;

    case "DelSelect":
      nUI.select = -1;
      break;

    case "Reach":
      nUI.reach = !nUI.reach;
      break;

    case "Nakinashi":
      nUI.nakinashi = !nUI.nakinashi;
      break;

    case "Display":
      nUI.display = !nUI.display;
      break;

    case "EndGame":
      nUI.display = true;
      nUI.nakinashi = false;
      break;

    case "StateChange":
      nUI.reach = false;
      nUI.select = -1;
      break;

    case "Init":
      return initUI();
  }

  return nUI;
}

function CAction() {
  const game = useContext(GameContext);
  let uiDispatch = useContext(UIDispatchContext);
  let ui = useContext(UIContext);

  //鳴無送信
  useEffect(() => {
    if (game.state === "Naki" && game.wait[game.you].kind === "Wait") {
      if (ui.nakinashi && !canRon(game, game.you, game.now)) {
        console.log("鳴無送信");
        naki({ kind: "Pass", hais: [game.now], from: -1 });
      } else {
        soundEffect();
      }
    }
  });

  function naki(m: Mentsu) {
    play(game, { command: "Naki", naki: m, player: game.you });
  }
  return (
    <div className="Action">
      <div>
        <Button variant="contained" onClick={() => uiDispatch({ type: "Nakinashi" })}>
          <Checkbox checked={ui.nakinashi} name="Nakinashi" />
          鳴無
        </Button>
      </div>
      {game.state === "Naki" && game.wait[game.you].kind === "Wait" && (
        <>
          <div>
            <Button onClick={() => naki({ kind: "Pass", hais: [game.now], from: -1 })} variant="contained">
              パス
            </Button>
          </div>
          {canRon(game, game.you, game.now) && (
            <div>
              <Button onClick={() => naki({ kind: "Ron", hais: [game.now], from: -1 })} variant="contained">
                ロン
              </Button>
            </div>
          )}
          {canNaki(game, game.you, game.turnPlayer, game.now).map((m, i) => (
            <div key={i}>
              <Button variant="contained" onClick={() => naki(m)}>
                {<CNaki m={m} />}
              </Button>
            </div>
          ))}
        </>
      )}

      {game.state === "Turn" && game.turnPlayer === game.you && (
        <>
          {can9syu(game) && (
            <div>
              <Button variant="contained" onClick={() => play(game, { command: "9Syu", player: game.you })}>
                九種九牌
              </Button>
            </div>
          )}
          {canTsumo(game) && (
            <div>
              <Button variant="contained" onClick={() => play(game, { command: "Tsumo", player: game.you })}>
                ツモ
              </Button>
            </div>
          )}
          {canReach(game) && (
            <div className="Reach">
              <Button variant="contained" onClick={() => uiDispatch({ type: "Reach" })}>
                リーチ
              </Button>
            </div>
          )}
          {canKan(game, game.you).map((m, i) => (
            <div key={i}>
              <Button variant="contained" onClick={() => naki(m)}>
                {<CNaki m={m} />}
              </Button>
            </div>
          ))}
        </>
      )}

      {game.state === "Agari" && (
        <>
          {!game.wait[game.you] && (
            <div>
              <Button onClick={() => play(game, { command: "OK", yama: newDeck(game), player: game.you })} variant="contained">
                OK
              </Button>
            </div>
          )}
          <div>
            <Button onClick={() => uiDispatch({ type: "Display" })} variant="contained">
              表示切替
            </Button>
          </div>
        </>
      )}
      {game.state === "Debug" && game.player[game.you].hand.tehai.length < (game.you === game.startPlayer ? 14 : 13) && (
        <div>
          <Button onClick={() => play(game, { command: "All", player: game.you })} variant="contained">
            適当に取る
          </Button>
        </div>
      )}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  return (
    <div className="Game">
      <div className="Field">
        {game.state === "End" ? (
          <CEnd />
        ) : (
          <>
            <CBa />
            <CWanpai />
            <div className="Yama">残{game.yama.length}</div>
            <CHuriten />
            <CPlayer id={nextPlayer(game, game.you, 1)} />
            <CPlayer id={nextPlayer(game, game.you, 2)} />
            {game.setting.seat.length === 4 && <CPlayer id={nextPlayer(game, game.you, 3)} />}
            <CPlayer id={game.you} />
            {game.state === "Agari" && <CAgari />}
            <CMati />
            <CAgari />
            <CDraft />
            <CDebug />
          </>
        )}
      </div>
      <CAction />
    </div>
  );
}

function initDeck(game: Game): Hai[] {
  let id = 0;
  let deck: Hai[] = [];

  if (game.setting.seat.length === 4 || game.parallel) {
    [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((n) =>
      [...Array(4)].forEach(() => deck.push({ id: id++, suit: "マンズ", num: n, aka: false, clear: false }))
    );
  } else {
    [1, 9].forEach((n) => [...Array(4)].forEach(() => deck.push({ id: id++, suit: "マンズ", num: n, aka: false, clear: false })));
  }
  ["ピンズ", "ソーズ"].map((s) =>
    [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((n) => [...Array(4)].forEach(() => deck.push({ id: id++, suit: s, num: n, aka: false, clear: false })))
  );
  ["東", "南", "西", "北", "白", "発", "中"].map((s) =>
    [...Array(4)].forEach(() => deck.push({ id: id++, suit: s, num: 0, aka: false, clear: false }))
  );

  //赤ドラ
  if (game.setting.aka) {
    deck.forEach((h, i) => {
      if (h.num === 5 && h.id % 4 === 0) deck[i].aka = true;
    });
  }

  //透明牌
  deck.forEach((h, i) => {
    if (h.id % 4 < Number(game.setting.clear)) deck[i].clear = true;
  });

  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const sdeck = shuffle(
    initDeck(game).map((h) => h.id),
    r
  );
  return sdeck;
}

function newOrder(game: Game) {
  let order = game.setting.seat.map((_, i) => i);
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(order, r);
  } else {
    return order;
  }
}

function nextGame(game: Game, yama: number[]) {
  const agari: Agari = game.tmp;
  game.player.forEach((_, i) => {
    game.player[i].score += agari.scores[i];
    if (game.player[i].reach) game.ba.bar1000++;
  });
  let cont: boolean = true;

  switch (agari.kind) {
    case "ロン":
    case "ツモ":
      cont = agari.player === game.startPlayer; // 親あがり
      game.player[agari.player].score += game.ba.bar1000 * 1000;
      game.ba.bar1000 = 0;
      game.ba.bar100 = cont ? game.ba.bar100 + 1 : 0;
      break;

    case "流局":
      cont = game.player[game.startPlayer].tenpais.length > 0; //親テンパイ
      game.ba.bar100++;
      break;

    case "九種九牌":
    case "四風子連打":
    case "四開槓":
    case "四家立直":
      cont = true;
      game.ba.bar100++;
      break;

    default:
      console.error("Unknown Agari Kind");
      break;
  }

  let finish = false;
  // console.log("nextGame");
  if (!cont) {
    if (game.ba.num === game.setting.seat.length) {
      switch (game.ba.kaze) {
        case "東":
          game.ba.kaze = "南";
          break;

        case "南":
          game.ba.kaze = "西";
          break;

        case "西":
          game.ba.kaze = "北";
          break;

        default:
          console.error("Unknown Kaze");
          break;
      }
      game.ba.num = 1;
    } else {
      game.ba.num++;
    }
    game.startPlayer = nextPlayer(game, game.startPlayer);
  }

  if (game.ba.kaze === "西" || game.ba.kaze === "北") {
    if (game.player.some((p) => p.score >= 30000)) {
      finish = true;
    }
  }

  if (finish) {
    game.state = "End";
  } else {
    game.turnPlayer = game.startPlayer;
    game.wanpai = { hais: [], dora: 1, doraReserv: 0 };
    game.player = game.player.map((p, i) => ({
      name: p.name,
      hand: { tehai: [], naki: [], last: -1 },
      kawa: [],
      huri: [],
      reach: false,
      reachIdx: -1,
      double: false,
      ippatsu: false,
      tenpais: [],
      huriten: { sute: false, reach: false, yama: false },
      score: p.score,
      kaze: getKaze(game, game.startPlayer, i),
    }));
    game.yama = yama;
    game.now = -1;
    game.kan = false;
    game.haitei = false;
    game.first = true;

    if (game.setting.haipai === "Draft") {
      game.wanpai.hais = game.yama.splice(0, 14);
      game.player.forEach((_, i) => (game.draft[i] = game.yama.splice(0, 13)));
      game.wait = game.player.map(() => false);
      game.draftCnt = 0;
      game.state = "Draft";
    } else if (game.setting.haipai === "Select") {
      game.state = "Debug";
    } else {
      game.wanpai.hais = game.yama.splice(0, 14);
      game.player.forEach((_, i) => (game.player[i].hand.tehai = game.yama.splice(0, 13)));
      game.player[game.startPlayer].hand.last = yama.splice(0, 1)[0];
      game.player[game.startPlayer].hand.tehai.push(game.player[game.startPlayer].hand.last);
      game.now = game.player[game.startPlayer].hand.last;
      game = updateTenpai(game);
      game.state = "Turn";
    }
  }

  game = updateTenpai(game);

  return game;
}

function startGame(game: Game, yama: number[], order: number[]) {
  game.hais = initDeck(game);
  game.order = order;
  game.startPlayer = game.order[0];
  game.turnPlayer = game.startPlayer;
  game.wanpai = { hais: [], dora: 1, doraReserv: 0 };
  game.player = game.setting.seat.map((p, i) => ({
    name: p,
    hand: { tehai: [], naki: [], last: -1 },
    kawa: [],
    huri: [],
    reach: false,
    reachIdx: -1,
    double: false,
    ippatsu: false,
    tenpais: [],
    huriten: { sute: false, reach: false, yama: false },
    score: 25000,
    kaze: getKaze(game, game.startPlayer, i),
  }));
  game.yama = yama;
  game.kan = false;
  game.haitei = false;
  game.first = true;

  if (game.setting.haipai === "Draft") {
    game.wanpai.hais = game.yama.splice(0, 14);
    game.player.forEach((_, i) => (game.draft[i] = game.yama.splice(0, 13)));
    game.wait = game.player.map(() => false);
    game.draftCnt = 0;
    game.state = "Draft";
  } else if (game.setting.haipai === "Select") {
    game.state = "Debug";
  } else {
    game.wanpai.hais = game.yama.splice(0, 14);
    game.player.forEach((_, i) => (game.player[i].hand.tehai = game.yama.splice(0, 13)));
    game.player[game.startPlayer].hand.last = yama.splice(0, 1)[0];
    game.player[game.startPlayer].hand.tehai.push(game.player[game.startPlayer].hand.last);
    game.now = game.player[game.startPlayer].hand.last;
    game = updateTenpai(game);
    game.state = "Turn";
  }
  // console.log("startGame",game);
  return game;
}

function initGame(env: any): Game {
  // console.log("init");
  return {
    env: env,
    setting: { seat: [], aka: false, clear: "", haipai: "", orderRandom: false },
    state: "Init",
    you: -1,
    hais: [],
    ba: { kaze: "東", num: 1, bar100: 0, bar1000: 0 },
    yama: [],
    wanpai: { hais: [], dora: 0, doraReserv: 0 },
    step: 0,
    order: [],
    player: [],
    turnPlayer: 0,
    startPlayer: 0,
    wait: [],
    tmp: null,
    kan: false,
    haitei: false,
    first: true,
    draft: [],
    draftCnt: -1,
    now: -1,
    parallel: false,
  };
}

function isTenpai(game: Game, player: number) {
  // console.log("isTenpai", game);
  let tehai = game.player[player].hand.tehai.map((h) => game.hais[h]);
  tehai.sort((a, b) => sortMahjong(a, b));
  const canSute = 3 * game.player[player].hand.naki.length + tehai.length === 14;

  function agariCheck(h: Hai) {
    if (["マンズ", "ピンズ", "ソーズ"].includes(h.suit) && (h.num < 1 || h.num > 9)) return false;
    return true;
  }

  function rec(t: Hai[], tenpai: Tenpai) {
    // console.log(t, tenpai);
    if (t.length === 0) return [tenpai];
    let ret: Tenpai[] = [];

    //国士
    if (t.length === 13) {
      const yn = yaochuHai.map((y) => t.filter((h) => h.suit === y.suit && h.num === y.num).length);
      if (yn.every((n) => n === 1)) {
        yaochuHai.forEach((y) => {
          const ntenpai: Tenpai = {
            ...tenpai,
            agari: y,
            mati: "Kokushi",
            mentsu: [
              {
                kind: "Kokushi",
                hais: t.map((h) => h.id),
                from: -1,
              },
            ],
          };
          ret.push(ntenpai);
        });
        return ret;
      }
      if (yn.filter((n) => n === 1).length === 11 && yn.filter((n) => n === 2).length === 1) {
        const ntenpai: Tenpai = {
          ...tenpai,
          agari: yaochuHai[yn.indexOf(0)],
          mati: "Kokushi",
          mentsu: [
            {
              kind: "Kokushi",
              hais: t.map((h) => h.id),
              from: -1,
            },
          ],
        };
        ret.push(ntenpai);
        return ret;
      }
    }

    //コーツ
    if (t.length >= 3 && t[1].suit === t[0].suit && t[1].num === t[0].num && t[2].suit === t[0].suit && t[2].num === t[0].num) {
      const m: Mentsu = { kind: "Kotsu", hais: [t[0].id, t[1].id, t[2].id], from: -1 };
      const ntenpai = { ...tenpai, mentsu: [...tenpai.mentsu, m] };
      const nt = t.filter((h) => !m.hais.includes(h.id));
      ret = ret.concat(rec(nt, ntenpai));
    }

    if (t.length >= 2 && t[1].suit === t[0].suit && t[1].num === t[0].num) {
      //トイツ
      {
        const m: Mentsu = { kind: "Toitsu", hais: [t[0].id, t[1].id], from: -1 };
        const ntenpai = { ...tenpai, mentsu: [...tenpai.mentsu, m] };
        const nt = t.filter((h) => !m.hais.includes(h.id));
        ret = ret.concat(rec(nt, ntenpai));
      }
      //トイツターツ
      if (tenpai.mati === "") {
        const m: Mentsu = { kind: "Kotsu", hais: [t[0].id, t[1].id, -1], from: -1 };
        const a = { ...blankHai, suit: t[0].suit, num: t[0].num };
        if (agariCheck(a)) {
          const ntenpai: Tenpai = { ...tenpai, mentsu: [m, ...tenpai.mentsu], agari: a, mati: "Syabo" };
          const nt = t.filter((h) => !m.hais.includes(h.id));
          ret = ret.concat(rec(nt, ntenpai));
        }
      }
    }

    //シュンツ
    const [p1, p2] = [1, 2].map((d) => t.findIndex((h) => h.suit === t[0].suit && h.num === t[0].num + d));
    if (p1 !== -1 && p2 !== -1) {
      const m: Mentsu = { kind: "Syuntsu", hais: [t[0].id, t[p1].id, t[p2].id], from: -1 };
      const ntenpai = { ...tenpai, mentsu: [...tenpai.mentsu, m] };
      const nt = t.filter((h) => !m.hais.includes(h.id));
      ret = ret.concat(rec(nt, ntenpai));
    }
    if (tenpai.mati === "") {
      //リャンメンターツ
      if (p1 !== -1) {
        const mp2: Mentsu = { kind: "Syuntsu", hais: [t[0].id, t[p1].id, -1], from: -1 };
        const ap2 = { ...blankHai, suit: t[0].suit, num: t[0].num + 2 };
        if (agariCheck(ap2)) {
          const mati = t[0].num === 1 ? "Penchan" : "Ryanmen";
          const ntenpai: Tenpai = { ...tenpai, mentsu: [mp2, ...tenpai.mentsu], agari: ap2, mati: mati };
          const nt = t.filter((h) => !mp2.hais.includes(h.id));
          ret = ret.concat(rec(nt, ntenpai));
        }

        const mm1: Mentsu = { kind: "Syuntsu", hais: [-1, t[0].id, t[p1].id], from: -1 };
        const am1 = { ...blankHai, suit: t[0].suit, num: t[0].num - 1 };
        if (agariCheck(am1)) {
          const mati = t[0].num === 8 ? "Penchan" : "Ryanmen";
          const ntenpai: Tenpai = { ...tenpai, mentsu: [mm1, ...tenpai.mentsu], agari: am1, mati: mati };
          const nt = t.filter((h) => !mm1.hais.includes(h.id));
          ret = ret.concat(rec(nt, ntenpai));
        }
      }
      //カンチャンターツ
      if (p2 !== -1) {
        const m: Mentsu = { kind: "Syuntsu", hais: [t[0].id, -1, t[p2].id], from: -1 };
        const a = { ...blankHai, suit: t[0].suit, num: t[0].num + 1 };
        if (agariCheck(a)) {
          const ntenpai: Tenpai = { ...tenpai, mentsu: [m, ...tenpai.mentsu], agari: a, mati: "Kanchan" };
          const nt = t.filter((h) => !m.hais.includes(h.id));
          ret = ret.concat(rec(nt, ntenpai));
        }
      }
    }

    //単騎ターツ
    if (tenpai.mati === "") {
      const m: Mentsu = { kind: "Toitsu", hais: [t[0].id, -1], from: -1 };
      const a = { ...blankHai, suit: t[0].suit, num: t[0].num };
      if (agariCheck(a)) {
        const ntenpai: Tenpai = { ...tenpai, mentsu: [m, ...tenpai.mentsu], agari: a, mati: "Tanki" };
        const nt = t.filter((h) => !m.hais.includes(h.id));
        ret = ret.concat(rec(nt, ntenpai));
      }
    }

    return ret;
  }

  let ret = rec(tehai, { agari: { ...blankHai }, mentsu: [], sute: -1, mati: "" });
  if (canSute) {
    tehai.forEach((s) => {
      const nt = tehai.filter((h) => h.id !== s.id);
      let t = rec(nt, { agari: { ...blankHai }, mentsu: [], sute: s.id, mati: "" });
      ret = ret.concat(t);
    });
  }

  // console.log(ret);
  //メンツ数チェック
  const mn = game.player[player].hand.naki.length;
  ret = ret.filter((tenpai) => {
    if (tenpai.mati === "Kokushi") return true;
    let m2 = tenpai.mentsu.filter((m) => m.kind === "Toitsu").length;
    let m3 = tenpai.mentsu.length - m2 + mn;

    if (m3 === 4 && m2 === 1) {
      if (
        tenpai.mentsu
          .flatMap((m) => m.hais)
          .filter((h) => h !== -1)
          .filter((h) => game.hais[h].suit === tenpai.agari.suit && game.hais[h].num === tenpai.agari.num).length === 4
      )
        return false;
      return true;
    }
    if (m3 === 0 && m2 === 7) {
      //チートイ4枚使いチェック
      let tt = tenpai.mentsu.map((m) => m.hais[0]);
      if (tt.every((t1) => tt.filter((t2) => game.hais[t1].suit === game.hais[t2].suit && game.hais[t1].num === game.hais[t2].num).length === 1))
        return true;
    }

    return false;
  });

  // console.log(ret);
  return ret;
}

function isAgariHai(game: Game, player: number, ronHai: HaiID) {
  let ron = game.hais[ronHai];

  if (player === game.turnPlayer) return false;

  let tenpai = game.player[player].tenpais.filter((t) => t.agari.suit === ron.suit && t.agari.num === ron.num);
  if (tenpai.length === 0) return false;

  return true;
}

function canRon(game: Game, player: number, ronHai: HaiID) {
  const p = game.player[player];
  if (p.huriten.reach || p.huriten.sute || p.huriten.yama) return false;

  if (player === game.turnPlayer) return false;

  const tenpai = p.tenpais.filter((t) => t.agari.suit === game.hais[ronHai].suit && t.agari.num === game.hais[ronHai].num);
  const yss = tenpai.map((t) => getYakus(game, player, t, ronHai, false));

  if (game.kan && game.tmp.naki.kind === "Ankan") {
    return yss.some((ys) => ys.some((y) => y.name === "国士無双"));
  }

  return yss.some((ys) => ys.length > 0);
}

function canTsumo(game: Game) {
  if (game.player[game.turnPlayer].hand.last === -1) return false;

  const last = game.hais[game.player[game.turnPlayer].hand.last];
  let tenpai = game.player[game.turnPlayer].tenpais.filter((t) => t.sute === last.id && t.agari.suit === last.suit && t.agari.num === last.num);
  return tenpai.map((t) => getYakus(game, game.turnPlayer, t, game.player[game.turnPlayer].hand.last, true)).some((y) => y.length > 0);
}

function canReach(game: Game) {
  let p = game.player[game.turnPlayer];

  //立直してない,面前,1000点ある,ツモ牌残ってる
  if (p.reach) return false;
  if (p.hand.naki.some((n) => n.kind !== "Ankan")) return false;
  if (p.score < 1000) return false;
  if (game.yama.length < game.player.length) return false;

  return p.tenpais.length > 0;
}

function can9syu(game: Game) {
  if (game.setting.seat.length === 3) return false;
  const tehai = game.player[game.turnPlayer].hand.tehai.map((h) => game.hais[h]);
  const yn = yaochuHai.map((y) => tehai.filter((h) => h.suit === y.suit && h.num === y.num).length);
  return yn.filter((y) => y > 0).length >= 9;
}

function getAgariHais(game: Game, sute: HaiID) {
  let p = game.player[game.turnPlayer];

  function unique(acc: Hai[], cur: Hai) {
    if (!acc.some((h) => h.suit === cur.suit && h.num === cur.num)) acc.push(cur);
    return acc;
  }

  let ret = p.tenpais
    .filter((t) => t.sute === sute)
    .map((t) => t.agari)
    .sort(sortMahjong)
    .reduce(unique, []);

  return ret;
}

function canKan(game: Game, player: number) {
  let tehai = game.player[player].hand.tehai.map((h) => game.hais[h]);
  tehai.sort((a, b) => sortMahjong(a, b));
  let ret: Mentsu[] = [];
  let cnt = 1;
  let bh = tehai[0];

  //山牌なし
  if (game.yama.length === 0) return ret;
  //カン4回まで
  if (game.wanpai.dora + game.wanpai.doraReserv === 5) return ret;

  for (let i = 1; i < tehai.length; i++) {
    if (tehai[i].suit === bh.suit && tehai[i].num === bh.num) {
      cnt++;
      if (cnt === 4) {
        //立直中の待ちが変わるかどうか
        let hais = [tehai[i - 3].id, tehai[i - 2].id, tehai[i - 1].id, tehai[i].id];
        if (game.player[player].reach) {
          if (!hais.includes(game.player[player].hand.last)) continue;
          if (game.player[player].tenpais.some((t) => t.mentsu.some((m) => m.kind !== "Kotsu" && m.hais.some((h) => hais.includes(h))))) continue;
        }
        ret.push({
          kind: "Ankan",
          hais: hais,
          from: -1,
        });
      }
    } else {
      cnt = 1;
      bh = tehai[i];
    }
  }
  game.player[player].hand.naki.forEach((m) => {
    if (m.kind === "Pon") {
      let i = tehai.findIndex((h) => h.num === game.hais[m.hais[0]].num && h.suit === game.hais[m.hais[0]].suit);
      if (i !== -1) {
        let nn = { kind: "Kakan", hais: [...m.hais, tehai[i].id], from: m.from };
        ret.push(nn);
      }
    }
  });

  return ret;
}

function canNaki(game: Game, nakiPlayer: number, haiPlayer: number, hai: HaiID) {
  let ret: Mentsu[] = [];
  const tehai = game.player[nakiPlayer].hand.tehai.map((h) => game.hais[h]);
  const nakihai = game.hais[hai];

  if (nakiPlayer === haiPlayer) return [];
  if (game.yama.length === 0) return [];
  if (game.player[nakiPlayer].reach) return [];
  if (game.kan) return []; //チャンカンのみ

  function unique(acc: Hai[], cur: Hai) {
    if (!acc.some((h) => h.num === cur.num && h.aka === cur.aka && h.clear === cur.clear)) acc.push(cur);
    return acc;
  }

  //ポン・カン
  const same = tehai.filter((h) => h.suit === nakihai.suit && h.num === nakihai.num);
  if (same.length === 3) {
    if (game.wanpai.dora + game.wanpai.doraReserv < 5) {
      let k: Mentsu = {
        kind: "Kan",
        hais: [same[0].id, same[1].id, same[2].id],
        from: [-1, 3, 1, 0][seatPos(game, nakiPlayer, haiPlayer)],
      };
      k.hais.splice(k.from, 0, nakihai.id);
      ret.push(k);
    }

    // ポン組み合わせUniq
    let ph = [
      [same[0], same[1]],
      [same[0], same[2]],
      [same[1], same[2]],
    ];
    ph = ph.reduce((acc: Hai[][], cur) => {
      if (!acc.some((a) => a.every((ah) => cur.some((ch) => ah.aka === ch.aka && ah.clear === ch.clear)))) acc.push(cur);
      return acc;
    }, []);
    let ps: Mentsu[] = ph.map((p) => ({ kind: "Pon", hais: p.map((h) => h.id), from: [-1, 2, 1, 0][seatPos(game, nakiPlayer, haiPlayer)] }));
    ps.forEach((_, i) => ps[i].hais.splice(ps[i].from, 0, nakihai.id));
    ret.push(...ps);
  }

  if (same.length === 2) {
    let p = {
      kind: "Pon",
      hais: [same[0].id, same[1].id],
      from: [-1, 2, 1, 0][seatPos(game, nakiPlayer, haiPlayer)],
    };
    p.hais.splice(p.from, 0, nakihai.id);
    ret.push(p);
  }

  //チー
  if (nextPlayer(game, haiPlayer) === nakiPlayer && game.setting.seat.length !== 3) {
    const [m2s, m1s, p1s, p2s] = [-2, -1, 1, 2].map((d) =>
      tehai.filter((h) => h.suit === nakihai.suit && h.num === nakihai.num + d).reduce(unique, [])
    );
    const c1 = m2s.flatMap((m2) =>
      m1s.map((m1) => ({
        kind: "Chi",
        hais: [nakihai.id, m2.id, m1.id],
        from: 0,
      }))
    );
    ret.push(...c1);
    const c2 = m1s.flatMap((m1) =>
      p1s.map((p1) => ({
        kind: "Chi",
        hais: [nakihai.id, m1.id, p1.id],
        from: 0,
      }))
    );
    ret.push(...c2);
    const c3 = p1s.flatMap((p1) =>
      p2s.map((p2) => ({
        kind: "Chi",
        hais: [nakihai.id, p1.id, p2.id],
        from: 0,
      }))
    );
    ret.push(...c3);
  }
  return ret;
}

function nextTsumo(game: Game) {
  if (game.yama.length === 0) {
    game = fixRyukyoku(game);
    return game;
  }

  if (game.kan) {
    //チャンカン待ちの後、リンシャンツモ
    game.player[game.turnPlayer].hand.last = game.yama.splice(-1)[0];
    game.player[game.turnPlayer].hand.tehai.push(game.player[game.turnPlayer].hand.last);
    game.now = game.player[game.turnPlayer].hand.last;
    game = updateTenpai(game);
    if (game.tmp.naki.kind === "Kakan") {
      game.wanpai.doraReserv += 1;
    } else {
      game.wanpai.dora += 1;
    }
    game.player.forEach((_, i) => (game.player[i].ippatsu = false)); //一発消し
  } else {
    //通常ツモ
    // console.log("通常ツモ",game);
    game.state = "Turn";
    game.turnPlayer = nextPlayer(game, game.turnPlayer);
    game.player[game.turnPlayer].hand.last = game.yama.splice(0, 1)[0];
    game.player[game.turnPlayer].hand.tehai.push(game.player[game.turnPlayer].hand.last);
    game.now = game.player[game.turnPlayer].hand.last;
    game = updateTenpai(game);
    game.player[game.turnPlayer].huriten.yama = false;
    game.player[game.turnPlayer].huriten.sute = false;
    if (game.turnPlayer === game.startPlayer) game.first = false;
    if (game.yama.length === 0) game.haitei = true;
  }

  return game;
}

function updateTenpai(game: Game) {
  game.player.forEach((_, i) => {
    game.player[i].tenpais = isTenpai(game, i);
  });
  return game;
}

function fixNaki(game: Game, player: number) {
  const naki: Mentsu = game.wait[player];
  game.player[game.turnPlayer].kawa.splice(-1);
  game.player[player].hand.naki.push(naki);
  game.player[player].hand.tehai = game.player[player].hand.tehai.filter((h) => !naki.hais.includes(h));
  game.turnPlayer = player;
  game.first = false;
  game.state = "Turn";

  if (naki.kind === "Kan") {
    game.player[player].hand.last = game.yama.splice(-1)[0];
    game.player[player].hand.tehai.push(game.player[player].hand.last);
    game.wanpai.doraReserv += 1;
    game.now = game.player[player].hand.last;
    game.kan = true;
  }

  game = updateTenpai(game);
  game.player[game.turnPlayer].huriten.yama = false;
  game.player[game.turnPlayer].huriten.sute = false;

  game.player.forEach((_, i) => (game.player[i].ippatsu = false)); //一発消し

  return game;
}

const yaochuHai: Hai[] = [
  { ...blankHai, suit: "マンズ", num: 1 },
  { ...blankHai, suit: "マンズ", num: 9 },
  { ...blankHai, suit: "ピンズ", num: 1 },
  { ...blankHai, suit: "ピンズ", num: 9 },
  { ...blankHai, suit: "ソーズ", num: 1 },
  { ...blankHai, suit: "ソーズ", num: 9 },
  { ...blankHai, suit: "東", num: 0 },
  { ...blankHai, suit: "南", num: 0 },
  { ...blankHai, suit: "西", num: 0 },
  { ...blankHai, suit: "北", num: 0 },
  { ...blankHai, suit: "白", num: 0 },
  { ...blankHai, suit: "発", num: 0 },
  { ...blankHai, suit: "中", num: 0 },
];

function isYaochu(hai: Hai) {
  return yaochuHai.some((y) => y.suit === hai.suit && y.num === hai.num);
}

function isZihai(hai: Hai) {
  return ["東", "南", "西", "北", "白", "発", "中"].includes(hai.suit);
}

function nextDora(game: Game, hai: Hai): Hai {
  if (game.setting.seat.length === 3) {
    if (hai.suit === "マンズ" && hai.num === 1) return { ...blankHai, suit: "マンズ", num: 9 };
    if (hai.suit === "マンズ" && hai.num === 9) return { ...blankHai, suit: "マンズ", num: 1 };
  }
  if (["マンズ", "ピンズ", "ソーズ"].includes(hai.suit)) {
    return { ...blankHai, suit: hai.suit, num: (hai.num % 9) + 1 };
  }

  const s = ["南", "西", "北", "東", "発", "中", "白"][["東", "南", "西", "北", "白", "発", "中"].indexOf(hai.suit)];
  return { ...blankHai, suit: s, num: 0 };
}

function getYakus(game: Game, player: number, t: Tenpai, agariHai: HaiID, isTsumo: boolean) {
  const p = game.player[player];
  let ret: Yaku[] = [];

  if (t.mati === "Kokushi") {
    ret.push({ name: "国士無双", han: 13 });
    return ret;
  }

  let ms = t.mentsu.concat(p.hand.naki).map((m) => ({
    ...m,
    hais: m.hais.map((h) => game.hais[h === -1 ? agariHai : h]),
  }));
  const zenhai = ms.flatMap((m) => m.hais);
  const isMenzen = p.hand.naki.every((n) => n.kind === "Ankan");
  const atama = ms.find((m) => m.kind === "Toitsu")?.hais[0].suit ?? "エラー";

  if (p.reach) {
    if (p.double) {
      ret.push({ name: "ダブル立直", han: 2 });
    } else {
      ret.push({ name: "立直", han: 1 });
    }
    if (p.ippatsu) {
      ret.push({ name: "一発", han: 1 });
    }
  }

  if (isMenzen && isTsumo) {
    ret.push({ name: "門前清自摸和", han: 1 });
  }

  if (ms.every((m) => m.hais.every((h) => !isYaochu(h)))) {
    ret.push({ name: "断么九", han: 1 });
  }

  if (
    ms.every((m) => m.kind === "Syuntsu" || m.kind === "Toitsu") &&
    t.mati === "Ryanmen" &&
    !["白", "発", "中"].includes(atama) &&
    atama !== game.ba.kaze &&
    atama !== p.kaze &&
    !(atama === "北" && game.setting.seat.length === 3 && !game.parallel)
  ) {
    ret.push({ name: "平和", han: 1 });
  }

  // シュンツ(チー含む)の先頭牌
  const ss = ms.filter((m) => m.kind === "Syuntsu" || m.kind === "Chi").map((m) => m.hais.reduce((ac, cv) => (ac.num < cv.num ? ac : cv)));
  if (isMenzen) {
    const ssn = ss.map((s1) => ss.filter((s2) => s1.num === s2.num && s1.suit === s2.suit).length);
    if (ssn.length === 4 && ssn.every((n) => n === 2)) {
      ret.push({ name: "二盃口", han: 3 });
    } else if (ssn.some((n) => n >= 2)) {
      ret.push({ name: "一盃口", han: 1 });
    }
  }

  const gen3 = ms.filter((m) => m.kind !== "Toitsu" && ["白", "発", "中"].includes(m.hais[0].suit)).length;
  const gen2 = ms.filter((m) => m.kind === "Toitsu" && ["白", "発", "中"].includes(m.hais[0].suit)).length;

  if (gen3 === 3) {
    ret.push({ name: "大三元", han: 13 });
  } else if (gen3 === 2 && gen2 === 1) {
    ret.push({ name: "小三元", han: 2 });
  } else if (gen3 >= 1) {
    ret.push({ name: "役牌", han: gen3 });
  }

  if (ms.some((m) => m.kind !== "Toitsu" && m.hais[0].suit === p.kaze)) {
    ret.push({ name: "自風", han: 1 });
  }
  if (ms.some((m) => m.kind !== "Toitsu" && m.hais[0].suit === game.ba.kaze)) {
    ret.push({ name: "場風", han: 1 });
  }
  if (game.setting.seat.length === 3 && ms.some((m) => m.kind !== "Toitsu" && m.hais[0].suit === "北" && !game.parallel)) {
    ret.push({ name: "北", han: 1 });
  }

  if (game.kan) {
    if (isTsumo) {
      ret.push({ name: "嶺上開花", han: 1 });
    } else {
      ret.push({ name: "槍槓", han: 1 });
    }
  }

  if (game.haitei && isTsumo) {
    ret.push({ name: "海底摸月", han: 1 });
  }

  if (game.yama.length === 0 && !isTsumo) {
    //直前カンでも成立
    ret.push({ name: "河底撈魚", han: 1 });
  }

  if ([...Array(9)].some((_, i) => ["マンズ", "ピンズ", "ソーズ"].every((su) => ss.some((sy) => sy.suit === su && sy.num === i + 1)))) {
    ret.push({ name: "三色同順", han: isMenzen ? 2 : 1 });
  }

  if (["マンズ", "ピンズ", "ソーズ"].some((su) => [1, 4, 7].every((n) => ss.some((s) => s.suit === su && s.num === n)))) {
    ret.push({ name: "一気通貫", han: isMenzen ? 2 : 1 });
  }

  if (zenhai.every((h) => h.num === 1 || h.num === 9)) {
    ret.push({ name: "清老頭", han: 13 });
  } else if (zenhai.every((h) => isYaochu(h))) {
    ret.push({ name: "混老頭", han: 2 });
  } else if (ms.every((m) => m.hais.some((h) => h.num === 1 || h.num === 9))) {
    ret.push({ name: "純全帯么九", han: isMenzen ? 3 : 2 });
  } else if (ms.every((m) => m.hais.some((h) => isYaochu(h)))) {
    ret.push({ name: "混全帯么九", han: isMenzen ? 2 : 1 });
  }

  if (ms.every((m) => m.kind === "Toitsu")) {
    ret.push({ name: "七対子", han: 2 });
  }

  const anko = ms.filter((m, i) => m.kind === "Kotsu" && (i !== 0 || isTsumo)).length;
  if (anko === 4) {
    ret.push({ name: "四暗刻", han: 13 });
  } else {
    if (ms.every((m) => m.kind !== "Syuntsu" && m.kind !== "Chi") && ms.some((m) => m.hais.length === 3)) {
      //チートイと両立しないように
      ret.push({ name: "対々和", han: 2 });
    }
    if (anko === 3) {
      ret.push({ name: "三暗刻", han: 2 });
    }
  }

  //コーツ類の先頭牌
  const ks = ms.filter((m) => ["Kotsu", "Pon", "Kan", "Ankan", "Kakan"].includes(m.kind)).map((m) => m.hais[0]);
  if ([...Array(9)].some((_, i) => ["マンズ", "ピンズ", "ソーズ"].every((su) => ks.some((k) => k.suit === su && k.num === i + 1)))) {
    ret.push({ name: "三色同刻", han: 2 });
  }

  const kans = ms.filter((m) => m.hais.length === 4).length;
  if (kans === 4) {
    ret.push({ name: "四槓子", han: 13 });
  } else if (kans === 3) {
    ret.push({ name: "三槓子", han: 2 });
  }

  if (["マンズ", "ピンズ", "ソーズ"].some((su) => zenhai.every((h) => h.suit === su))) {
    ret.push({ name: "清一色", han: isMenzen ? 6 : 5 });
    if (p.hand.naki.length === 0) {
      const ns = [...Array(9)].map((_, i) => zenhai.filter((h) => h.num === i + 1).length);
      if (ns[0] >= 3 && ns[8] >= 3 && ns.every((n) => n >= 1)) {
        ret.push({ name: "九蓮宝燈", han: 13 });
      }
    }
  } else if (["マンズ", "ピンズ", "ソーズ"].some((su) => zenhai.every((h) => h.suit === su || isZihai(h)))) {
    ret.push({ name: "混一色", han: isMenzen ? 3 : 2 });
  }

  if (zenhai.every((h) => isZihai(h))) {
    ret.push({ name: "字一色", han: 13 });
  }

  const kaze3 = ms.filter((m) => m.kind !== "Toitsu" && ["東", "南", "西", "北"].includes(m.hais[0].suit)).length;
  const kaze2 = ms.filter((m) => m.kind === "Toitsu" && ["東", "南", "西", "北"].includes(m.hais[0].suit)).length;
  if (kaze3 === 4) {
    ret.push({ name: "大四喜", han: 13 });
  } else if (kaze3 === 3 && kaze2 === 1) {
    ret.push({ name: "小四喜", han: 13 });
  }

  if (zenhai.every((h) => h.suit === "発" || (h.suit === "ソーズ" && [2, 3, 4, 6, 8].includes(h.num)))) {
    ret.push({ name: "緑一色", han: 13 });
  }

  if (game.first && isTsumo && p.hand.naki.length === 0) {
    if (player === game.startPlayer) {
      ret.push({ name: "天和", han: 13 });
    } else {
      ret.push({ name: "地和", han: 13 });
    }
  }

  if (ret.some((y) => y.han === 13)) {
    ret = ret.filter((y) => y.han === 13); //役満のみ
  } else {
    if (ret.length > 0) {
      let dora = game.wanpai.hais.slice(0, game.wanpai.dora).flatMap((w) => {
        let d = nextDora(game, game.hais[w]);
        return zenhai.filter((h) => h.suit === d.suit && h.num === d.num);
      }).length;
      if (dora > 0) {
        ret.push({ name: "ドラ", han: dora });
      }
      let akadora = zenhai.filter((h) => h.aka).length;
      if (akadora > 0) {
        ret.push({ name: "赤ドラ", han: akadora });
      }
      if (p.reach) {
        let uradora = game.wanpai.hais.slice(5, 5 + game.wanpai.dora).flatMap((w) => {
          let d = nextDora(game, game.hais[w]);
          return zenhai.filter((h) => h.suit === d.suit && h.num === d.num);
        }).length;
        if (uradora > 0) {
          ret.push({ name: "裏ドラ", han: uradora });
        }
      }
    }
  }

  return ret;
}

function getHu(game: Game, player: number, t: Tenpai, agariHai: HaiID, isTsumo: boolean) {
  const p = game.player[player];

  let ms = t.mentsu.concat(p.hand.naki);

  let ret = 20;
  if (!ms.some((m) => ["Pon", "Kan", "Chi", "Kakan"].includes(m.kind)) && !isTsumo) ret += 10; //面前ロン 30符

  ms.forEach((m, i) => {
    let h = game.hais[m.hais[0]];
    switch (m.kind) {
      case "Pon":
        ret += isYaochu(h) ? 4 : 2;
        break;

      case "Kotsu":
        if (!isTsumo && i === 0) ret += isYaochu(h) ? 4 : 2;
        else ret += isYaochu(h) ? 8 : 4;
        break;

      case "Kan":
      case "Kakan":
        ret += isYaochu(h) ? 16 : 8;
        break;

      case "Ankan":
        ret += isYaochu(h) ? 32 : 16;
        break;
    }
  });

  const atama = game.hais[ms.find((m) => m.kind === "Toitsu")?.hais[0] ?? -1].suit;
  if (["白", "発", "中"].includes(atama)) ret += 2;
  if (atama === game.ba.kaze || atama === p.kaze) ret += 2;

  switch (t.mati) {
    case "Penchan":
    case "Kanchan":
    case "Tanki":
      ret += 2;
      break;

    case "Syabo":
    case "Ryanmen":
      break;

    default:
      console.error("Unknown mati");
      break;
  }

  if (isTsumo) ret += 2;

  ret = Math.ceil(ret / 10) * 10;
  if (ret === 20) ret = 30; //喰いピンフは30

  return ret;
}

function getScores(game: Game, player: number, isTsumo: boolean, yakus: Yaku[], hu: number) {
  const han = yakus.reduce((ac, cv) => ac + cv.han, 0);
  const isOya = game.startPlayer === player;

  let total = "";
  let scores: number[] = []; //([0]:ロンあがり点, [1]:ツモ子支払い,[2]:ツモ親支払い)

  if (han === 0) {
    scores = [0, 0, 0];
  } else if (han >= 13) {
    total = "役満";
    scores = isOya ? [48000, 16000] : [32000, 8000, 16000];
  } else if (han >= 11) {
    total = "3倍満";
    scores = isOya ? [36000, 12000] : [24000, 6000, 12000];
  } else if (han >= 8) {
    total = "倍満";
    scores = isOya ? [24000, 8000] : [16000, 4000, 8000];
  } else if (han >= 6) {
    total = "ハネ満";
    scores = isOya ? [18000, 6000] : [12000, 3000, 6000];
  } else if (han >= 5 || (han === 4 && hu >= 40) || (han === 3 && hu >= 70)) {
    total = "満貫";
    scores = isOya ? [12000, 4000] : [8000, 2000, 4000];
  } else {
    total = han + "飜" + hu + "符";
    switch (han) {
      case 4:
        scores = [
          isOya ? [0, 2600] : [0, 1300, 2600], //20
          isOya ? [9600, 3200] : [6400, 1600, 3200], //25
          isOya ? [11600, 3900] : [7700, 2000, 3900], //30
        ][[20, 25, 30].indexOf(hu)];
        break;
      case 3:
        scores = [
          isOya ? [0, 1300] : [0, 700, 1300], //20
          isOya ? [4800, 1600] : [3200, 800, 1600], //25
          isOya ? [5800, 2000] : [3900, 1000, 2000], //30
          isOya ? [7700, 2600] : [5200, 1300, 2600], //40
          isOya ? [9600, 3200] : [6400, 1600, 3200], //50
          isOya ? [11600, 3900] : [7700, 2000, 3900], //60
        ][[20, 25, 30, 40, 50, 60].indexOf(hu)];
        break;
      case 2:
        scores = [
          isOya ? [0, 700] : [0, 400, 700], //20
          isOya ? [2400, 0] : [1600, 0, 0], //25
          isOya ? [2900, 1000] : [2000, 500, 1000], //30
          isOya ? [3900, 1300] : [2600, 700, 1300], //40
          isOya ? [4800, 1600] : [3200, 800, 1600], //50
          isOya ? [5800, 2000] : [3900, 1000, 2000], //60
          isOya ? [6800, 2300] : [4500, 1200, 2300], //70
          isOya ? [7700, 2600] : [5200, 1300, 2600], //80
          isOya ? [8700, 2900] : [5800, 1500, 2900], //90
          isOya ? [9600, 3200] : [6400, 1600, 3200], //100
          isOya ? [10600, 3600] : [7100, 1800, 3600], //110
        ][[20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 110].indexOf(hu)];
        break;
      case 1:
        scores = [
          isOya ? [1500, 500] : [1000, 300, 500], //30
          isOya ? [2000, 700] : [1300, 400, 700], //40
          isOya ? [2400, 800] : [1600, 400, 800], //50
          isOya ? [2900, 1000] : [2000, 500, 1000], //60
          isOya ? [3400, 1200] : [2300, 600, 1200], //70
          isOya ? [3900, 1300] : [2600, 700, 1300], //80
          isOya ? [4400, 1500] : [2900, 800, 1500], //90
          isOya ? [4800, 1600] : [3200, 800, 1600], //100
          isOya ? [5300, 1800] : [3600, 900, 1800], //110
        ][[30, 40, 50, 60, 70, 80, 90, 100, 110].indexOf(hu)];
        break;
    }
  }

  //積み棒
  scores[0] += game.ba.bar100 * 300;
  scores[1] += game.ba.bar100 * 100;
  scores[2] += game.ba.bar100 * 100;

  const ret = game.player.map((_, i) => {
    if (game.setting.seat.length === 4) {
      if (i === player) {
        if (i === game.startPlayer) {
          return isTsumo ? scores[1] * 3 : scores[0];
        } else {
          return isTsumo ? scores[1] * 2 + scores[2] : scores[0];
        }
      } else {
        if (isTsumo) {
          return i === game.startPlayer ? -scores[2] : -scores[1];
        } else {
          return i === game.turnPlayer ? -scores[0] : 0;
        }
      }
    } else {
      //3人打ち ツモ損
      if (i === player) {
        if (i === game.startPlayer) {
          return isTsumo ? scores[1] * 2 : scores[0];
        } else {
          return isTsumo ? scores[1] * 1 + scores[2] : scores[0];
        }
      } else {
        if (isTsumo) {
          return i === game.startPlayer ? -scores[2] : -scores[1];
        } else {
          return i === game.turnPlayer ? -scores[0] : 0;
        }
      }
    }
  });

  return { total: total, scores: ret };
}

function createAgari(game: Game, player: number, isTsumo: boolean, agariHai: HaiID) {
  let ts = game.player[player].tenpais.filter((t) => t.agari.suit === game.hais[agariHai].suit && t.agari.num === game.hais[agariHai].num);
  if (isTsumo) ts = ts.filter((t) => t.sute === agariHai);
  const as: Agari[] = ts.map((t) => {
    const ys = getYakus(game, player, t, agariHai, isTsumo);
    let hu = 0;
    if (ys.some((y) => y.name === "平和") && isTsumo) hu = 20;
    else if (ys.some((y) => y.name === "七対子")) hu = 25;
    else if (ys.some((y) => y.name === "国士無双")) hu = 0;
    else hu = getHu(game, player, t, agariHai, isTsumo);
    const sc = getScores(game, player, isTsumo, ys, hu);
    return {
      player: player,
      kind: isTsumo ? "ツモ" : "ロン",
      hai: agariHai,
      yakus: ys,
      total: sc.total,
      scores: sc.scores,
    };
  });
  console.log("as", as);
  return as.reduce((ac, cv) => (ac.scores[player] > cv.scores[player] ? ac : cv));
}

function fixRon(game: Game, player: number) {
  game.state = "Agari";
  game.tmp = createAgari(game, player, false, game.wait[player].hais[0]);
  game.wait = game.player.map((_) => false);

  return game;
}

function fixTsumo(game: Game) {
  game.state = "Agari";
  game.tmp = createAgari(game, game.turnPlayer, true, game.player[game.turnPlayer].hand.last);
  game.wait = game.player.map((_) => false);

  return game;
}

function fixRyukyoku(game: Game) {
  game.state = "Agari";
  const ts = game.player.map((p, i) => p.tenpais.length > 0);
  const tn = ts.filter((t) => t).length;
  let agari: Agari = {
    player: -1,
    kind: "流局",
    hai: -1,
    scores: [0, 0, 0, 0],
    total: "",
    yakus: [],
  };

  if (1 <= tn && tn < game.setting.seat.length) {
    agari.scores = game.player.map((_, i) => (ts[i] ? 3000 / tn : -3000 / (game.setting.seat.length - tn)));
  }
  game.tmp = agari;
  game.wait = game.player.map((_) => false);

  return game;
}

function spRyukyoku(game: Game, kind: string, player: number) {
  game.state = "Agari";
  let agari: Agari = {
    player: player,
    kind: kind,
    hai: -1,
    scores: [0, 0, 0, 0],
    total: "",
    yakus: [],
  };

  game.tmp = agari;
  game.wait = game.player.map((_) => false);

  return game;
}

function check4kan(game: Game) {
  const kn = game.player.map((p) => p.hand.naki.filter((n) => ["Kan", "Ankan", "Kakan"].includes(n.kind)).length);
  if (kn.every((n) => n < 4) && kn.reduce((ac, cv) => ac + cv) === 4) {
    game = spRyukyoku(game, "四開槓", -1);
  }
  return game;
}

function fixDraft(ng: Game) {
  ng.player[ng.startPlayer].hand.last = ng.yama.splice(0, 1)[0];
  ng.player[ng.startPlayer].hand.tehai.push(ng.player[ng.startPlayer].hand.last);
  ng.now = ng.player[ng.startPlayer].hand.last;
  ng = updateTenpai(ng);
  ng.state = "Turn";

  return ng;
}

function fixDebug(ng: Game) {
  if (ng.player.every((p, i) => p.hand.tehai.length === (i === ng.startPlayer ? 14 : 13))) {
    ng.wanpai.hais = ng.yama.splice(0, 14);
    ng.player[ng.startPlayer].hand.last = ng.player[ng.startPlayer].hand.tehai[13];
    ng.now = ng.player[ng.startPlayer].hand.last;
    ng = updateTenpai(ng);
    ng.state = "Turn";
  }
  return ng;
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;
  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;

  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGame(ng, action.yama, action.order);
          return ng;
      }
      break;

    case "Turn":
      switch (action.command) {
        case "Dahai":
          console.assert(ng.turnPlayer === action.player);
          ng.player[ng.turnPlayer].hand.last = -1;
          ng.player[ng.turnPlayer].hand.tehai = ng.player[ng.turnPlayer].hand.tehai.filter((h) => h !== action.hai);
          ng = updateTenpai(ng);
          ng.player[ng.turnPlayer].kawa.push(action.hai);
          ng.player[ng.turnPlayer].huri.push(action.hai);
          ng.player[ng.turnPlayer].huriten.sute = ng.player[ng.turnPlayer].huri.some((h) =>
            ng.player[ng.turnPlayer].tenpais.some((t) => t.agari.suit === ng.hais[h].suit && t.agari.num === ng.hais[h].num)
          );
          ng.player[ng.turnPlayer].ippatsu = false;
          ng.now = action.hai;
          ng.kan = false;

          if (
            ng.player.every((p) => p.huri.length === 1 && p.hand.naki.length === 0) &&
            ["東", "南", "西", "北"].some((k) => ng.player.every((p) => ng.hais[p.huri[0]].suit === k) && game.setting.seat.length !== 3)
          ) {
            ng = spRyukyoku(ng, "四風子連打", -1);
            return ng;
          }

          if (action.reach) {
            ng.player[ng.turnPlayer].reach = true;
            ng.player[ng.turnPlayer].reachIdx = ng.player[ng.turnPlayer].kawa.length - 1;
            ng.player[ng.turnPlayer].ippatsu = true;
            ng.player[ng.turnPlayer].double = ng.first && ng.player[ng.turnPlayer].hand.naki.length === 0;
            ng.player[ng.turnPlayer].score -= 1000;

            if (ng.player.every((p) => p.reach) && game.setting.seat.length !== 3) {
              ng = spRyukyoku(ng, "四家立直", -1);
              return ng;
            }
          }
          ng.wait = ng.player.map((_, i) =>
            canNaki(ng, i, action.player, ng.now).length !== 0 || canRon(ng, i, ng.now) ? { kind: "Wait", hais: [] } : { kind: "Pass", hais: [] }
          );
          //新ドラ
          ng.wanpai.dora += ng.wanpai.doraReserv;
          ng.wanpai.doraReserv = 0;
          if (ng.wait.every((n) => n.kind === "Pass")) {
            ng = nextTsumo(ng);
          } else {
            ng.state = "Naki";
          }
          return ng;

        case "Naki":
          //自カン
          console.assert(ng.turnPlayer === action.player);
          if (action.naki.kind === "Kakan") {
            const i = ng.player[ng.turnPlayer].hand.naki.findIndex((m) => m.hais[0] === action.naki.hais[0]);
            ng.player[ng.turnPlayer].hand.naki.splice(i, 1, action.naki);
          } else {
            ng.player[ng.turnPlayer].hand.naki.push(action.naki);
          }
          ng.player[ng.turnPlayer].hand.tehai = ng.player[ng.turnPlayer].hand.tehai.filter((h) => !action.naki.hais.includes(h));
          ng.kan = true;
          ng.tmp = action;

          ng.wait = ng.player.map((_, i) => (canRon(ng, i, ng.now) ? { kind: "Wait", hais: [] } : { kind: "Pass", hais: [] }));
          if (ng.wait.every((n) => n.kind === "Pass")) {
            ng = nextTsumo(ng);
          } else {
            ng.state = "Naki";
          }
          ng = check4kan(ng);
          return ng;

        case "Tsumo":
          ng = fixTsumo(ng);
          return ng;

        case "9Syu":
          ng = spRyukyoku(ng, "九種九牌", ng.turnPlayer);
          return ng;
      }
      break;

    case "Naki":
      switch (action.command) {
        case "Naki":
          ng.wait[action.player] = action.naki;
          if (action.naki.kind === "Pass" && isAgariHai(ng, action.player, action.naki.hais[0])) {
            ng.player[action.player].huriten.yama = true;
            if (ng.player[action.player].reach) {
              ng.player[action.player].huriten.reach = true;
            }
          }

          if (ng.wait.every((n) => n.kind === "Pass")) {
            ng = nextTsumo(ng);
          } else if (ng.wait.every((n) => n.kind !== "Wait")) {
            const rp = ng.wait.findIndex((n) => n.kind === "Ron");
            if (rp !== -1) {
              ng = fixRon(ng, rp);
              return ng;
            }

            const pp = ng.wait.findIndex((n) => n.kind === "Pon" || n.kind === "Kan");
            if (pp !== -1) {
              ng = fixNaki(ng, pp);
              ng = check4kan(ng);
              return ng;
            }

            const cp = ng.wait.findIndex((n) => n.kind === "Chi");
            if (cp !== -1) {
              ng = fixNaki(ng, cp);
              return ng;
            }
          }
          return ng;
      }
      break;

    case "Agari":
      switch (action.command) {
        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((n) => n)) {
            ng = nextGame(ng, action.yama);
          }

          return ng;
      }
      break;

    case "Draft":
      switch (action.command) {
        case "Select":
          const dy = nextPlayer(ng, action.player, 13 - ng.draftCnt);
          ng.player[action.player].hand.tehai.push(action.hai);
          ng.draft[dy] = ng.draft[dy].filter((h) => h !== action.hai);
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            ng.draftCnt++;
            ng.wait = ng.player.map(() => false);
            if (ng.draftCnt === 13) {
              ng = fixDraft(ng);
            }
          }
          return ng;
      }
      break;

    case "Debug":
      switch (action.command) {
        case "Select":
          ng.player[action.player].hand.tehai.push(action.hai);
          ng.yama = ng.yama.filter((h) => h !== action.hai);
          ng = fixDebug(ng);
          return ng;

        case "All":
          let n = (action.player === ng.startPlayer ? 14 : 13) - ng.player[action.player].hand.tehai.length;
          ng.player[action.player].hand.tehai = ng.player[action.player].hand.tehai.concat(ng.yama.splice(0, n));
          ng = fixDebug(ng);
          return ng;
      }
      break;
  }

  console.error("Unknown State or Action");

  return ng;
}

export const CMahjong = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useEffect(() => {
    console.log("Mahjong Render", game);
  }, [game]);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    UIStateChange(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.state, game.turnPlayer]);

  useEffect(() => {
    soundEffect();
  }, [game.turnPlayer, game.draftCnt]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", yama: newDeck(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Mahjong">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="麻雀" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>麻雀</h2>
      <p>日本の一般的な立直麻雀をベースにしています。</p>
      <p>
        詳しいルールは{" "}
        <a href="https://ja.wikipedia.org/wiki/%E9%BA%BB%E9%9B%80%E3%81%AE%E3%83%AB%E3%83%BC%E3%83%AB" target="_blank" rel="noreferrer">
          麻雀のルール - Wikipedia
        </a>{" "}
        や解説サイトなどをご覧ください。
      </p>
      <div>
        <h4>TAGA 特殊ルール(オプション)</h4>
        <p>
          透明牌
          <br />
          同種の牌4枚のうち指定した枚数が透明な牌となり、他のプレイヤーからも見えるようになります。
        </p>
        <p>
          配牌ドラフト
          <br />
          「13牌の中から1牌を選び、残りを下家に渡す」のを繰り返し、配牌を作ります。
          <br />
          親の14牌目はランダムです。
        </p>
      </div>

      <div>
        <h4>対応ルール</h4>
        半荘戦
        <br />
        食いタン：あり
        <br />
        食い変え：あり
        <br />
        複数ロン：頭ハネ
        <br />
        トビ終了：なし
        <br />
        オーラスのあがり止め：なし
        <br />
        <br />
        <h4>対応ルール(三人打ち)</h4>
        萬子の2～8を使用しない
        <br />
        チー：なし
        <br />
        北：常に役牌
        <br />
        途中流局：なし <br />
        ツモ得点：ツモ損(参考
        <a href="https://ja.wikipedia.org/wiki/%E4%B8%89%E4%BA%BA%E9%BA%BB%E9%9B%80" target="_blank" rel="noreferrer">
          三人麻雀 - Wikipedia
        </a>
        )
        <br />
        <br />
        可能であればプレイヤー同士で取り決めを行ってください。(東風戦で終わる、など)
      </div>

      <div>
        <h4>対応役</h4>
        <p>
          1翻
          <br />
          立直, 一発, 門前清自摸和, 断么九, 平和, 一盃口, 役牌, 自風, 場風, 嶺上開花, 槍槓, 海底摸月, 河底撈魚
        </p>
        <p>
          2翻(*食い下がり)
          <br />
          ダブル立直, 小三元, 三色同順*, 一気通貫*, 混老頭, 混全帯么九*, 七対子, 対々和, 三暗刻, 三色同刻, 三槓子
        </p>
        <p>
          3翻(*食い下がり)
          <br />
          二盃口, 純全帯么九*, 混一色
        </p>
        <p>
          6翻(*食い下がり) <br />
          清一色*
        </p>
        <p>
          役満 <br />
          国士無双, 大三元, 清老頭, 四暗刻, 四槓子, 九蓮宝燈, 字一色, 大四喜, 小四喜, 緑一色, 天和, 地和
        </p>
        <p>ダブル以上の役満には対応していません。</p>
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/mahjong/help1.png"} /> <br />
        鳴無ボタンにチェックを付けると、ポン・カン・チーを自動的にパスするようになります。(ロンはパスしません)
      </div>
    </div>
  );
}

export const Mahjong = {
  name: "麻雀",
  id: "Mahjong",
  people: [3, 4],
  settingDef: settingDef,
  component: CMahjong,
  help: CHelp,
};

const settingDefPa: any[] = [];

function nextGamePa(game: Game, yama: number[]) {
  game.yama = yama.splice(0, 99);
  game.kan = false;
  game.haitei = false;
  game.first = false;

  game.wanpai.dora = 1;
  game.wanpai.hais = yama.splice(0, 1);
  const tehai = yama.splice(0, 14);
  game.player.forEach((_, i) => {
    if (game.state === "Ryukyoku") {
      game.player[i].score += game.tmp[i] ? 1500 : 0;
    } else {
      if (game.tmp[i].kind === "ツモ") {
        game.player[i].score += game.tmp[i].scores[0];
      }
    }
    game.player[i].hand.tehai = [...tehai];
    game.player[i].hand.last = tehai[0];
    game.wait[i] = { command: "Wait" };
  });
  game = updateTenpai(game);
  game.state = "Turn";
  game.ba.num += 1;

  return game;
  // console.log("startGame",game);
}

function startGamePa(game: Game, yama: number[]) {
  game.hais = initDeck(game);
  game.startPlayer = -1;
  game.turnPlayer = -1;
  game.wanpai = { hais: [], dora: 1, doraReserv: 0 };
  game.player = game.setting.seat.map((p, i) => ({
    name: p,
    hand: { tehai: [], naki: [], last: -1 },
    kawa: [],
    huri: [],
    reach: false,
    reachIdx: -1,
    double: false,
    ippatsu: false,
    tenpais: [],
    huriten: { sute: false, reach: false, yama: false },
    score: 0,
    kaze: "東",
  }));
  game.order = [];
  game.yama = yama.splice(0, 99);
  game.kan = false;
  game.haitei = false;
  game.first = false;

  game.wanpai.dora = 1;
  game.wanpai.hais = yama.splice(0, 1);
  const tehai = yama.splice(0, 14);
  game.player.forEach((_, i) => {
    game.player[i].hand.tehai = [...tehai];
    game.player[i].hand.last = tehai[0];
    game.wait[i] = { command: "Wait" };
  });
  game = updateTenpai(game);
  game.state = "Turn";
  return game;
  // console.log("startGame",game);
}

function initGamePa(env: any): Game {
  // console.log("init");
  return {
    env: env,
    setting: { seat: [], aka: false, clear: "0", haipai: "Random", orderRandom: false },
    state: "Init",
    you: -1,
    hais: [],
    ba: { kaze: "東", num: 1, bar100: 0, bar1000: 0 },
    yama: [],
    wanpai: { hais: [], dora: 0, doraReserv: 0 },
    step: 0,
    order: [],
    player: [],
    turnPlayer: 0,
    startPlayer: 0,
    wait: [],
    tmp: null,
    kan: false,
    haitei: false,
    first: true,
    draft: [],
    draftCnt: -1,
    now: -1,
    parallel: true,
  };
}

function CAgariPa() {
  const game = useContext(GameContext);

  return (
    <div className="AgariPa">
      {game.state === "Ryukyoku" && <>流局</>}
      {game.state === "Tsumo" && <>ツモ</>}
    </div>
  );
}

function CYakuPa(props: any) {
  const game = useContext(GameContext);

  if (game.state !== "Tsumo" || game.tmp[props.id].kind !== "ツモ") return <></>;
  return <div className="Yaku">{game.tmp[props.id].yakus.map((y) => y.name).join(", ")}</div>;
}

function CActionPa() {
  const game = useContext(GameContext);

  return (
    <div className="Action">
      {game.state === "Turn" && game.wait[game.you].command === "Wait" && (
        <>
          {canTsumoPa(game) && (
            <div>
              <Button variant="contained" onClick={() => play(game, { command: "Tsumo", player: game.you })}>
                ツモ
              </Button>
            </div>
          )}
        </>
      )}

      {["Tsumo", "Ryukyoku"].includes(game.state) && (
        <>
          {!game.wait[game.you] && (
            <div>
              <Button onClick={() => play(game, { command: "OK", yama: newDeck(game), player: game.you })} variant="contained">
                OK
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function CPlayerStatusPa(props: { id: number }) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  let isWait =
    (game.state === "Turn" && game.wait[props.id].command === "Wait") || (["Tsumo", "Ryukyoku"].includes(game.state) && !game.wait[props.id]);
  let cname = isWait ? "Name Turn" : "Name";
  let score = p.score.toString();
  if (game.state === "Ryukyoku" && game.tmp[props.id]) score += " + 1500";
  if (game.state === "Tsumo" && game.tmp[props.id].kind === "ツモ") score += " + " + game.tmp[props.id].scores[0].toString();
  return (
    <div className="Status">
      <div className="Zikaze">{p.kaze}</div>
      <div>
        <div className={cname}>{p.name}</div>
        <div>{score}</div>
      </div>
    </div>
  );
}

function CHandPa(props: { id: number }) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  let ptehai = [...p.hand.tehai];
  ptehai.sort((ai, bi) => sortMahjong(game.hais[ai], game.hais[bi]));

  function playHai(id: HaiID) {
    play(game, { command: "Dahai", hai: id, player: game.you });
  }

  function canPlay() {
    if (game.you === props.id && game.state === "Turn" && game.wait[game.you].command === "Wait") return true;
    return false;
  }

  function cname(id: HaiID) {
    let ret = "";
    if (canPlay()) ret += " CanPlay";
    if (id === p.hand.last) ret += " Last";
    if (game.you === props.id && game.state === "Turn" && game.wait[game.you].command === "Dahai" && game.wait[game.you].hai === id) ret += " Now";

    return ret;
  }

  function option(id: HaiID) {
    return canPlay()
      ? {
          onClick: () => playHai(id),
        }
      : {};
  }

  return (
    <div className="Hand">
      {ptehai.map((h, i) => (
        <CHai key={h} h={h} side={true} down={false} class={cname(h)} options={option(h)} />
      ))}
    </div>
  );
}

function CPlayerPa(props: any) {
  const game = useContext(GameContext);

  return (
    <div className={"Player " + (props.id === game.you ? "You" : "Other")}>
      <CPlayerStatusPa id={props.id} />
      <CHandPa id={props.id} />
      <div className="Spacer" />
      <CYakuPa id={props.id} />
    </div>
  );
}

function CGamePa() {
  const game = useContext(GameContext);

  return (
    <div className="Game">
      <div className="Field">
        {game.state === "End" ? (
          <CEnd />
        ) : (
          <>
            <div className="Ba">
              <div>
                <div className="Kyoku">{game.ba.kaze + game.ba.num + "局"}</div>
              </div>
            </div>
            <div className="Yama">残{game.yama.length}</div>
            <CWanpai />
            <CAgariPa />
            <div className="Players">
              {game.player.map((p, i) => (
                <CPlayerPa key={i} id={i} />
              ))}
            </div>
          </>
        )}
        <CActionPa />
      </div>
    </div>
  );
}

function getScoresPa(game: Game, player: number, isTsumo: boolean, yakus: Yaku[], hu: number) {
  const han = yakus.reduce((ac, cv) => ac + cv.han, 0);

  let total = "";
  let score = 0;

  if (han === 0) {
    score = 0;
  } else if (han >= 13) {
    total = "役満";
    score = 48000;
  } else if (han >= 11) {
    total = "3倍満";
    score = 36000;
  } else if (han >= 8) {
    total = "倍満";
    score = 24000;
  } else if (han >= 6) {
    total = "ハネ満";
    score = 18000;
  } else if (han >= 5 || (han === 4 && hu >= 40) || (han === 3 && hu >= 70)) {
    total = "満貫";
    score = 12000;
  } else {
    total = han + "飜" + hu + "符";
    switch (han) {
      case 4:
        score = [7800, 9600, 11700][[20, 25, 30].indexOf(hu)];
        break;
      case 3:
        score = [3900, 4800, 6000, 7800, 9600, 11700][[20, 25, 30, 40, 50, 60].indexOf(hu)];
        break;
      case 2:
        score = [2100, 2400, 3000, 3900, 4800, 6000, 6900, 7800, 8700, 9600, 10800][[20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 110].indexOf(hu)];
        break;
      case 1:
        score = [1500, 2100, 2400, 3000, 3600, 3900, 4500, 4800, 5400][[30, 40, 50, 60, 70, 80, 90, 100, 110].indexOf(hu)];
        break;
    }
  }

  return { total: total, scores: [score] };
}

function createAgariPa(game: Game, player: number, isTsumo: boolean, agariHai: HaiID) {
  let ts = game.player[player].tenpais.filter((t) => t.agari.suit === game.hais[agariHai].suit && t.agari.num === game.hais[agariHai].num);
  ts = ts.filter((t) => t.sute === agariHai);
  const as: Agari[] = ts.map((t) => {
    const ys = getYakus(game, player, t, agariHai, isTsumo);
    let hu = 0;
    if (ys.some((y) => y.name === "平和") && isTsumo) hu = 20;
    else if (ys.some((y) => y.name === "七対子")) hu = 25;
    else if (ys.some((y) => y.name === "国士無双")) hu = 0;
    else hu = getHu(game, player, t, agariHai, isTsumo);
    const sc = getScoresPa(game, player, isTsumo, ys, hu);
    return {
      player: player,
      kind: "ツモ",
      hai: agariHai,
      yakus: ys,
      total: sc.total,
      scores: sc.scores,
    };
  });
  return as.reduce((ac, cv) => (ac.scores[0] > cv.scores[0] ? ac : cv));
}

function canTsumoPa(game: Game) {
  const last = game.hais[game.player[game.you].hand.last];
  let tenpai = game.player[game.you].tenpais.filter((t) => t.sute === last.id && t.agari.suit === last.suit && t.agari.num === last.num);
  return tenpai.length > 0;
}

function fixRyukyokuPa(game: Game) {
  game.state = "Ryukyoku";
  game.tmp = game.player.map((p) => p.tenpais.length > 0);
  game.wait = game.player.map((_) => false);
  return game;
}

function fixTsumoPa(game: Game) {
  game.state = "Tsumo";
  game.tmp = game.player.map((_, i) =>
    game.wait[i].command === "Tsumo"
      ? createAgariPa(game, i, true, game.player[i].hand.last)
      : { player: i, kind: "なし", hai: -1, scores: [], total: "", yakus: [] }
  );
  game.wait = game.player.map((_) => false);

  return game;
}

function nextTsumoPa(game: Game) {
  game.player.forEach((_, i) => {
    game.player[i].hand.tehai = game.player[i].hand.tehai.filter((h) => h !== game.wait[i].hai);
  });

  if (game.yama.length === 0) {
    game = fixRyukyokuPa(game);
    return game;
  }

  //通常ツモ
  const thai = game.yama.splice(0, 1)[0];
  game.player.forEach((_, i) => {
    game.player[i].hand.last = thai;
    game.player[i].hand.tehai.push(thai);
    game.wait[i] = { command: "Wait" };
  });
  game = updateTenpai(game);
  if (game.yama.length === 0) game.haitei = true;

  return game;
}

function stepPlayPa(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;
  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;

  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          const setting = JSON.parse(action.setting);
          ng.setting.seat = setting.seat;
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGamePa(ng, action.yama);
          return ng;
      }
      break;

    case "Turn":
      switch (action.command) {
        case "Dahai":
        case "Tsumo":
          ng.wait[action.player] = action;
          if (ng.wait.every((w) => w.command !== "Wait")) {
            if (ng.wait.some((w) => w.command === "Tsumo")) {
              ng = fixTsumoPa(ng);
            } else {
              ng = nextTsumoPa(ng);
            }
          }
          return ng;
      }
      break;

    case "Tsumo":
    case "Ryukyoku":
      switch (action.command) {
        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((n) => n)) {
            ng = nextGamePa(ng, action.yama);
          }
          return ng;
      }
      break;
  }

  console.error("Unknown State or Action");

  return ng;
}

export const CMahjongPa = (props: any) => {
  const [game, setGame] = useState(() => initGamePa(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useEffect(() => {
    console.log("MahjongPa Render", game);
  }, [game]);

  useDB({ env: props.env, setGame: setGame, step: stepPlayPa });

  useEffect(() => {
    UIStateChange(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.state, game.turnPlayer]);

  useEffect(() => {
    soundEffect();
  }, [game.state, game.yama.length]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", yama: newDeck(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="MahjongPa Mahjong">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGamePa />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="並行麻雀" game={game} />
    </div>
  );
};

function CHelpPa(props: any) {
  return (
    <div className="Help">
      <h2>並行麻雀</h2>
      <p>日本の一般的な立直麻雀をアレンジしたゲームです。</p>
      <p>
        一般的な麻雀のルールは{" "}
        <a href="https://ja.wikipedia.org/wiki/%E9%BA%BB%E9%9B%80%E3%81%AE%E3%83%AB%E3%83%BC%E3%83%AB" target="_blank" rel="noreferrer">
          麻雀のルール - Wikipedia
        </a>{" "}
        や解説サイトなどをご覧ください。
      </p>
      <div>
        <h4>並行麻雀ルール</h4>
        <p>
          すべてのプレイヤーは同一の配牌でスタートします。
          <br />
          全員が捨て牌を選択したら、次のツモに進みます。
          <br />
          どの牌を切っても次のツモ牌は同じになります。
          <br />
          誰かがツモ和了した時点でその局が終了し、和了したプレイヤーに点数が加算されます。
          <br />
          和了点は4人麻雀で親がツモ和了した時の点数になります。
          <br />
          100牌で流局となり、テンパイしているプレイヤーに1500点が加算されます。
          <br />
          ゲームは繰り返し続きます。いつ終了するかは参加者で取り決めてください。
          <br />
          副露、立直は出来ません（暗槓を含む）。
          <br />
          自風・場風は東固定です。
          <br />
        </p>
      </div>

      <div>
        <h4>対応役</h4>
        <p>
          1翻
          <br />
          門前清自摸和, 断么九, 平和, 一盃口, 役牌, 自風, 場風, 海底摸月
        </p>
        <p>
          2翻
          <br />
          小三元, 三色同順, 一気通貫, 混老頭, 混全帯么九, 七対子, 三色同刻
        </p>
        <p>
          3翻
          <br />
          二盃口, 純全帯么九, 混一色
        </p>
        <p>
          6翻
          <br />
          清一色
        </p>
        <p>
          役満 <br />
          国士無双, 大三元, 清老頭, 四暗刻, 九蓮宝燈, 字一色, 大四喜, 小四喜, 緑一色
        </p>
        <p>ダブル以上の役満には対応していません。</p>
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/mahjong/help2.png"} /> <br />
      </div>
    </div>
  );
}

export const MahjongPa = {
  name: "並行麻雀",
  id: "MahjongPa",
  people: [1, 99],
  settingDef: settingDefPa,
  component: CMahjongPa,
  help: CHelpPa,
};
