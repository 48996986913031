/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom($id: String!) {
    onUpdateRoom(id: $id) {
      id
      pass
      game
      setting
      seed
      ttl
    }
  }
`;
export const onCreateRoomLog = /* GraphQL */ `
  subscription OnCreateRoomLog {
    onCreateRoomLog {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const onUpdateRoomLog = /* GraphQL */ `
  subscription OnUpdateRoomLog {
    onUpdateRoomLog {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const onDeleteRoomLog = /* GraphQL */ `
  subscription OnDeleteRoomLog {
    onDeleteRoomLog {
      dummy
      createdAt
      env
      updatedAt
    }
  }
`;
export const onCreatePlay = /* GraphQL */ `
  subscription OnCreatePlay($table: String!) {
    onCreatePlay(table: $table) {
      table
      step
      action
      ttl
    }
  }
`;
