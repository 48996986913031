import "./Poker.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, Play, soundEffect } from "../GameCommand";
import { Button, Slider } from "@material-ui/core/";
import { Random, shuffle } from "../lib/xs";

interface Setting {
  seat: string[];
  orderRandom: boolean;
}

const settingDef = [{ kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true }];

interface Player {
  state: string; // Wait, Bet, AllIn, Down, Drop
  name: string;
  score: number;
  bet: number;
  pot: number;
  hand: CardID[];
}

interface Card {
  id: number;
  suit: string;
  power: number; // 0～12 = 2,3,4,,,10,J,Q,K,A
}

type CardID = number;

const suits = ["Spade", "Club", "Heart", "Diamond"];

interface Hand {
  kind: string;
  power: number[];
}

interface Field {
  community: CardID[];
  state: string;
  bet: number;
  minRaise: number;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  player: Player[];
  cards: Card[];
  field: Field;
  playOrder: number[];
  dealer: number;
  sb: number;
  turnPlayer: number;
  gameNo: number;
  wait: any[];
  tmp: any;
}

const GameContext = React.createContext({} as Game);

interface UI {
  raise: number;
}

function initUI() {
  return { raise: 0 };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function UIReducer(state: UI, action: any): UI {
  console.log("Reducer", state, action);
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Init":
      return initUI();

    case "Raise":
      nUI.raise = action.raise;
      break;
  }

  return nUI;
}

function UIStepChange(game: Game, dispatch) {
  dispatch({ type: "Raise", raise: game.field.minRaise });
}

function card2filename(c: Card, side: boolean): string {
  let name = "";
  if (c.suit === "Jocker") name = "jk0";
  else name = ["s", "c", "h", "d"][suits.indexOf(c.suit)] + ("00" + (((c.power + 13 + 1) % 13) + 1)).slice(-2);
  if (!side) name = "bk0";
  return process.env.PUBLIC_URL + "/trump/" + name + ".png";
}

function CCard(props: { c: CardID; side: boolean; options?: any }) {
  const game = useContext(GameContext);
  return (
    <div className="Card">
      <img src={card2filename(game.cards[props.c], props.side)} alt="Card" {...props.options} />
    </div>
  );
}

function CPlayer(props: any) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  const side = props.id === game.you || (game.state === "Show" && ["Bet", "AllIn"].includes(info.state));
  let cname = "Player";
  if (props.id === game.turnPlayer) cname += " Turn";
  if (props.id === game.dealer) cname += " Dealer";
  cname += " " + info.state;

  return (
    <div key={info.name} className={cname}>
      <div>{info.name}</div>
      <div>{info.score}</div>
      <div className="Hand">
        {info.hand.map((h) => (
          <CCard key={h} c={h} side={side} />
        ))}
      </div>
      {game.state === "Game" && info.bet > 0 && <div>{info.bet}</div>}
      {game.state === "Show" && ["Bet", "AllIn"].includes(info.state) && <div>{game.tmp[props.id].kind}</div>}
      {["NoCon", "Show"].includes(game.state) && info.pot > 0 && (
        <div>
          <b>+{info.pot}</b>
        </div>
      )}
    </div>
  );
}

function CField() {
  const game = useContext(GameContext);
  let showC = [0, 3, 4, 5][["PreFlop", "Flop", "Turn", "River"].indexOf(game.field.state)];
  if (game.state === "Show") showC = 5;
  return (
    <div className="Field">
      <div className="Pot">{"Pot:" + game.player.reduce((pv, cv) => pv + cv.pot, 0)}</div>
      <div className="GameNo">{"Game:" + (game.gameNo + 1)}</div>
      <div className="Community">
        {game.field.community.slice(0, showC).map((c, i) => (
          <CCard key={i} c={c} side={true} />
        ))}
      </div>
    </div>
  );
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const p = game.player[game.you];

  function slider(min: number, max: number) {
    return (
      <Slider
        defaultValue={game.field.minRaise}
        step={1}
        min={min}
        max={max}
        marks={[
          { value: min, label: min.toString() },
          { value: max, label: max.toString() },
        ]}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        onChangeCommitted={(evt, value) => uiDispatch({ type: "Raise", raise: value })}
      />
    );
  }

  return (
    <div className="Action">
      {game.state === "Game" && game.turnPlayer === game.you && (
        <>
          <div>
            <Button onClick={() => play(game, { command: "Down", player: game.you })} variant="contained">
              フォールド
            </Button>
          </div>
          {game.field.bet > 0 && (
            <>
              {p.bet + p.score >= game.field.bet && (
                <div>
                  <Button onClick={() => play(game, { command: "Bet", bet: game.field.bet, player: game.you })} variant="contained">
                    {game.field.bet > p.bet ? "コール" + game.field.bet : "チェック"}
                  </Button>
                </div>
              )}
              {p.bet + p.score >= game.field.minRaise && (
                <div>
                  <Button onClick={() => play(game, { command: "Bet", bet: ui.raise, player: game.you })} variant="contained">
                    レイズ {ui.raise}
                  </Button>
                  {slider(game.field.minRaise, p.score + p.bet)}
                </div>
              )}
            </>
          )}
          {game.field.bet === 0 && (
            <>
              <div>
                <Button onClick={() => play(game, { command: "Bet", bet: 0, player: game.you })} variant="contained">
                  チェック
                </Button>
              </div>
              <div>
                <Button onClick={() => play(game, { command: "Bet", bet: ui.raise, player: game.you })} variant="contained">
                  ベット {ui.raise}
                </Button>
                {slider(game.field.minRaise, p.score + p.bet)}
              </div>
            </>
          )}
          <div>
            <Button onClick={() => play(game, { command: "Bet", bet: p.score + p.bet, player: game.you })} variant="contained">
              オールイン
            </Button>
          </div>
        </>
      )}

      {["NoCon", "Show"].includes(game.state) && !game.wait[game.you] && (
        <div>
          <Button onClick={() => play(game, { command: "OK", deck: newDeck(game), player: game.you })} variant="contained">
            OK
          </Button>
        </div>
      )}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  let others = [] as JSX.Element[];
  let otherId = nextPlayer(game, game.you);
  while (otherId !== game.you) {
    others.push(<CPlayer key={otherId} id={otherId} />);
    otherId = nextPlayer(game, otherId);
  }

  return (
    <div className="Game">
      <div className="Others">{others}</div>
      <CField />
      <CPlayer id={game.you} />
      <CAction />
    </div>
  );
}

function newOrder(game: Game) {
  let order = game.setting.seat.map((_, i) => i);
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(order, r);
  } else {
    return order;
  }
}

function nextPlayer(game: Game, base: number, diff: number = 1): number {
  const basei = game.playOrder.indexOf(base);
  return game.playOrder[(basei + diff) % game.setting.seat.length];
}

function nextBetPlayer(game: Game, base: number) {
  const basei = game.playOrder.indexOf(base);
  let i = basei;

  do {
    i = (i + 1) % game.setting.seat.length;
  } while (!(["Bet", "Wait"].includes(game.player[game.playOrder[i]].state) || game.playOrder[i] === base));
  return game.playOrder[i];
}

function nextDealer(game: Game, base: number) {
  const basei = game.playOrder.indexOf(base);
  let i = basei;

  do {
    i = (i + 1) % game.setting.seat.length;
  } while (!(game.player[game.playOrder[i]].score > 0 || game.playOrder[i] === base));
  return game.playOrder[i];
}

function initDeck() {
  let id = 0;
  let deck = suits.map((s) => [...Array(13)].map((_, i) => ({ id: id++, suit: s, power: i }))).flat();
  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const deck = shuffle(
    [...Array(initDeck().length)].map((_, i) => i),
    r
  );
  return deck;
}

function bet(game: Game, player: number, bet: number) {
  if (game.player[player].score + game.player[player].bet > bet) {
    game.player[player].score -= bet - game.player[player].bet;
    game.player[player].bet = bet;
    game.player[player].state = "Bet";
  } else {
    game.player[player].bet += game.player[player].score;
    game.player[player].score = 0;
    game.player[player].state = "AllIn";
  }

  if (game.field.minRaise <= bet) {
    game.field.minRaise = bet + (bet - game.field.bet);
  }
  if (game.field.bet < bet) {
    game.field.bet = bet;
  }
  return game;
}

// aの方が強いと負, bの方が強いと正
function compHand(a: Hand, b: Hand) {
  for (let i = 0; i < a.power.length; i++) {
    if (a.power[i] !== b.power[i]) return b.power[i] - a.power[i];
  }
  return 0;
}

function getHand(game: Game, cards: CardID[]) {
  const cs = cards.map((c) => game.cards[c]).sort((a, b) => b.power - a.power);

  function comb(arr: Card[], fix: Card[]) {
    let ret: Card[][] = [];
    if (fix.length === 5) {
      return [[...fix]];
    }
    if (arr.length + fix.length < 5) {
      return [];
    }
    ret = ret.concat(comb(arr.slice(1), [...fix, arr[0]]));
    ret = ret.concat(comb(arr.slice(1), [...fix]));
    return ret;
  }
  const css = comb(cs, []);

  const hands: Hand[] = css.map((hand) => {
    const flush = hand.every((h) => h.suit === hand[0].suit);
    const straight =
      hand[1].power === hand[0].power - 1 &&
      hand[2].power === hand[0].power - 2 &&
      hand[3].power === hand[0].power - 3 &&
      hand[4].power === hand[0].power - 4;
    const straight5 = hand[0].power === 12 && hand[1].power === 3 && hand[2].power === 2 && hand[3].power === 1 && hand[4].power === 0; // A,5,4,3,2

    if (flush && straight) {
      return { kind: "ストレートフラッシュ", power: [8, hand[0].power] };
    }

    if (flush && straight5) {
      return { kind: "ストレートフラッシュ", power: [8, hand[1].power] };
    }

    const ns = [...Array(13)].map((_, i) => hand.filter((h) => h.power === i).length);
    if (ns.some((n) => n === 4)) {
      const p = ns.indexOf(4);
      const kicker = hand.find((h) => h.power !== p)?.power ?? 0;
      return { kind: "フォーカード", power: [7, p, kicker] };
    }
    if (ns.some((n) => n === 3) && ns.some((n) => n === 2)) {
      const p3 = ns.indexOf(3);
      const p2 = ns.indexOf(2);
      return { kind: "フルハウス", power: [6, p3, p2] };
    }

    if (flush) {
      return { kind: "フラッシュ", power: [5, hand[0].power, hand[1].power, hand[2].power, hand[3].power, hand[4].power] };
    }

    if (straight) {
      return { kind: "ストレート", power: [4, hand[0].power] };
    }

    if (straight5) {
      return { kind: "ストレート", power: [4, hand[1].power] };
    }

    if (ns.some((n) => n === 3)) {
      const p = ns.indexOf(3);
      const kicker = hand.filter((h) => h.power !== p);
      return { kind: "スリーカード", power: [3, p, kicker[0].power, kicker[1].power] };
    }

    if (ns.filter((n) => n === 2).length === 2) {
      const p2 = ns.indexOf(2);
      const p1 = ns.findIndex((n, i) => n === 2 && i !== p2);
      const kicker = hand.find((h) => ![p1, p2].includes(h.power))?.power ?? 0;
      return { kind: "ツーペア", power: [2, p1, p2, kicker] };
    }

    if (ns.filter((n) => n === 2).length === 1) {
      const p = 12 - [...ns].reverse().indexOf(2);
      const kicker = hand.filter((h) => h.power !== p);
      return { kind: "ワンペア", power: [1, p, kicker[0].power, kicker[1].power, kicker[2].power] };
    }

    return { kind: "ハイカード", power: [0, hand[0].power, hand[1].power, hand[2].power, hand[3].power, hand[4].power] };
  });

  hands.sort(compHand);
  return hands[0];
}

function fixShow(game: Game) {
  const hands = game.player.map((p) =>
    ["Bet", "AllIn"].includes(p.state) ? getHand(game, [...game.field.community, ...p.hand]) : { kind: "", power: [-1] }
  );
  game.tmp = hands;
  let wins = hands
    .map((h, i) => ({ hand: h, player: i }))
    .sort((a, b) => {
      const c = compHand(a.hand, b.hand);
      return c === 0 ? game.player[a.player].bet - game.player[b.player].bet : c;
    }); //役の高い順、ベットの少ない順

  game.player.forEach((_, i) => {
    game.player[i].bet = game.player[i].pot;
    game.player[i].pot = 0;
  });

  do {
    const evens = wins.filter((w) => compHand(wins[0].hand, w.hand) === 0).length;
    let pot = 0;
    let bet = game.player[wins[0].player].bet;
    //ポット作成
    game.player.forEach((_, i) => {
      if (game.player[i].bet < bet) {
        pot += game.player[i].bet;
        game.player[i].bet = 0;
      } else {
        pot += bet;
        game.player[i].bet -= bet;
      }
    });
    //配布
    let i = game.dealer;
    let n = 0;
    do {
      if (
        wins
          .slice(0, evens)
          .map((w) => w.player)
          .includes(i)
      ) {
        game.player[i].pot += Math.ceil(pot / evens);
        n++;
        if (n <= pot % evens) game.player[i].pot++;
      }
      i = nextPlayer(game, i);
    } while (i !== game.dealer);
    const finish = wins.findIndex((p) => game.player[p.player].bet > 0);
    if (finish !== -1) wins.splice(0, finish);
  } while (game.player.some((p) => p.bet > 0));

  game.wait = game.player.map((p) => !["Bet", "AllIn"].includes(p.state));

  return game;
}

function nextStage(game: Game) {
  if (game.player.filter((p) => p.state === "Bet").length <= 1) {
    game.state = "Show";
  }

  switch (game.field.state) {
    case "PreFlop":
      game.field.state = "Flop";
      break;
    case "Flop":
      game.field.state = "Turn";
      break;
    case "Turn":
      game.field.state = "River";
      break;
    case "River":
      game.state = "Show";
      break;
  }
  game.player.forEach((_, i) => {
    game.player[i].pot += game.player[i].bet;
    game.player[i].bet = 0;
  });

  if (game.state === "Show") {
    game = fixShow(game);
  } else {
    game.player.forEach((_, i) => {
      if (game.player[i].state === "Bet") game.player[i].state = "Wait";
    });
    game.turnPlayer = nextBetPlayer(game, game.dealer);
    game.field.bet = 0;
    game.field.minRaise = game.sb * 2;
  }

  return game;
}

function checkShow(game: Game) {
  const gameP = game.player.filter((p) => ["Bet", "AllIn", "Wait"].includes(p.state));
  if (gameP.length === 1) {
    game.state = "NoCon";
    let pot = 0;
    game.player.forEach((_, i) => {
      pot += game.player[i].pot + game.player[i].bet;
      game.player[i].pot = 0;
      game.player[i].bet = 0;
    });
    gameP[0].pot = pot;
    game.wait = game.player.map((p) => !["Bet", "AllIn", "Wait"].includes(p.state));
  } else if (gameP.every((p) => p.state === "AllIn" || (p.state === "Bet" && p.bet === game.field.bet))) {
    game = nextStage(game);
  }

  return game;
}

function newGame(game: Game, deck: number[]) {
  game.field.community = deck.splice(0, 5);
  game.field.state = "PreFlop";

  game.player.forEach((_, i) => {
    game.player[i].bet = 0;
    game.player[i].pot = 0;
    if (game.player[i].score === 0) {
      game.player[i].hand = [];
      game.player[i].state = "Drop";
    } else {
      game.player[i].hand = deck.splice(0, 2);
      game.player[i].state = "Wait";
    }
  });

  if (game.player.filter((p) => p.score > 0).length === 1) {
    game.state = "End";
    return game;
  }

  game.field.bet = game.sb * 2;
  game.field.minRaise = game.sb * 2 * 2;
  let sbp: number, bbp: number;
  if (game.player.filter((p) => p.state === "Wait").length === 2) {
    sbp = game.dealer;
    bbp = nextBetPlayer(game, game.dealer);
  } else {
    sbp = nextBetPlayer(game, game.dealer);
    bbp = nextBetPlayer(game, sbp);
  }
  game = bet(game, sbp, game.sb);
  game = bet(game, bbp, game.sb * 2);
  if (game.player[bbp].state === "Bet") game.player[bbp].state = "Wait";
  game.turnPlayer = nextBetPlayer(game, bbp);

  game.state = "Game";

  game = checkShow(game);

  return game;
}

function nextGame(game: Game, deck: number[]) {
  game.player.forEach((_, i) => {
    game.player[i].score += game.player[i].pot;
  });
  game.gameNo++;
  if (game.gameNo % 10 === 0) game.sb = Math.ceil(game.sb * 1.5);
  game.dealer = nextDealer(game, game.dealer);

  game = newGame(game, deck);
  return game;
}

function startGame(game: Game, deck: number[], order: number[]) {
  game.playOrder = order;
  game.cards = initDeck();
  game.dealer = 0;
  game.player = game.setting.seat.map((name) => ({ name: name, score: 100, bet: 0, pot: 0, hand: [], state: "Init" }));
  game.sb = 1;
  game.gameNo = 0;

  game = newGame(game, deck);
  return game;
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [], orderRandom: false },
    state: "Init",
    you: -1,
    step: 0,
    player: [],
    cards: [],
    field: { community: [], state: "Init", bet: 0, minRaise: 0 },
    playOrder: [],
    dealer: 0,
    sb: 0,
    turnPlayer: 0,
    gameNo: 0,
    wait: [],
    tmp: undefined,
  };
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;

  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGame(ng, action.deck, action.order);
          return ng;
      }
      break;

    case "Game":
      switch (action.command) {
        case "Bet":
          ng = bet(ng, action.player, action.bet);
          ng.turnPlayer = nextBetPlayer(ng, action.player);
          ng = checkShow(ng);
          return ng;

        case "Down":
          ng.player[action.player].state = "Down";
          ng.player[action.player].hand = [];
          ng.turnPlayer = nextBetPlayer(ng, action.player);
          ng = checkShow(ng);
          return ng;
      }
      break;

    case "NoCon":
    case "Show":
      switch (action.command) {
        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            ng = nextGame(ng, action.deck);
          }
          return ng;
      }
  }
  console.error("Unknown State");

  return ng;
}

const CPoker = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    console.log("Render", game);
  }, [game]);

  useEffect(() => {
    UIStepChange(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.step]);

  useEffect(() => {
    soundEffect();
  }, [game.turnPlayer, game.state, game.field.state]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", deck: newDeck(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Poker">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="ポーカー" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>ポーカー</h2>
      <p>ノーリミットのテキサスホールデムポーカーです。</p>
      <p>
        基本的なルールは{" "}
        <a
          href="https://ja.wikipedia.org/wiki/%E3%83%86%E3%82%AD%E3%82%B5%E3%82%B9%E3%83%BB%E3%83%9B%E3%83%BC%E3%83%AB%E3%83%87%E3%83%A0"
          target="_blank"
          rel="noreferrer"
        >
          テキサス・ホールデム - Wikipedia
        </a>{" "}
        をご覧ください。
      </p>
      <div>
        <h4>ストラクチャー</h4>
        <p>
          100点スタート <br />
          ブラインドは 1点-2点 <br />
          10ゲーム毎にスモールブラインドが1.5倍(切り捨て)に上昇します。
        </p>
        <h4>制限</h4>
        <p>ショーダウン時のマックはできません。</p>
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/poker/help1.png"} /> <br />
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/poker/help2.png"} /> <br />
        レイズ額はレイズボタンの下のバーで変更できます。 <br />
        PCであればバーをクリックした後に左右キーで細かい調整ができます。 <br />
      </div>
    </div>
  );
}

export const Poker = {
  name: "ポーカー",
  id: "Poker",
  people: [2, 12],
  settingDef: settingDef,
  component: CPoker,
  help: CHelp,
};
