function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export class Random {
  x: number;
  y: number;
  z: number;
  w: number;

  constructor() {
    const seed = getRandomInt(1, Number.MAX_SAFE_INTEGER - 1);
    this.x = 123456789;
    this.y = 362436069;
    this.z = 521288629;
    this.w = seed;
    for (let i = 0; i < 100; i++) this.next();
  }

  // XorShift
  next() {
    let t;

    t = this.x ^ (this.x << 11);
    this.x = this.y;
    this.y = this.z;
    this.z = this.w;
    return (this.w = this.w ^ (this.w >>> 19) ^ (t ^ (t >>> 8)));
  }

  // min以上max以下の乱数を生成する
  nextInt(min: number, max: number) {
    const r = Math.abs(this.next());
    return min + (r % (max + 1 - min));
  }
}

const limit = (1n << 128n) - 1n;

export class RandomBig {
  x: bigint;
  y: bigint;
  z: bigint;
  w: bigint;

  constructor() {
    const seed = BigInt(getRandomInt(1, Number.MAX_SAFE_INTEGER - 1));
    this.x = 12345678901234567890n;
    this.y = 36243606935644327773n;
    this.z = 5212886290917591573n;
    this.w = seed;

    for (let i = 0; i < 100; i++) this.next();
  }

  // XorShift
  next() {
    let t;

    t = (this.x ^ (this.x << 11n)) & limit;
    this.x = this.y;
    this.y = this.z;
    this.z = this.w;
    return (this.w = this.w ^ (this.w >> 19n) ^ (t ^ (t >> 8n)));
  }

  // min以上max以下の乱数を生成する
  nextBig(min: bigint, max: bigint) {
    const r = this.next();
    return min + (r % (max + 1n - min));
  }
}

export function shuffle([...array], r: Random) {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = r.nextInt(0, i);
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
