import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect, Switch, useLocation } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";

import App from "./App";

const theme = unstable_createMuiStrictModeTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      }
    }
  }
});

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/invite/" component={CInvite} />
          {process.env.NODE_ENV === "development" && <Route exact path="/debug/:name/:room" component={CDebug} />}
          {process.env.NODE_ENV === "development" && <Route exact path="/dgame/:game/:seed/:name" component={CDGame} />}
          <Route component={CNotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

function CDebug(props: any) {
  console.log(props);
  return <App name={props.match.params.name} room={props.match.params.room} />;
}

function CDGame(props: any) {
  console.log(props);
  const query = useLocation().search;
  const searchParams = new URLSearchParams(query);
  if(searchParams.has("step")) window["step"] = Number(searchParams.get("step"));
  
  return <App name={props.match.params.name} game={props.match.params.game} seed={props.match.params.seed} />;
}

function CInvite(props: any) {
  console.log(props);
  const query = useLocation().search;
  const searchParams = new URLSearchParams(query);
  const room = searchParams.get("room") ?? "";
  const pass = searchParams.get("pass") ?? "";
  return <App room={room} password={pass} />;
}

function CNotFound() {
  return <Redirect to="/" />;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
