import React, { useEffect } from "react";

export function ADBanner(props: { id: string }) {
  useEffect(() => {
    // PCの場合の処理を記述
    const div = document.getElementById(props.id) as HTMLElement;
    const iframe = document.createElement("iframe");
    div.appendChild(iframe);

    let html = `<div id="im-67a2a809bb7d469db4d37092bd5e4790">
  <script async src="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js"></script>
  <script>(window.adsbyimobile=window.adsbyimobile||[]).push({pid:75857,mid:536263,asid:1752250,type:"banner",display:"inline",elementid:"im-67a2a809bb7d469db4d37092bd5e4790"})</script>
</div>`;

    const iframeDoc = (iframe.contentWindow as Window).document;
    iframeDoc.open();
    iframe.width = "100%";
    iframe.height = "100%";
    iframeDoc.write(html);
    iframeDoc.close();
  },[props.id]);
  return <div id={props.id} className="ADBanner"></div>;
}

export function ADs(props: { id: string }) {
  return (
    <div className="ADs" id={props.id}>
      PR
      <ADBanner id={props.id + "1"} />
      <ADBanner id={props.id + "2"} />
    </div>
  );
}
