import { useEffect, useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { listPlays } from "./graphql/queries";
import { createPlay, updateRoom, createRoomLog } from "./graphql/mutations";
import { onCreatePlay } from "./graphql/subscriptions";
import { Typography, Menu, MenuItem, Button } from "@material-ui/core/";
import { Settings } from "@material-ui/icons/";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";

export interface Env {
  name: string;
  room: string;
  pass: string;
  game: string;
  setting: string;
  seed: number;
}

export interface Play {
  table: string;
  step: number;
  action: string;
  ttl: number;
}

export function useDB(props: any) {
  useEffect(() => {
    console.log("useDB");
    //既に登録されているステップを処理
    (API.graphql(graphqlOperation(listPlays, { table: props.env.seed, limit: 10000, sortDirection: "ASC" })) as any)
      .then((res: any) => {
        console.log("listPlays", JSON.stringify(res));
        if (res.data.listPlays.items.length !== 0) {
          let plays: Play[] = res.data.listPlays.items;
          console.log(typeof window["step"], window["step"]);
          if (process.env.NODE_ENV === "development" && typeof window["step"] !== "undefined") plays = plays.filter((p) => p.step <= window["step"]);
          props.setGame((g) => plays.reduce((gi, p) => props.step(gi, p), g));
        }
      })
      .catch((e: any) => {
        console.log(e);
        window.alert("通信エラー1");
      });

    //DB購読
    const subscription = (API.graphql(graphqlOperation(onCreatePlay, { table: props.env.seed })) as any).subscribe({
      next: (msg: any) => {
        console.log("subscription fired", msg);
        const play: Play = msg.value.data.onCreatePlay;
        props.setGame((g) => props.step(g, play));
      },
      error: (msg: any) => {
        console.log("subscribe error", msg);
        window.alert("通信エラー2");
      },
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.env.seed]);
}

function pushAction(play: Play, callback = () => {}) {
  console.log("pushAction", play);
  (API.graphql(graphqlOperation(createPlay, { input: play, condition: { action: { attributeExists: false } } })) as any)
    .then((res: any) => {
      console.log(res);
      callback();
    })
    .catch((e: any) => {
      console.log(e);
      // window.alert("通信エラー3");
    });
}

export function play(game: any, action: any, callback = () => {}) {
  pushAction(
    {
      table: game.env.seed.toString(),
      step: game.step + 1,
      action: JSON.stringify(action),
      ttl: getTTL(),
    },
    callback
  );
}

export function closeGame(game: any) {
  (API.graphql(graphqlOperation(updateRoom, { input: { id: game.env.room, game: "", seed: -1, ttl: getTTL() } })) as any)
    .then((res: any) => {
      console.log(res);
      pushAction({ table: game.env.seed.toString(), action: JSON.stringify({ command: "CloseGame" }), step: game.step + 1, ttl: getTTL() });
    })
    .catch((e: any) => {
      console.log(e);
      window.alert("通信エラー4");
    });
}

export function resetGame(game: any) {
  // 部屋情報, 今のテーブルの順に更新
  (API.graphql(graphqlOperation(updateRoom, { input: { id: game.env.room, seed: -1, ttl: getTTL() } })) as any)
    .then((res: any) => {
      console.log(res);
      pushAction({ table: game.env.seed.toString(), action: JSON.stringify({ command: "CloseGame" }), step: game.step + 1, ttl: getTTL() });
    })
    .catch((e: any) => {
      console.log(e);
      window.alert("通信エラー5");
    });
}

export function createGame(env: Env) {
  const callback = () => {
    (API.graphql(graphqlOperation(updateRoom, { input: { id: env.room, seed: env.seed, ttl: getTTL() } })) as any)
      .then((res: any) => {
        console.log(res);
      })
      .catch((e: any) => {
        console.log(e);
        alert("error");
      });
  };
  pushAction({ table: env.seed.toString(), action: JSON.stringify({ command: "Setting", setting: env.setting }), step: 1, ttl: getTTL() }, callback);

  //ログ
  (API.graphql(graphqlOperation(createRoomLog, { input: { dummy: 0, env: JSON.stringify(env) } })) as any)
    .then((res: any) => {
      console.log(res);
    })
    .catch((e: any) => {
      console.log(e);
    });
}

export function bugReport(content: any) {
  const url = "https://discord.com/api/webhooks/831736986699169812/rM-Kj-3Yq-aLMarjizuvo96GEV5sjh1iOHXna74IK5BL1UgawpjkdrBRv8b3IKJ_aOz-";
  const data = {
    content: JSON.stringify(content),
  };
  const req = new XMLHttpRequest();
  req.onreadystatechange = () => {
    if (req.readyState === req.DONE) {
      console.log(req.response, req);
    }
  };
  req.open("POST", url);
  req.setRequestHeader("Content-Type", "application/json");
  req.send(JSON.stringify(data));
}

export function BugReportButton(props: any) {
  function report() {
    (API.graphql(graphqlOperation(updateRoom, { input: { id: props.env.room, seed: -1, ttl: getTTL() } })) as any)
      .then((res: any) => {
        console.log(res);
        props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
      })
      .catch((e: any) => {
        console.log(e);
        window.alert("通信エラー6");
      });

    bugReport({
      env: props.env,
      ua: window.navigator.userAgent,
      error: props.error.name + props.error.message,
    });
  }
  return (
    <Button variant="contained" onClick={report}>
      リセット
    </Button>
  );
}

export function GameSettingButton(props: any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeGameClick = () => {
    var res = window.confirm("ゲームを終了しますか?");
    if (res) closeGame(props.game);
    handleClose();
  };

  const newGameClick = () => {
    console.log("ニューゲーム");
    var res = window.confirm("今のゲームを終了し、同じ設定でテーブルを作成しますか?");
    if (res) resetGame(props.game);
    handleClose();
  };

  const bugReportClick = () => {
    const msg = prompt("内容を入力してください(ゲーム状況は自動で送信されます)");
    if (msg !== null) {
      bugReport({
        env: props.game.env,
        msg: msg,
        ua: window.navigator.userAgent,
      });
    }
    handleClose();
  };

  return (
    <div className="GameButtons">
      <div className="GameSetting">
        <Button aria-label="menu" aria-controls="gameMenu" aria-haspopup="true" variant="contained" onClick={handleClick}>
          <Typography variant="h6">{props.name}</Typography>
          <Settings />
        </Button>
        <Menu
          id="gameMenu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "left", vertical: -80 }}
        >
          <MenuItem onClick={newGameClick}>ニューゲーム</MenuItem>
          <MenuItem onClick={closeGameClick}>ゲーム終了</MenuItem>
          <MenuItem onClick={bugReportClick}>バグ報告</MenuItem>
        </Menu>
      </div>
      <TwitterShareButton url={"https://tagataga.com/"} title={"TAGAで" + props.name + "をプレイしました"}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <FacebookShareButton url={"https://tagataga.com/"} quote={"TAGAで" + props.name + "をプレイしました"}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
    </div>
  );
}

export function soundEffect() {
  const audio = new Audio(process.env.PUBLIC_URL + "/effect.mp3");
  audio.play();
}

export function getTTL() {
  return Math.floor(Date.now() / 1000) + 2592000; //30日後に削除
}
