import "./Goita.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, soundEffect } from "../GameCommand";

import { Button } from "@material-ui/core/";

import { Random, shuffle } from "../lib/xs";
import { divide } from "../lib/deck";

interface Setting {
  seat: string[];
  orderRandom: boolean;
}

const settingDef = [{ kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true }];

interface GCard {
  id: number;
  suit: string;
  name: string;
  point: number;
}

type CardID = number;

interface Field {
  card: CardID;
  side: boolean;
}

interface Player {
  name: string;
  hand: CardID[];
  field: Field[];
  dama: boolean;
  score: number;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  cards: GCard[];
  player: Player[];
  turnPlayer: number;
  trickPlayer: number;
  trickCard: CardID;
  wait: any[];
}

const GameContext = React.createContext({} as Game);

interface Play {
  table: string;
  step: number;
  action: string;
}

interface UI {
  select: CardID;
}

function initUI() {
  return { select: -1 };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function sortGoita(a: GCard, b: GCard): number {
  return b.id - a.id;
}

function nextPlayer(game: Game, base: number): number {
  return (base + 1) % 4;
}

function team1name(game: Game) {
  return game.player[0].name + " & " + game.player[2].name;
}

function team2name(game: Game) {
  return game.player[1].name + " & " + game.player[3].name;
}

function getScore(game: Game, field: Field[]) {
  const f = field.slice(-1)[0]; //末尾
  const s = field.slice(-2)[0]; //2番目
  const p = game.cards[f.card].point;
  if (!s.side && game.cards[s.card].suit === game.cards[f.card].suit) {
    return p * 2;
  }
  return p;
}

function getTeyaku(game: Game) {
  const si = game.player.map((p) => p.hand.filter((c) => game.cards[c].suit === "し").length);
  const m = Math.max(...si);

  if (m === 8) {
    const p = si.indexOf(8);
    return {
      score: 100,
      player: p,
      msg: game.player[p].name + "の 8し",
    };
  }

  if (m === 7) {
    const p = si.indexOf(7);
    const s = game.player[p].hand.filter((c) => game.cards[c].suit !== "し").map((c) => game.cards[c].point * 2)[0];
    return {
      score: s,
      player: p,
      msg: game.player[p].name + "の 7し",
    };
  }

  if (m === 6) {
    const p = si.indexOf(6);
    const ns = game.player[p].hand.filter((c) => game.cards[c].suit !== "し");
    let s = 0;
    if (game.cards[ns[0]].suit === game.cards[ns[1]].suit) {
      s = game.cards[ns[0]].point * 2;
    } else {
      s = Math.max(game.cards[ns[0]].point, game.cards[ns[1]].point);
    }

    return {
      score: s,
      player: p,
      msg: game.player[p].name + "の 6し",
    };
  }

  if (si[0] === 5 && si[2] === 5) {
    return {
      score: 150,
      player: 0,
      msg: team1name(game) + "の 5し",
    };
  }

  if (si[1] === 5 && si[3] === 5) {
    return {
      score: 150,
      player: 1,
      msg: team2name(game) + "の 5し",
    };
  }

  if (m === 5) {
    const si5 = si.map((s) => s === 5);
    return {
      score: -1,
      player: 0,
      msg: si5.map((b, i) => (b ? game.player[i].name + " " : "")).join("") + "の 5し",
      si5: si5,
    };
  }

  return {
    score: 0,
    player: 0,
    msg: "なし",
  };
}

function CCard(props: { c: CardID; side: boolean; class?: string; options?: any }) {
  const game = useContext(GameContext);
  let cname = (props?.class ?? "") + " Card";
  if (game.trickCard === props.c) cname += " Trick";
  return (
    <div className={cname} {...props.options}>
      {props.side && game.cards[props.c].name}
    </div>
  );
}

// prettier-ignore
function CSummary() {
  return (
    <div className="Summary">
      <table>
        <tr><td>王</td><td>2枚</td><td>50点</td></tr>
        <tr><td>飛</td><td>2枚</td><td>40点</td></tr>
        <tr><td>角</td><td>2枚</td><td>40点</td></tr>
        <tr><td>金</td><td>4枚</td><td>30点</td></tr>
        <tr><td>銀</td><td>4枚</td><td>30点</td></tr>
        <tr><td>馬</td><td>4枚</td><td>20点</td></tr>
        <tr><td>香</td><td>4枚</td><td>20点</td></tr>
        <tr><td>し</td><td>10枚</td><td>10点</td></tr>
      </table>
    </div>
  );
}

function CEnd() {
  const game = useContext(GameContext);

  return (
    <div className="Message">
      {team1name(game) + " : " + game.player[0].score + "点"} <br />
      {team2name(game) + " : " + game.player[1].score + "点"}
      <br />
      <br />
      {(game.player[0].score > game.player[1].score ? team1name(game) : team2name(game)) + " の勝ち"}
    </div>
  );
}

function CAgari() {
  const game = useContext(GameContext);

  function checkField() {
    play(game, { command: "Check", player: game.you, sdeck: newDeck(game) });
  }

  return (
    <div className="Message">
      {game.player[game.turnPlayer].name + "のあがり"}
      <br />
      {getScore(game, game.player[game.turnPlayer].field) + "点"}
      <br />
      {!game.wait[game.you] && (
        <Button onClick={checkField} variant="contained">
          OK
        </Button>
      )}
    </div>
  );
}

function C5SiReset() {
  const game = useContext(GameContext);

  function checkField() {
    play(game, { command: "Check", player: game.you, sdeck: newDeck(game) });
  }

  return (
    <div className="Message">
      {"配り直します"}
      <br />
      {!game.wait[game.you] && (
        <Button onClick={checkField} variant="contained">
          OK
        </Button>
      )}
    </div>
  );
}

function C5SiContinue() {
  const game = useContext(GameContext);

  function checkField() {
    play(game, { command: "Check", player: game.you });
  }

  return (
    <div className="Message">
      {"続行します"}
      <br />
      {!game.wait[game.you] && (
        <Button onClick={checkField} variant="contained">
          OK
        </Button>
      )}
    </div>
  );
}

function C5Si() {
  const game = useContext(GameContext);
  const teyaku = getTeyaku(game);

  function checkField(check: string) {
    play(game, { command: "Check", check: check, player: game.you });
  }

  return (
    <div className="Message">
      {teyaku.msg}
      <br />
      {game.wait[game.you] === "Select" && (
        <>
          <Button onClick={() => checkField("Go")} variant="contained">
            続行
          </Button>
          <br />
          <br />
          <Button onClick={() => checkField("Stop")} variant="contained">
            配り直し
          </Button>
        </>
      )}
    </div>
  );
}

function CTeyaku() {
  const game = useContext(GameContext);
  const teyaku = getTeyaku(game);

  function checkField() {
    play(game, { command: "Check", player: game.you, sdeck: newDeck(game) });
  }

  return (
    <div className="Message">
      {teyaku.msg}
      <br />
      {teyaku.score + "点"}
      <br />
      {!game.wait[game.you] && (
        <Button onClick={checkField} variant="contained">
          OK
        </Button>
      )}
    </div>
  );
}

function CPlayerField(props: { id: number }) {
  const game = useContext(GameContext);
  return (
    <div className="PlayerField">
      <div className="Uke">
        {game.player[props.id].field
          .filter((_, i) => i % 2 === 0)
          .map((f) => (
            <CCard key={f.card} c={f.card} side={f.side} />
          ))}
      </div>
      <div className="Seme">
        {game.player[props.id].field
          .filter((_, i) => i % 2 === 1)
          .map((f) => (
            <CCard key={f.card} c={f.card} side={f.side} />
          ))}
      </div>
    </div>
  );
}

function CPlayerStatus(props: { id: number }) {
  const game = useContext(GameContext);
  const p = game.player[props.id];
  let cname = "Status";
  if (["Uke", "Seme", "Huse"].includes(game.state) && game.turnPlayer === props.id) cname += " Wait";
  if (game.state === "Agari" && !game.wait[props.id]) cname += " Wait";
  return (
    <div className={cname}>
      {p.name}
      <br />
      {p.score + "点"}
    </div>
  );
}

function CPlayer() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  console.log("selects", ui.select);
  const p = game.player[game.you];
  return (
    <div className="Player">
      <CPlayerStatus id={game.you} />
      <CPlayerField id={game.you} />
      <div className="Hand">
        {p.hand.map((c, i) =>
          game.turnPlayer === game.you && canPlay(game, c) ? (
            ui.select === c ? (
              <CCard key={c} c={c} side={true} class="Selected" options={{ onClick: () => uiDispatch({ type: "Select", card: c }) }} />
            ) : (
              <CCard
                key={c}
                c={c}
                side={true}
                class="CanSelect"
                options={{
                  onClick: () => {
                    uiDispatch({ type: "Select", card: c });
                  },
                }}
              />
            )
          ) : (
            <CCard key={c} c={c} side={true} />
          )
        )}
      </div>
    </div>
  );
}

function COtherPlayer(props: any) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  const c = props.id === game.turnPlayer ? "Other Turn" : "Other";
  return (
    <div key={info.name} className={props.className + " " + c}>
      <CPlayerStatus id={props.id} />
      <CPlayerField id={props.id} />
      <div className="Hand">
        {game.player[props.id].hand.map((c) => (
          <CCard key={c} c={c} side={game.state === "Teyaku"} />
        ))}
      </div>
    </div>
  );
}

function GoitaUIReducer(state: UI, action: any): UI {
  console.log("Reducer", state, action);
  let nUI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Select":
      nUI.select = nUI.select === action.card ? -1 : action.card;
      break;

    case "Init":
      return initUI();
  }

  return nUI;
}

function canPlay(game: Game, select: CardID) {
  const hand = game.cards[select];
  const trick = game.cards[game.trickCard];

  if (game.state === "Huse") {
    return true;
  }

  if (game.state === "Seme") {
    if (hand.suit === "王") {
      if (game.player[game.you].dama || game.player[game.you].hand.length === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  if (game.state !== "Uke") return false;

  if (hand.suit === trick.suit) return true;

  if (hand.suit === "王" && ["飛", "角", "金", "銀", "馬"].includes(trick.suit)) return true;

  return false;
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);

  const uiCallback = () => {
    uiDispatch({ type: "Init" });
  };

  function playField() {
    play(game, { command: "Hand", hand: ui.select, player: game.you }, uiCallback);
  }
  function passField() {
    play(game, { command: "Pass", player: game.you }, uiCallback);
  }

  const isTurn = game.turnPlayer === game.you;

  return (
    <div className="Action">
      {game.state === "Huse" &&
        isTurn &&
        (ui.select === -1 ? (
          <div className="ActionMessage">伏せる駒を選択してください</div>
        ) : (
          <Button onClick={playField} variant="contained">
            伏せ
          </Button>
        ))}
      {game.state === "Seme" &&
        isTurn &&
        (ui.select === -1 ? (
          <div className="ActionMessage">攻める駒を選択してください</div>
        ) : (
          <Button onClick={playField} variant="contained">
            攻め
          </Button>
        ))}
      {game.state === "Uke" &&
        isTurn &&
        (ui.select !== -1 ? (
          <Button onClick={playField} variant="contained">
            受け
          </Button>
        ) : (
          <Button onClick={passField} variant="contained">
            なし
          </Button>
        ))}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  let others = [] as number[];
  let otherId = nextPlayer(game, game.you);
  while (otherId !== game.you) {
    others.push(otherId);
    otherId = nextPlayer(game, otherId);
  }
  return (
    <div className="Game">
      <div className="Field">
        {game.state === "End" ? (
          <CEnd />
        ) : (
          <>
            <COtherPlayer className="Other1" id={others[0]} />
            <COtherPlayer className="Other2" id={others[1]} />
            <COtherPlayer className="Other3" id={others[2]} />
            <CPlayer />
            <CAction />
            {game.state === "Agari" && <CAgari />}
            {game.state === "Teyaku" && <CTeyaku />}
            {game.state === "5Si" && <C5Si />}
            {game.state === "5SiContinue" && <C5SiContinue />}
            {game.state === "5SiReset" && <C5SiReset />}
          </>
        )}
      </div>
      <CSummary />
    </div>
  );
}

function initDeck(): GCard[] {
  let id = 0;
  let deck: GCard[] = [];
  [...Array(1)].forEach((_, i) => deck.push({ id: id++, suit: "王", name: "王", point: 50 }));
  [...Array(1)].forEach((_, i) => deck.push({ id: id++, suit: "王", name: "玉", point: 50 }));
  [...Array(2)].forEach((_, i) => deck.push({ id: id++, suit: "飛", name: "飛", point: 40 }));
  [...Array(2)].forEach((_, i) => deck.push({ id: id++, suit: "角", name: "角", point: 40 }));
  [...Array(4)].forEach((_, i) => deck.push({ id: id++, suit: "金", name: "金", point: 30 }));
  [...Array(4)].forEach((_, i) => deck.push({ id: id++, suit: "銀", name: "銀", point: 30 }));
  [...Array(4)].forEach((_, i) => deck.push({ id: id++, suit: "馬", name: "馬", point: 20 }));
  [...Array(4)].forEach((_, i) => deck.push({ id: id++, suit: "香", name: "香", point: 20 }));
  [...Array(10)].forEach((_, i) => deck.push({ id: id++, suit: "し", name: "し", point: 10 }));

  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const sdeck = divide<CardID>(
    shuffle(
      [...Array(initDeck().length)].map((_, i) => i),
      r
    ),
    game.setting.seat.length
  )[1];
  return sdeck;
}

// デバッグ用
// function newDeck(game: Game) {
//   let sdeck;
//   const deck = initDeck();

//   do{
//     let r = new Random();
//     sdeck = divide<CardID>(
//       shuffle(
//         [...Array(deck.length)].map((_, i) => i),
//         r
//       ),
//       game.setting.seat.length
//     )[1];
//   }while(sdeck.filter(h=>h.filter(c=>deck[c].suit==="し").length ===5).length !== 2);
//   return sdeck;
// }
function newOrder(game: Game) {
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(game.setting.seat, r);
  } else {
    return game.setting.seat;
  }
}

function teyakuCheck(game: Game) {
  if (game.state !== "Huse") return;

  const teyaku = getTeyaku(game);
  if (teyaku.score > 0) {
    game.state = "Teyaku";
    game.wait = [false, false, false, false];
  }

  if (teyaku.score === -1) {
    game.state = "5Si";
    game.wait = teyaku.si5?.map((b) => (b ? "Select" : "Wait")) ?? [];
  }
}

function resetGame(game: Game, sdeck: number[][]) {
  let score;
  let player;

  switch (game.state) {
    case "Agari":
      score = getScore(game, game.player[game.turnPlayer].field);
      player = game.turnPlayer;
      break;

    case "Teyaku":
      score = getTeyaku(game).score;
      player = getTeyaku(game).player;
      break;

    case "5SiReset":
      score = 0;
      player = game.turnPlayer;
      break;
  }

  const winTeam = player % 2;

  const hands: CardID[][] = sdeck.map((d) => d.sort((ai, bi) => sortGoita(game.cards[ai], game.cards[bi])));
  const players: Player[] = game.player.map((p, i) => ({
    name: p.name,
    hand: hands[i],
    field: [],
    score: p.score + (i % 2 === winTeam ? score : 0),
    dama: hands[i].filter((c) => game.cards[c].suit === "王").length === 2,
  }));
  game.player = players;
  game.turnPlayer = player;
  game.trickPlayer = -1;
  game.trickCard = -1;
  game.state = game.player.some((p) => p.score >= 150) ? "End" : "Huse";
  teyakuCheck(game);
}

function startGame(game: Game, sdeck: number[][], order: string[]) {
  const deck = initDeck();
  const hands: CardID[][] = sdeck.map((d) => d.sort((ai, bi) => sortGoita(deck[ai], deck[bi])));
  const players: Player[] = order.map((p, i) => ({
    name: p,
    hand: hands[i],
    field: [],
    score: 0,
    dama: hands[i].filter((c) => deck[c].suit === "王").length === 2,
  }));
  game.cards = deck;
  game.player = players;
  game.turnPlayer = 0;
  game.trickPlayer = -1;
  game.trickCard = -1;
  game.state = "Huse";
  teyakuCheck(game);
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: JSON.parse(env.setting),
    state: "Init",
    you: -1,
    cards: [],
    step: 0,
    player: [],
    turnPlayer: 0,
    trickPlayer: -1,
    trickCard: -1,
    wait: [],
  };
}

function stepGoita(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;
  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;

  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          startGame(ng, action.sdeck, action.order);
          ng.you = action.order.indexOf(ng.env.name);
          return ng;
      }
      break;

    case "Huse":
      switch (action.command) {
        case "Hand":
          //出す
          ng.player[action.player].hand.splice(ng.player[action.player].hand.indexOf(action.hand), 1);
          ng.player[action.player].field.push({ card: action.hand, side: false });
          ng.trickPlayer = action.player;
          ng.trickCard = action.hand;
          ng.state = "Seme";
          return ng;
      }
      break;

    case "Seme":
      switch (action.command) {
        case "Hand":
          //出す
          ng.player[action.player].hand.splice(ng.player[action.player].hand.indexOf(action.hand), 1);
          ng.player[action.player].field.push({ card: action.hand, side: true });
          ng.trickPlayer = action.player;
          ng.trickCard = action.hand;

          //上がり判定
          if (ng.player[action.player].hand.length === 0) {
            ng.state = "Agari";
            ng.wait = [...Array(ng.player.length)].map(() => false);
          } else {
            ng.state = "Uke";
            ng.turnPlayer = nextPlayer(ng, ng.turnPlayer);
          }
          return ng;
      }
      break;

    case "Uke":
      switch (action.command) {
        case "Hand":
          //出す
          ng.player[action.player].hand.splice(ng.player[action.player].hand.indexOf(action.hand), 1);
          ng.player[action.player].field.push({ card: action.hand, side: true });
          ng.trickPlayer = action.player;
          ng.trickCard = action.hand;

          ng.state = "Seme";
          return ng;

        case "Pass":
          ng.turnPlayer = nextPlayer(ng, ng.turnPlayer);
          //流れ
          if (ng.turnPlayer === ng.trickPlayer) {
            ng.state = "Huse";
            ng.trickCard = -1;
          }
          return ng;
      }
      break;

    case "Agari":
    case "5SiReset":
    case "Teyaku":
      switch (action.command) {
        case "Check":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            resetGame(ng, action.sdeck);
            console.log("newgame", ng);
          }
          return ng;
      }
      break;

    case "5Si":
      switch (action.command) {
        case "Check":
          ng.wait[action.player] = action.check;
          if (!ng.wait.some((w) => w === "Select")) {
            ng.state = ng.wait.some((w) => w === "Stop") ? "5SiReset" : "5SiContinue";
            ng.wait = [...Array(ng.player.length)].map(() => false);
          }
          return ng;
      }
      break;

    case "5SiContinue":
      switch (action.command) {
        case "Check":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            ng.state = "Huse";
          }
          return ng;
      }
      break;
  }
  console.error("Unknown State or Action");

  return ng;
}

export const CGoita = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(GoitaUIReducer, undefined, initUI);

  console.log("Goita Render", game);

  useDB({ env: props.env, setGame: setGame, step: stepGoita });

  useEffect(() => {
    soundEffect();
  }, [game.state, game.turnPlayer, game.trickCard]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", sdeck: newDeck(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Goita">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="ごいた" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>ごいた</h2>
      <p>ごいたは日本の伝統的なゲームです。</p>
      <p>
        基本的なルールは{" "}
        <a href="http://goita.jp/rule/" target="_blank" rel="noreferrer">
          能登ごいた保存会 - ごいたの遊び方
        </a>{" "}
        をご覧ください。
      </p>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/goita/help1.png"} /> <br />
      </div>
    </div>
  );
}

export const Goita = {
  name: "ごいた",
  id: "Goita",
  settingDef: settingDef,
  people: [4, 4],
  component: CGoita,
  help: CHelp,
};
