import { Button } from "@material-ui/core/";
import { PlayArrow } from "@material-ui/icons/";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { bugReport } from "./GameCommand";

function CMessageButton() {
  const click = () => {
    const msg = prompt("メッセージを入力してください");
    if (msg)
      bugReport({
        msg: msg,
        ua: window.navigator.userAgent,
      });
  };

  return (
    <Button variant="contained" onClick={click}>
      メッセージ送信
    </Button>
  );
}

export function CAbout() {
  return (
    <div>
      <div className="About">
        <div>
          <h4>アップデート情報・アナウンス(Twitter)</h4>
          <TwitterTimelineEmbed sourceType="profile" screenName="tagapromotion" noHeader noFooter options={{ height: 200, width: 600 }} />
        </div>

        <div>
          <h4>コミュニケーション</h4>
          <p>
            TAGAにチャット機能はありません。 <br />
            プレイヤー同士のコミュニケーションには、Discordなどの外部チャットサービスの併用を推奨しています。
          </p>
        </div>

        <div>
          <h4>配信などについて</h4>
          <p>
            TAGAでのゲームプレイを生放送で配信したり、動画化して投稿することを歓迎します。 <br />
            その際、TAGAへのリンクを記載していただけるとありがたいです。
          </p>
        </div>

        <div>
          <h4>ゲーム募集</h4>
          <p>
            あなたの作ったボードゲームをネット対戦できるようにしませんか。 <br />
            TAGAでは、以下の条件でゲームを募集しています。 <br />
            ・無償でご対応いたします <br />
            ・ゲームの実装はTAGAで行いますので、ルールブックやコンポーネントの画像だけご提供いただきます <br />
            ・提供者様から申請があれば(理由を問わず)即時公開停止します <br />
          </p>
        </div>

        <div>
          <h4>運営について</h4>
          <p>
            TAGAは個人で運営しているウェブサービスです。
            <br />
            特定の企業・団体は関与しておりません。
            <br />
          </p>
        </div>

        <div>
          <h4>プライバシーポリシー</h4>
          <p>
            TAGAでは第三者配信の広告サービス（i-mobile Ad
            Network）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するためCookieを使用します。
            <br />
            広告設定を行うことで、パーソナライズ広告を無効にすることができます。 <br />
            Cookieを無効にする方法は「
            <a href="https://www.i-mobile.co.jp/optout.html" target="_blank" rel="noreferrer">
              オプトアウト | 株式会社アイモバイル
            </a>
            」をご確認ください。
            <br />
          </p>
          <p>広告Cookie以外にTAGAが個人情報を取得することはございません。</p>
        </div>

        <div>
          <h4>連絡先</h4>
          <p>
            TAGAへのご意見・ご連絡などがある場合は、以下をご利用ください。
            <br />
            Twitter:{" "}
            <a href="https://twitter.com/tagapromotion" target="_blank" rel="noreferrer">
              @TAGAPromotion
            </a>{" "}
            <br />
            Eメール： tagadevelop@gmail.com
            <br />
            簡易メッセージ(返信できません) <CMessageButton /> <br />
            管理者：タドゥー(
            <a href="https://twitter.com/tadolu" target="_blank" rel="noreferrer">
              @tadolu
            </a>
            )
          </p>
        </div>
      </div>
    </div>
  );
}

export function CHowTo() {
  return (
    <div>
      <div className="HowTo">
        <div>
          <h4>TAGAの使い方</h4>
          <p>
            TAGAでは、同じ部屋に参加している人と遊ぶことができます。
            <br />
            一緒に遊ぶ人の中で、誰か1人が部屋を作成してください。
            <br />
          </p>
        </div>
        <div>
          <h4>新しい部屋の作り方</h4>
          <p>
            以下のように入力して、決定ボタンを押してください。
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help1.png"} />
            <br />
          </p>
          <p>
            ユーザ名は、ゲーム中に表示したいあなたの名前を入力してください。
            <br />
            部屋名と部屋パスワードを決めて入力してください。<br />
            「ランダム生成」ボタンを押すと、部屋名にランダムな文字列が入力されます。
          </p>
          <p>
            部屋が作られると、下の画面になります。
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help2.png"} />
            <br />
            他の人に部屋名とパスワード、もしくは招待用URLを伝えてください。
            <br />
            招待用URLは「招待用URLをコピー」ボタンを押すと、クリップボードにコピーされます。
          </p>
          <p>「パスワードが間違っています。」と表示された場合は、別の部屋名でやり直してください。</p>
        </div>
        <div>
          <h4>部屋への参加方法</h4>
          <p>部屋を作った人から、部屋名とパスワード、もしくは招待用URLを受け取ってください。</p>
          <p>
            以下のように入力して、決定ボタンを押してください。
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help3.png"} />
            <br />
          </p>
          <p>
            ユーザ名は、ゲーム中に表示したいあなたの名前を入力してください。 <br />
            部屋を作った人から聞いた部屋名をパスワードを入力してください。
            <br />
            招待用URLからアクセスした場合、部屋名とパスワードが入力された状態で編集できないようになっています。
          </p>
        </div>
        <div>
          <h4>ゲームの始め方</h4>
          <p>
            部屋に参加している状態で、遊びたいゲームの
            <PlayArrow />
            を押してください。
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help4.png"} /> <br />
            ゲームが選択されると、部屋に参加している全員の画面が以下のようになります。
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help5.png"} />
            <br />
            プレイ人数変更ボタンで人数を調整し、着席ボタンを押してください。
            <br />
            オプションを設定してください。オプションの意味は画面下部のヘルプを参照してください。 <br />
            操作は参加者全員の画面で共有されています。 <br />
            別のゲームを遊ぶ場合は、「一覧に戻る」ボタンを押してください。
            <br />
            <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help6.png"} /> <br />
            全員の着席とオプションを確認したら、ゲーム開始ボタンを押してください。
          </p>
        </div>
        <div>
          <h4>ゲーム設定ボタン</h4>
          <p>
            それぞれのゲームを遊んでいる時、ゲーム画面の下のボタンから操作が行えます。 <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help7.png"} /> <br />
            <img alt="helpImg" src={process.env.PUBLIC_URL + "/help/help8.png"} /> <br />
            ニューゲーム <br />
            現在のゲームを終了し、新しいゲームを開始します。オプションの設定は引き継がれます。 <br />
            ゲームの決着がついた時や、途中でやり直したい時に選択してください。 <br />
            <br />
            ゲーム終了
            <br />
            現在のゲームを終了し、ゲーム一覧画面に戻ります。
            <br />
            ゲームの決着がついた時や、途中でやめる時に選択してください。 <br />
            <br />
            バグ報告
            <br />
            運営にバグを報告します。
            <br />
            変な表示になった時や、間違った動きをした時などに選択してください。
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
