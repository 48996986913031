
export function draw<D>(deck:D[], hand:D[], num:number) :[D[],D[]]
{
    num = Math.min(num,deck.length);
    return [deck.slice(num),hand.concat(deck.slice(0,num))];
}

export function divide<D>(deck:D[], num:number) :[D[],D[][]]
{
    let hands : D[][] = [...Array(num)].map(()=>[]);
    deck.map((c,i)=>hands[i%num].push(c));
    return [[],hands];
}
