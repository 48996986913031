import "./Backgammon.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, Play, GameSettingButton, Env, soundEffect } from "../GameCommand";

import { Button } from "@material-ui/core/";

import { Random } from "../lib/xs";

interface Setting {
  seat: string[];
}

const settingDef = [];

interface Player {
  name: string;
  score: number;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  field: number[];
  goal: number[];
  player: Player[];
  turnPlayer: number;
  dice: number[];
  moveLeft: number[];
  moved: number[];
  double: number;
  doubleLast: number;
  last: number;
  wait: any[];
  tmp: any;
}

const GameContext = React.createContext({} as Game);

interface UI {
  select: number;
}

function initUI() {
  return { select: -1 };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function opponent(base: number): number {
  return base === 0 ? 1 : 0;
}

function UIStateChange(game, dispatch) {
  dispatch({ type: "Init" });
}

function UIReducer(state: UI, action: any): UI {
  console.log("Reducer", state, action);
  let nUI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Select":
      if (nUI.select !== action.point) {
        nUI.select = action.point;
      } else {
        nUI.select = -1;
      }
      break;

    case "Init":
      return initUI();
  }

  return nUI;
}

function CMessage() {
  const game = useContext(GameContext);

  return (
    <>
      {game.state === "Win" && (
        <div className="Message">
          <div>{game.player[game.turnPlayer].name + " ポイント獲得"}</div>
          <div>{game.tmp.kind + " : " + game.tmp.score} </div>
        </div>
      )}
      {game.state === "Double" && game.you !== game.doubleLast && (
        <div className="Message">
          <div>ダブルが提案されました。</div>
        </div>
      )}
    </>
  );
}

function getRoll() {
  let r = new Random();
  const dice = [r.nextInt(1, 6), r.nextInt(1, 6)];
  return dice;
}

function get1stRoll() {
  let r = new Random();
  let dice;
  do {
    dice = [r.nextInt(1, 6), r.nextInt(1, 6)];
  } while (dice[0] === dice[1]);
  return dice;
}

function CAction() {
  const game = useContext(GameContext);

  function roll() {
    play(game, { command: "Roll", player: game.you, dice: getRoll() });
  }

  const isTurn = game.turnPlayer === game.you;

  return (
    <div className="Action">
      {isTurn && game.state === "Dice" && (
        <>
          <Button variant="contained" onClick={roll}>
            <CDice num={6} />
            <CDice num={6} />
          </Button>
          {game.doubleLast !== game.you && (
            <Button variant="contained" onClick={() => play(game, { command: "Double", player: game.you })}>
              ダブル
            </Button>
          )}
        </>
      )}
      {game.state === "Win" && !game.wait[game.you] && (
        <Button onClick={() => play(game, { command: "OK", dice: get1stRoll(), player: game.you })} variant="contained">
          OK
        </Button>
      )}
      {game.state === "Double" && game.you !== game.doubleLast && (
        <>
          <Button onClick={() => play(game, { command: "Take", player: game.you })} variant="contained">
            テイク
          </Button>
          <Button onClick={() => play(game, { command: "Pass", player: game.you })} variant="contained">
            パス
          </Button>
        </>
      )}
    </div>
  );
}

function CPlayers() {
  const game = useContext(GameContext);

  return (
    <>
      <div className={"Status StatusYou " + (game.turnPlayer === game.you ? "Turn" : "")}>
        <CPiece player={game.you} />
        <div>{game.player[game.you].name}</div>
        <div>{game.player[game.you].score}</div>
      </div>
      <div className={"Status StatusOpponent " + (game.turnPlayer === opponent(game.you) ? "Turn" : "")}>
        <CPiece player={opponent(game.you)} />
        <div>{game.player[opponent(game.you)].name}</div>
        <div>{game.player[opponent(game.you)].score}</div>
      </div>
    </>
  );
}

function CDice(props: { num: number; class?: string }) {
  return (
    <div className={"Dice " + (props.class ?? "")}>
      <img src={process.env.PUBLIC_URL + "/dice/" + props.num + ".png"} alt={"dice" + props.num} />
    </div>
  );
}

function CRolled() {
  const game = useContext(GameContext);
  return (
    <div className="Rolled">
      {game.dice.map((d, i) => (
        <CDice key={d + "_" + i} class={game.moved.includes(i) ? "Moved" : ""} num={d} />
      ))}
    </div>
  );
}

function CPiece(props: { player: number }) {
  return <div className={"Piece Piece" + props.player} />;
}

function CPoint(props: { id: number }) {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const num = game.field[props.id];

  const canSelect =
    game.state === "Move" &&
    game.turnPlayer === game.you &&
    ui.select === -1 &&
    game.moveLeft.some((m) => move(game.field, game.you, props.id, m).state !== "False");

  const selected = game.state === "Move" && game.turnPlayer === game.you && ui.select === props.id;

  const canMove =
    game.state === "Move" &&
    game.turnPlayer === game.you &&
    ui.select !== -1 &&
    game.moveLeft.some((m) => move(game.field, game.you, ui.select, m).to === props.id);

  let cname = "Point";
  if (canSelect) cname += " CanSelect";
  if (selected) cname += " Selected";
  if (canMove) cname += " CanMove";
  if (game.last === props.id) cname += " Last";

  function onClick() {
    if (canSelect || selected) {
      return () => {
        uiDispatch({ type: "Select", point: props.id });
      };
    }

    if (canMove) {
      const dice = game.moveLeft.find((m) => move(game.field, game.you, ui.select, m).to === props.id);
      return () => {
        play(game, { command: "Move", from: ui.select, dice: dice, player: game.you });
      };
    }

    return () => {};
  }

  return (
    <div className={cname} onClick={onClick()}>
      {[...Array(num < 0 ? -1 * num : num)].map((_, i) => (
        <CPiece key={props.id + "," + i} player={num < 0 ? 1 : 0} />
      ))}
    </div>
  );
}

function CField() {
  return (
    <div className="Field">
      {[...Array(26)].map((_, i) => (
        <CPoint key={i} id={25 - i} />
      ))}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);
  const cname = "Board " + (game.you === 0 ? "" : "BoardOpponent");

  return (
    <div className="Game">
      <div className={cname}>
        <CField />
      </div>
      <CPlayers />
      <CRolled />
      <div className="Double">{game.double}</div>
      <CAction />
      <CMessage />
    </div>
  );
}

function fixWin(game: Game) {
  game.player[game.turnPlayer].score += game.tmp.score;
  return game;
}

function resetGame(game: Game, dice: number[]) {
  game.field = [...Array(26)].map(() => 0);
  game.field[6] = 5;
  game.field[25 - 6] = -5;
  game.field[8] = 3;
  game.field[25 - 8] = -3;
  game.field[13] = 5;
  game.field[25 - 13] = -5;
  game.field[24] = 2;
  game.field[25 - 24] = -2;
  game.goal = [0, 0];
  game.turnPlayer = dice[0] > dice[1] ? 0 : 1;
  game.last = -1;
  game.double = 1;
  game.doubleLast = -1;
  game.state = "Move";
  game = setRoll(game, dice);

  return game;
}

function startGame(game: Game, dice: number[]) {
  game.player = game.setting.seat.map((s) => ({ name: s, score: 0 }));

  game = resetGame(game, dice);

  return game;
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: JSON.parse(env.setting),
    state: "Init",
    you: -1,
    step: 0,
    field: [],
    goal: [],
    player: [],
    turnPlayer: 0,
    dice: [],
    moveLeft: [],
    moved: [],
    double: 1,
    doubleLast: -1,
    last: -1,
    wait: [],
    tmp: null,
  };
}

function lockCheck(game: Game) {
  if (game.moveLeft.every((m) => [...Array(26)].every((_, i) => move(game.field, game.turnPlayer, i, m).state === "False"))) {
    game.turnPlayer = opponent(game.turnPlayer);
    game.state = "Dice";
  }
  return game;
}

function winCheck(game: Game) {
  const stans = game.turnPlayer === 0 ? 1 : -1;
  const base = game.turnPlayer === 0 ? 0 : 25;
  function index(i) {
    return base + i * stans;
  }

  if (game.goal[game.turnPlayer] < 15) return game; //自分の駒が残っている

  game.state = "Win";
  game.wait = [false, false];

  if (game.goal[opponent(game.turnPlayer)] > 0) {
    game.tmp = { kind: "シングル", score: 1 * game.double };
  } else if ([...Array(7)].some((_, i) => game.field[index(i)] * stans < 0)) {
    game.tmp = { kind: "バックギャモン", score: 3 * game.double };
  } else {
    game.tmp = { kind: "ギャモン", score: 2 * game.double };
  }

  return game;
}

function move(field: number[], player: number, from: number, diff: number) {
  let ret = { state: "False", to: -1, field: [...field] };
  if (player === 1) {
    ret.field.reverse();
    ret.field = ret.field.map((f) => f * -1);
    from = 25 - from;
  }

  if (ret.field[from] <= 0) return ret; //自分の駒が無い
  if (ret.field[25] > 0 && from !== 25) return ret; //エンター優先

  const to = from - diff;
  let last; //自駒のある最大ポイント
  for (last = 25; last >= 0; last--) if (ret.field[last] > 0) break;

  if (to <= 0) {
    //ベアリングオフ
    // 7～24ポイントに自駒が無い
    if (last <= 6) {
      // ちょうど、もしくは一番後ろ
      if (to === 0 || from === last) {
        ret.field[from]--;
        ret.state = "Goal";
        ret.to = 0;
      }
    }
  } else {
    if (ret.field[to] === -1) {
      ret.field[0]--; //ヒット
      ret.field[from]--;
      ret.field[to] = 1;
      ret.state = "Move";
      ret.to = to;
    } else if (ret.field[to] >= 0) {
      ret.field[from]--;
      ret.field[to]++;
      ret.state = "Move";
      ret.to = to;
    }
  }

  if (ret.state !== "False" && player === 1) {
    ret.field.reverse();
    ret.field = ret.field.map((f) => f * -1);
    ret.to = 25 - ret.to;
  }
  return ret;
}

function setRoll(game: Game, dice: number[]) {
  if (dice[0] === dice[1]) {
    game.moveLeft = [...dice, ...dice];
  } else {
    game.moveLeft = [...dice];
    game.moveLeft.sort((a, b) => a - b);
  }
  game.dice = [...game.moveLeft];
  game.moved = [];

  return game;
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;
  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;

  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGame(ng, action.dice);
          return ng;
      }
      break;

    case "Dice":
      switch (action.command) {
        case "Roll":
          ng = setRoll(ng, action.dice);
          ng.state = "Move";
          ng = lockCheck(ng);
          return ng;

        case "Double":
          ng.doubleLast = action.player;
          ng.state = "Double";
          return ng;
      }
      break;

    case "Move":
      switch (action.command) {
        case "Move":
          console.assert(ng.turnPlayer === action.player);
          const m = move(ng.field, action.player, action.from, action.dice);
          ng.field = m.field;
          ng.moveLeft.splice(ng.moveLeft.indexOf(action.dice), 1);
          ng.moved.push(ng.dice.findIndex((d, i) => d === action.dice && !ng.moved.includes(i)));
          ng.last = m.state === "Move" ? m.to : -1;
          if (m.state === "Goal") ng.goal[ng.turnPlayer]++;

          ng = winCheck(ng);
          if (ng.state !== "Win") {
            if (ng.moveLeft.length === 0) {
              ng.turnPlayer = opponent(ng.turnPlayer);
              ng.state = "Dice";
            } else {
              ng = lockCheck(ng);
            }
          }
          return ng;
      }
      break;

    case "Double":
      switch (action.command) {
        case "Take":
          ng.double *= 2;
          ng.state = "Dice";
          return ng;

        case "Pass":
          ng.state = "Win";
          ng.wait = [false, false];
          ng.tmp = { kind: "ダブルパス", score: game.double };
          return ng;
      }
      break;

    case "Win":
      switch (action.command) {
        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            ng = fixWin(ng);
            ng = resetGame(ng, action.dice);
          }
          return ng;
      }
      break;
  }

  console.error("Unknown State or Action");

  return ng;
}

export const CBackgammon = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  console.log("Backgammon Render", game);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    UIStateChange(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.step]);

  useEffect(() => {
    soundEffect();
  }, [game.turnPlayer, game.state]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", dice: get1stRoll() });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Backgammon">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="バックギャモン" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>バックギャモン</h2>
      <p>バックギャモンは伝統的なテーブルゲームです。</p>
      <p>
        基本的なルールは{" "}
        <a href="https://ja.wikipedia.org/wiki/%E3%83%90%E3%83%83%E3%82%AF%E3%82%AE%E3%83%A3%E3%83%A2%E3%83%B3" target="_blank" rel="noreferrer">
          バックギャモン - Wikipedia
        </a>{" "}
        をご覧ください。
      </p>
      <p>「サイコロの目を可能な限り多く使わなければならない」というルールには未対応です。</p>
      <p>ゲームは繰り返し続きます。マッチポイントなどは参加者で取り決めてください。</p>

      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/backgammon/help1.png"} /> <br />
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/backgammon/help2.png"} /> <br />
      </div>
    </div>
  );
}

export const Backgammon = {
  name: "バックギャモン",
  id: "Backgammon",
  settingDef: settingDef,
  people: [2, 2],
  component: CBackgammon,
  help: CHelp,
};
