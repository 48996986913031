import "./HimeSyogi.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, Play, soundEffect } from "../GameCommand";
import { Random, shuffle } from "../lib/xs";
import { Button, Tooltip } from "@material-ui/core/";

interface Setting {
  seat: string[];
  orderRandom: boolean;
  brawl: boolean;
}

const settingDef = [
  { kind: "boolean", id: "orderRandom", name: "席順ランダム", default: true },
  { kind: "boolean", id: "brawl", name: "乱闘モード", default: false },
];

interface Player {
  state: string;
  name: string;
  deck: number[];
  hand: number[];
  discard: number[];
  last: number;
  goal: number[];
  brawl: number[];
}

interface Chip {
  kind: string;
  player: number;
}

const chipKinds = ["ヒメ", "ニンジャ", "マジョ", "テロ", "キョンシー"];
const chipSkills = [himeSkill, ninjaSkill, majoSkill, teroSkill, kyonshiSkill];
const chipTxt = [
  "敵のヒメは全て1マス前進する",
  "左上に3マス進む",
  "1番遠くの敵と入れ替わる",
  "この駒と周囲1マスの駒は全て倒れる",
  "相手は全員手札を捨てる",
];

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  player: Player[];
  chips: Chip[];
  field: number[][];
  turnPlayer: number;
  last: number;
  wait: any[];
  tmp: any;
}

const GameContext = React.createContext({} as Game);

interface UI {
  select: number;
  selects: number[];
}

function initUI() {
  return { select: -1, selects: [] };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function UICheck(game, dispatch) {
  dispatch({ type: "Init" });
}

function UIReducer(state: UI, action: any): UI {
  console.log("Reducer", state, action);
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Init":
      return initUI();

    case "Select":
      if (nUI.select === action.select) {
        nUI.select = -1;
      } else {
        nUI.select = action.select;
      }
      return nUI;

    case "Selects":
      if (nUI.selects.includes(action.select)) {
        nUI.selects = nUI.selects.filter((s) => s !== action.select);
      } else {
        nUI.selects.push(action.select);
      }
      return nUI;
  }

  return nUI;
}

function chip2filename(c: Chip) {
  return process.env.PUBLIC_URL + "/himesyogi/chip" + c.player + "_" + chipKinds.indexOf(c.kind) + ".png";
}

function card2filename(id: number, player: number) {
  return process.env.PUBLIC_URL + "/himesyogi/card" + player + "_" + id + ".png";
}

function filedfilename(game: Game) {
  return process.env.PUBLIC_URL + "/himesyogi/field" + game.player.length + ".png";
}

function nextPlayer(game: Game, base: number, diff: number = 1): number {
  return (base + game.setting.seat.length - diff) % game.setting.seat.length;
}

function nextTurnPlayer(game: Game, base: number): number {
  let ret = base;
  do {
    ret = nextPlayer(game, ret);
  } while (!(game.player[ret].state !== "Lose" || ret === base));

  return ret;
}

function getDir(game: Game, player: number) {
  if (game.player.length === 2) {
    return [0, 3][player];
  }
  return [0, 4, 2][player];
}

function getDistance(n: number) {
  let ret: number[][] = [];
  ret.push([...Array(n)].map(() => 0));

  for (let d = 1; d <= n; d++) {
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 0 : 5)));
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 0 : 1)));
  }

  for (let d = 1; d <= n; d++) {
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 5 : 4)));
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 1 : 2)));
  }

  for (let d = 1; d < n; d++) {
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 4 : 3)));
    ret.push([...Array(n)].map((_, i) => (i < n - d ? 2 : 3)));
  }

  ret.push([...Array(n)].map(() => 3));
  return ret;
}

function CChip(props: { id: number; class?: string; options?: any }) {
  const game = useContext(GameContext);
  if (props.id === -1) return null;
  const c = game.chips[props.id];
  let cname = "Chip Dir" + getDir(game, c.player) + " " + (props.class ?? "");
  if (props.id === game.last) cname += " Last";
  return (
    <div className={cname}>
      <Tooltip title={chipTxt[chipKinds.indexOf(c.kind)]}>
        <img src={chip2filename(c)} alt="Chip" {...props.options} />
      </Tooltip>
    </div>
  );
}

function CCard(props: { id: number; player: number; class?: string; options?: any }) {
  return (
    <div className={"Card " + props.class ?? ""}>
      <img src={card2filename(props.id, props.player)} alt="Card" {...props.options} />
    </div>
  );
}

function CField() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);

  function chipOption(c: number) {
    if (c === -1) return {};
    const chip = game.chips[c];
    if (game.turnPlayer === game.you && chip.player === game.you && game.player[game.you].hand.includes(ui.select)) {
      return {
        onClick: () => {
          play(game, { command: "Card", card: ui.select, chip: c, player: game.you, decks: newDecks(game) });
        },
      };
    }
    return {};
  }

  function chipClass(c: number) {
    if (c === -1) return "";
    const chip = game.chips[c];
    if (game.turnPlayer === game.you && chip.player === game.you && game.player[game.you].hand.includes(ui.select)) {
      return "";
    }
    return "";
  }

  function hexProps([y, x]: number[]) {
    if (game.state === "Brawl" && !game.wait[game.you] && game.player[game.you].brawl.includes(ui.select) && game.field[y][x] === -1) {
      const [gy, gx] = game.player[game.you].goal;
      const canPos = [[0, 3], [5], [0], [1], [5, 5], [5, 0], [1, 0], [1, 1]].map((a) => farPos(game, [gy, gx], getDir(game, game.you), a));
      if (canPos.some(([cy, cx]) => cy === y && cx === x)) {
        return { className: "Hex CanSelect", onClick: () => play(game, { command: "Chip", y: y, x: x, chip: ui.select, player: game.you }) };
      }
    }
    return { className: "Hex" };
  }

  return (
    <div className={"Field Field" + game.player.length + (game.you !== 0 ? " FieldRev" : "")}>
      <img src={filedfilename(game)} alt="Field" className="Back" />
      <table>
        <tbody>
          {game.field.flatMap((l, y) => (
            <tr key={y}>
              {l.map((c, x) => (
                <td key={y + ":" + x}>
                  <div {...hexProps([y, x])}>
                    <CChip id={c} class={chipClass(c)} options={chipOption(c)} />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CMessage() {
  const game = useContext(GameContext);
  let msg = "";
  if (game.state === "Brawl" && !game.wait[game.you]) msg = "置きたい駒を選択した後、置く場所を選択してください";
  if (game.state === "Card" && game.turnPlayer === game.you) msg = "使用するカードを選択した後、適用する駒を選択してください";
  if (game.state === "Discard" && game.turnPlayer === game.you) msg = "手札が3枚になるように捨てるカードを選択してください";
  if (game.state === "End") msg = game.player[winPlayer(game)].name + "の勝利";
  return <div className={"Message Message" + game.player.length}>{msg}</div>;
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);

  return (
    <div className={"Action Action" + game.player.length}>
      {game.state === "Discard" && game.turnPlayer === game.you && game.player[game.you].hand.length - ui.selects.length === 3 && (
        <Button onClick={() => play(game, { command: "Discard", cards: ui.selects, player: game.you })} variant="contained">
          捨てる
        </Button>
      )}
      {game.state === "Brawl" &&
        !game.wait[game.you] &&
        game.player[game.you].brawl.length <= 2 &&
        game.player[game.you].brawl.every((c) => game.chips[c].kind !== "ヒメ") && (
          <Button onClick={() => play(game, { command: "OK", cards: ui.selects, player: game.you })} variant="contained">
            配置を終了する
          </Button>
        )}
    </div>
  );
}

function CPlayer() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const info = game.player[game.you];
  const cname = "You" + game.player.length + (game.turnPlayer === game.you ? " Turn" : "");

  function option(c: number) {
    if (game.turnPlayer === game.you) {
      if (game.state === "Card") {
        return { onClick: () => uiDispatch({ type: "Select", select: c }) };
      }
      if (game.state === "Discard") {
        return { onClick: () => uiDispatch({ type: "Selects", select: c }) };
      }
    }
    if (game.state === "Brawl" && !game.wait[game.you]) {
      return { onClick: () => uiDispatch({ type: "Select", select: c }) };
    }
    return {};
  }

  function cardclass(c: number) {
    if (game.turnPlayer === game.you) {
      if (game.state === "Card") {
        if (info.hand.includes(ui.select)) {
          if (ui.select === c) {
            return "Selected";
          } else {
            return "";
          }
        } else {
          // return "CanSelect";
          return "";
        }
      }
      if (game.state === "Discard") {
        if (ui.selects.includes(c)) {
          return "Selected";
        }
      }
    }
    return "";
  }

  function chipclass(c: number) {
    if (!game.wait[game.you]) {
      if (info.brawl.includes(ui.select)) {
        if (ui.select === c) {
          return "Selected";
        }
      }
    }
  }

  return (
    <div className={"Player You " + cname}>
      <div className="Name">{info.name}</div>
      <div className="Deck">山札:{info.deck.length}</div>
      {game.state === "Brawl" ? (
        <div className="Chips">
          {info.brawl.map((c, i) => (
            <CChip key={i} id={c} class={chipclass(c)} options={option(c)} />
          ))}
        </div>
      ) : (
        <div className="Cards">
          手札
          <div className="Hand">
            {info.hand.map((h, i) => (
              <CCard key={i} id={h} player={game.you} class={cardclass(h)} options={option(h)} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function COther(props: { id: number; cname: string }) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  const cname = game.turnPlayer === props.id ? " Turn" : "";
  return (
    <div className={"Player Other " + props.cname + cname}>
      <div className="Name">{info.name}</div>
      <div className="Deck">山札:{info.deck.length}</div>
      <div className="Hand">手札:{info.hand.length}</div>
      <div className="Discard">
        {info.last !== -1 && <CCard id={info.last} player={props.id} />}
      </div>
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  return (
    <div className="Game">
      <CField />
      <CPlayer />
      {game.player.length === 2 ? (
        <COther id={nextPlayer(game, game.you)} cname="Other21" />
      ) : (
        <>
          <COther id={nextPlayer(game, game.you)} cname="Other31" />
          <COther id={nextPlayer(game, game.you, 2)} cname="Other32" />
        </>
      )}
      <CMessage />
      <CAction />
    </div>
  );
}

function newOrder(game: Game) {
  let order = game.setting.seat.map((_, i) => i);
  if (game.setting.orderRandom) {
    let r = new Random();
    return shuffle(order, r);
  } else {
    return order;
  }
}

function newDecks(game: Game) {
  let r = new Random();
  return game.setting.seat.map(() => {
    let deck = [...Array(13)].map((_, i) => i);
    return shuffle(deck, r);
  });
}

function setDefaultChip(game: Game, player: number) {
  const [gy, gx] = game.player[player].goal;
  game.field[gy][gx] = game.chips.findIndex((c) => c.kind === "ヒメ" && c.player === player);
  const [my, mx] = farPos(game, [gy, gx], getDir(game, player), [5]);
  game.field[my][mx] = game.chips.findIndex((c) => c.kind === "マジョ" && c.player === player);
  const [ny, nx] = farPos(game, [gy, gx], getDir(game, player), [1, 1]);
  game.field[ny][nx] = game.chips.findIndex((c) => c.kind === "ニンジャ" && c.player === player);
}

function startGame(game: Game, sdecks: number[][], order: number[]) {
  game.chips = [...Array(order.length)].flatMap((_, i) => chipKinds.map((k) => ({ kind: k, player: i })));
  game.player = order.map((o, i) => ({ name: game.setting.seat[o], state: "Game", goal: [], deck: sdecks[i], hand: [], discard: [], last:-1, brawl: [] }));
  game.player.forEach((_, i) => {
    game.player[i].hand = game.player[i].deck.splice(0, 3);
  });
  game.you = game.player.findIndex((p) => p.name === game.env.name);

  if (game.player.length === 2) {
    game.field = [...Array(5)].map(() => [...Array(5)].map(() => -1));
    game.player[0].goal = [4, 2];
    game.player[1].goal = [0, 2];
  } else {
    game.field = [...Array(7)].map(() => [...Array(7)].map(() => -1));
    game.player[0].goal = [6, 3];
    game.player[1].goal = [1, 6];
    game.player[2].goal = [1, 0];
  }

  game.turnPlayer = 0;
  game.last = -1;

  if (game.setting.brawl) {
    game.player.forEach((_, i) => {
      game.player[i].brawl = game.chips.map((c, ci) => (c.player === i ? ci : -1)).filter((ci) => ci !== -1);
      game.wait = game.player.map(() => false);
      game.state = "Brawl";
    });
  } else {
    game.player.forEach((_, i) => setDefaultChip(game, i));
    game.state = "Card";
  }

  return game;
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [], orderRandom: false, brawl: false },
    state: "Init",
    you: -1,
    step: 0,
    player: [],
    chips: [],
    field: [],
    turnPlayer: 0,
    last: -1,
    wait: [],
    tmp: null,
  };
}

function validPos(game: Game, [y, x]: number[]) {
  if (game.player.length === 2) {
    switch (y) {
      case 0:
        return 1 <= x && x <= 3;
      case 1:
      case 2:
      case 3:
        return 0 <= x && x <= 4;
      case 4:
        return x === 2;
    }
  } else {
    switch (y) {
      case 0:
        return 2 <= x && x <= 4;
      case 1:
      case 2:
      case 3:
      case 4:
        return 0 <= x && x <= 6;
      case 5:
        return 1 <= x && x <= 5;
      case 6:
        return x === 3;
    }
  }
  return false;
}

function nextPos(game: Game, [y, x]: number[], dir: number) {
  const isAllign = game.player.length === 2 ? x % 2 === 0 : x % 2 === 1;
  switch (dir) {
    case 0:
      return [y - 1, x];
    case 1:
      return isAllign ? [y - 1, x + 1] : [y, x + 1];
    case 2:
      return isAllign ? [y, x + 1] : [y + 1, x + 1];
    case 3:
      return [y + 1, x];
    case 4:
      return isAllign ? [y, x - 1] : [y + 1, x - 1];
    case 5:
      return isAllign ? [y - 1, x - 1] : [y, x - 1];
  }
  return [-1, -1];
}

function farPos(game: Game, [y, x]: number[], fromDir: number, dirs: number[]) {
  return dirs.reduce(
    (acc, dir) => {
      return nextPos(game, acc, (fromDir + dir) % 6);
    },
    [y, x]
  );
}

function lose(game: Game, player: number) {
  game.player[player].state = "Lose";
  game.field.forEach((a, y) =>
    a.forEach((_, x) => {
      if (game.field[y][x] !== -1 && game.chips[game.field[y][x]].player === player) {
        game.field[y][x] = -1;
      }
    })
  );
}

function isEnd(game: Game) {
  return game.player.filter((p) => p.state !== "Lose").length === 1;
}

function winPlayer(game: Game) {
  return game.player.findIndex((p) => p.state !== "Lose");
}

function move(game: Game, chip: number, dirs: number[]) {
  const player = game.chips[chip].player;
  const pdir = getDir(game, player);

  for (const dir of dirs) {
    if (isEnd(game)) break;
    const [y, x] = findFieldPos(game, (c) => c === chip);
    if (!validPos(game, [y, x])) break;
    game.field[y][x] = -1;
    const [ny, nx] = nextPos(game, [y, x], (pdir + dir) % 6);
    if (validPos(game, [ny, nx])) {
      let isBreak = false;
      if (game.field[ny][nx] !== -1) {
        const death = game.chips[game.field[ny][nx]];
        if (death.kind === "ヒメ") {
          lose(game, death.player);
        }
        isBreak = true;
      }
      game.field[ny][nx] = chip;
      if (game.chips[chip].kind === "ヒメ") {
        const fp = game.player.findIndex((p, i) => i !== player && p.goal[0] === ny && p.goal[1] === nx);
        if (fp !== -1) {
          lose(game, fp);
          isBreak = true;
        }
      }
      if (isBreak) break;
    } else {
      //範囲外に出る
      if (game.chips[chip].kind === "ヒメ") {
        lose(game, player);
      }
      break;
    }
  }

  return game;
}

function draw(game: Game, player: number, num: number) {
  const left = num - game.player[player].deck.length;
  game.player[player].hand = game.player[player].hand.concat(game.player[player].deck.splice(0, num));
  if (left > 0) {
    game.player[player].deck = game.tmp[player].filter((c) => game.player[player].discard.includes(c));
    game.player[player].discard = [];
    game.player[player].hand = game.player[player].hand.concat(game.player[player].deck.splice(0, left));
  }
  return game;
}

function findFieldPos(game: Game, cond: (number) => boolean) {
  const y = game.field.findIndex((a) => a.some(cond));
  if (y === -1) return [-1, -1];
  const x = game.field[y].findIndex(cond);
  return [y, x];
}

function card0(game: Game, player: number, chip: number) {
  return move(game, chip, [0]);
}

function card1(game: Game, player: number, chip: number) {
  return move(game, chip, [1]);
}

function card2(game: Game, player: number, chip: number) {
  return move(game, chip, [5]);
}

function card3(game: Game, player: number, chip: number) {
  game = move(game, chip, [2]);
  game = draw(game, player, 3);
  return game;
}

function card4(game: Game, player: number, chip: number) {
  game = move(game, chip, [4]);
  game = draw(game, player, 3);
  return game;
}

function card5(game: Game, player: number, chip: number) {
  game = move(game, chip, [3]);
  game = draw(game, player, 3);
  return game;
}

function card6(game: Game, player: number, chip: number) {
  return move(game, chip, [0, 0]);
}

function card7(game: Game, player: number, chip: number) {
  return move(game, chip, [1, 1]);
}

function card8(game: Game, player: number, chip: number) {
  return move(game, chip, [5, 5]);
}

function card9(game: Game, player: number, chip: number) {
  return move(game, chip, [0, 1]);
}

function card10(game: Game, player: number, chip: number) {
  return move(game, chip, [0, 5]);
}

function himeSkill(game: Game, player: number, chip: number) {
  let p = nextPlayer(game, player);
  do {
    const chip = ((tp) => game.chips.findIndex((c) => c.player === tp && c.kind === "ヒメ"))(p);
    if (chip !== -1) move(game, chip, [0]);
    p = nextPlayer(game, p);
  } while (p !== player);

  return game;
}

function majoSkill(game: Game, player: number, chip: number) {
  const [y, x] = findFieldPos(game, (c) => c === chip);
  const pdir = getDir(game, game.chips[chip].player);

  const disrev = [...Array(6)].flatMap((_, i) => getDistance(i + 1)).reverse();

  for (const dirs of disrev) {
    const [ty, tx] = farPos(game, [y, x], pdir, dirs);
    if (validPos(game, [ty, tx]) && game.field[ty][tx] !== -1 && game.chips[game.field[ty][tx]].player !== player) {
      const tmp = game.field[ty][tx];
      game.field[ty][tx] = chip;
      game.field[y][x] = tmp;
      if (game.chips[tmp].kind === "ヒメ") {
        const fp = game.player.findIndex((p, i) => i !== game.chips[tmp].player && p.goal[0] === y && p.goal[1] === x);
        if (fp !== -1) lose(game, fp);
      }
      break;
    }
  }

  return game;
}

function ninjaSkill(game: Game, player: number, chip: number) {
  return move(game, chip, [5, 5, 5]);
}

function teroSkill(game: Game, player: number, chip: number) {
  const [y, x] = findFieldPos(game, (c) => c === chip);
  const pdir = getDir(game, game.chips[chip].player);
  const around = getDistance(1);
  game.field[y][x] = -1;
  for (const dirs of around) {
    const [ty, tx] = farPos(game, [y, x], pdir, dirs);
    if (validPos(game, [ty, tx]) && game.field[ty][tx] !== -1) {
      const tmp = game.field[ty][tx];
      game.field[ty][tx] = -1;
      if (game.chips[tmp].kind === "ヒメ") {
        lose(game, game.chips[tmp].player);
      }
      if (isEnd(game)) break;
    }
  }
  return game;
}

function kyonshiSkill(game: Game, player: number, chip: number) {
  game.player.forEach((_, i) => {
    if (i !== player) {
      game.player[i].discard = game.player[i].discard.concat(game.player[i].hand);
      game.player[i].hand = [];
      draw(game, i, 3);
    }
  });
  return game;
}

function cardSkill(game: Game, player: number, chip: number) {
  chipSkills[chipKinds.indexOf(game.chips[chip].kind)](game, player, chip);
  return game;
}

const cards = [card0, card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, cardSkill, cardSkill];

function cardPlay(game: Game, player: number, card: number, chip: number) {
  game = cards[card](game, player, chip);
  game.player[player].hand = game.player[player].hand.filter((h) => h !== card);
  game.player[player].discard.push(card);
  if (game.player[player].hand.length < 3) draw(game, player, 3 - game.player[player].hand.length);
  game.last = chip;
  game.player[player].last = card;

  return game;
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;

  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGame(ng, action.sdecks, action.order);
          return ng;
      }
      break;

    case "Card":
      switch (action.command) {
        case "Card":
          ng.tmp = action.decks;
          ng = cardPlay(ng, action.player, action.card, action.chip);
          if (isEnd(ng)) {
            ng.state = "End";
          } else if (ng.player[action.player].hand.length > 3) {
            ng.state = "Discard";
          } else {
            ng.turnPlayer = nextTurnPlayer(ng, ng.turnPlayer);
          }
          return ng;
      }
      break;

    case "Discard":
      switch (action.command) {
        case "Discard":
          ng.player[action.player].hand = ng.player[action.player].hand.filter((h) => !action.cards.includes(h));
          ng.player[action.player].discard = ng.player[action.player].discard.concat(action.cards);
          ng.turnPlayer = nextTurnPlayer(ng, ng.turnPlayer);
          ng.state = "Card";
          return ng;
      }
      break;

    case "Brawl":
      switch (action.command) {
        case "Chip":
          ng.player[action.player].brawl = ng.player[action.player].brawl.filter((c) => c !== action.chip);
          ng.field[action.y][action.x] = action.chip;
          if (ng.player[action.player].brawl.length === 0) ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) ng.state = "Card";
          return ng;

        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) ng.state = "Card";
          return ng;
      }
      break;
  }
  console.error("Unknown State or Action");

  return ng;
}

export const CHimeSyogi = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    console.log("Render", game);
  }, [game]);

  useEffect(() => {
    UICheck(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.turnPlayer]);

  useEffect(() => {
    soundEffect();
  }, [game.turnPlayer, game.state]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", sdecks: newDecks(game), order: newOrder(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="HimeSyogi">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="姫将棋" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>姫将棋</h2>
      <p>
        ゲームデザイン:
        <a href="https://twitter.com/uracon_" target="_blank" rel="noreferrer">
          uracon
        </a>
      </p>
      <p>
        <a href="https://gamemarket.jp/game/3755" target="_blank" rel="noreferrer">
          ゲーム情報
        </a>
        (現在は頒布されていません)
      </p>
      <p>ルールブック</p>
      <img style={{ border: "double black 2px" }} src={process.env.PUBLIC_URL + "/himesyogi/doc1.png"} alt="doc" />
      <img style={{ border: "double black 2px" }} src={process.env.PUBLIC_URL + "/himesyogi/doc2.png"} alt="doc" />
      <div>
        <h4>乱闘モード</h4>
        <p>
          ヒメを含む3つ以上の駒を、自由に配置してからスタートします
          <br />
          個数や置く順序などはプレイヤー間で取り決めてください
        </p>
      </div>
      <div>
        <h4>行動カード一覧</h4>
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_0.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_1.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_2.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_3.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_4.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_5.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_6.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_7.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_8.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_9.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_10.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_11.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/card0_12.png"} />
      </div>
      <div>
        <h4>駒一覧</h4>
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/chip0_0.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/chip0_1.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/chip0_2.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/chip0_3.png"} />
        <img alt="helpImg" style={{ width: 200, margin: 1 }} src={process.env.PUBLIC_URL + "/himesyogi/chip0_4.png"} />
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/himesyogi/help1.png"} /> <br />
      </div>
    </div>
  );
}

export const HimeSyogi = {
  name: "姫将棋",
  id: "HimeSyogi",
  people: [2, 3],
  settingDef: settingDef,
  component: CHimeSyogi,
  help: CHelp,
};
