import "./HakyokuDice.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, Play, soundEffect } from "../GameCommand";
import { Random, shuffle } from "../lib/xs";
import { Button, Tooltip } from "@material-ui/core/";

interface Setting {
  seat: string[];
}

const settingDef:any[] = [];

interface Player {
  state: string; //Want, Get, Lose
  name: string;
  chip: number[];
  dice: number[];
  reroll: number;
  score: number;
}

interface Chip {
  kind: string;
  name: string;
  text: string;
  dice: number[];
  func: (p: Player) => number;
}

interface FieldChip {
  id: number;
  player: number[];
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  stage: number;
  you: number;
  step: number;
  rollcount: number;
  player: Player[];
  field: FieldChip[];
  deck: number[];
  wait: any[];
}

const GameContext = React.createContext({} as Game);

interface UI {
  selectChip: number;
  selectDice: number[];
}

function initUI() {
  return { selectChip: -1, selectDice: [] };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function UICheck(game, dispatch) {
  dispatch({ type: "Init" });
}

function UIReducer(state: UI, action: any): UI {
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Init":
      return initUI();

    case "Chip":
      nUI.selectChip = nUI.selectChip === action.chip ? -1 : action.chip;
      return nUI;

    case "Dice":
      nUI.selectDice = nUI.selectDice.includes(action.dice) ? nUI.selectDice.filter((d) => d !== action.dice) : nUI.selectDice.concat(action.dice);
      return nUI;
  }

  return nUI;
}

function chip2filename(id: number) {
  return process.env.PUBLIC_URL + "/hakyokudice/chip" + id + ".png";
}

function dice2filename(num: number) {
  return process.env.PUBLIC_URL + "/hakyokudice/dice" + num + ".png";
}

// prettier-ignore
const chips: Chip[] = [
  { kind: "Chara", name: "uracon", text: "同じ目が2個以上出ると-6", dice: [], func: (p) => p.dice.some(d=>p.dice.filter(ad=>ad===d).length >= 2) ? -6 :0 },
  { kind: "Chara", name: "キノ子", text: "偶数の目が2個以上出ると-5", dice: [], func: (p) => (p.dice.filter((d) => d % 2 === 0).length >= 2 ? -5 : 0) },
  { kind: "Chara", name: "POP☆OMI", text: "MINTを持っていると-100", dice: [], func: (p) => (p.chip.map((c) => chips[c].name).includes("MINT") ? -100 : 0) },
  { kind: "Chara", name: "高橋三津雄", text: "奇数の目が2個以上出ると-4", dice: [], func: (p) => (p.dice.filter((d) => d % 2 === 1).length >= 2 ? -4 : 0) },
  { kind: "Chara", name: "ユ・バリデル", text: "可能なら必ず壺に投票する", dice: [], func: (p) => 0 },
  { kind: "Chara", name: "まひろ", text: "6の目が2個以上出ると-100", dice: [], func: (p) => (p.dice.filter((d) => d === 6).length >= 2 ? -100 : 0) },
  { kind: "Dice", name: "MINT", text: "", dice: [1, 1, 1, 1, 1, 9], func: (p) => 0 },
  { kind: "Dice", name: "ICHIGO", text: "", dice: [1, 1, 5, 5, 5, 5], func: (p) => 0 },
  { kind: "Dice", name: "RUM", text: "", dice: [1, 4, 4, 4, 4, 4], func: (p) => 0 },
  { kind: "Dice", name: "CHOCO", text: "", dice: [2, 2, 4, 4, 6, 6], func: (p) => 0 },
  { kind: "Dice", name: "DINAGON", text: "", dice: [1, 1, 1, 6, 6, 6], func: (p) => 0 },
  { kind: "Item", name: "金のがちょう", text: "奇数の目が出た個数だけ+2", dice: [], func: (p) => p.dice.filter((d) => d % 2 === 1).length * 2 },
  { kind: "Item", name: "打ち出の小槌", text: "偶数の目が出た個数だけ+1", dice: [], func: (p) => p.dice.filter((d) => d % 2 === 0).length },
  { kind: "Item", name: "王冠", text: "出目が全て同じなら+100", dice: [], func: (p) => (p.dice.every((d) => d === p.dice[0]) ? 100 : 0) },
  { kind: "Item", name: "ティアラ", text: "3の目が出た個数だけ+3", dice: [], func: (p) => p.dice.filter((d) => d === 3).length * 3 },
  { kind: "Item", name: "アサリ", text: "+2", dice: [], func: (p) => 2 },
  { kind: "Item", name: "アオノリ", text: "+1", dice: [], func: (p) => 1 },
  { kind: "Item", name: "壺", text: "何の意味もない壺", dice: [], func: (p) => 0 },
  { kind: "Goal", name: "幸運のお守り", text: "このゲームに勝利する", dice: [], func: (p) => 0 },
];

function CChip(props: { id: number; class?: string; options?: any }) {
  let cname = "Chip " + (props.class ?? "");
  const info = chips[props.id];
  const txt = info.name + ": " + (info.kind === "Dice" ? info.dice.join(",") : info.text);
  return (
    <div className={cname}>
      <Tooltip title={txt}>
        <img src={chip2filename(props.id)} alt="Chip" {...props.options} />
      </Tooltip>
    </div>
  );
}

function CDice(props: { player: number; id: number; class?: string; options?: any }) {
  const game = useContext(GameContext);
  const ds = getDices(game, props.player);
  let cname = "Dice " + (props.class ?? "");

  return (
    <div className={cname}>
      <Tooltip title={ds[props.id].join(",")}>
        <img src={dice2filename(game.player[props.player].dice[props.id])} alt="Dice" {...props.options} />
      </Tooltip>
    </div>
  );
}

function CMessage() {
  const game = useContext(GameContext);
  let txt = "";
  if (game.state === "Vote" && !game.wait[game.you]) txt = "獲得したいチップを選択してください";
  if (game.state === "Dice" && !game.wait[game.you] && game.player[game.you].dice.length > 0) txt = "振りなおしたいダイスを選択してください";
  if (game.state === "Fix") txt = "全員の出目が確定しました";
  if (game.state === "Finish") txt = (game.player.find((p) => p.chip.some((c) => chips[c].kind === "Goal"))?.name ?? "") + "の勝利";
  return <div className="Message">{txt}</div>;
}

function getDices(game: Game, player: number) {
  const normal = [1, 2, 3, 4, 5, 6];
  let ds = [[...normal], [...normal]].concat(game.player[player].chip.flatMap((c) => (chips[c].kind === "Dice" ? [[...chips[c].dice]] : [])));
  return ds;
}

function getRoll(game: Game, player: number) {
  let r = new Random();
  let ds = getDices(game, player);
  const roll = ds.map((d) => shuffle(d, r)[0]);
  return roll;
}

function getReroll(game: Game, player: number, dices: number[]) {
  let r = new Random();
  let ds = getDices(game, player);
  const roll = ds.map((d, i) => (dices.includes(i) ? shuffle(d, r)[0] : game.player[player].dice[i]));
  return roll;
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  return (
    <div className="Action">
      {game.state === "Vote" && !game.wait[game.you] && game.field.some((fc) => fc.id === ui.selectChip) && (
        <Button variant="contained" onClick={() => play(game, { command: "Vote", player: game.you, chip: ui.selectChip })}>
          投票する
        </Button>
      )}
      {game.state === "Dice" && !game.wait[game.you] && game.player[game.you].dice.length === 0 && (
        <Button variant="contained" onClick={() => play(game, { command: "Dice", player: game.you, dice: getRoll(game, game.you), reroll: 0 })}>
          ダイスを振る
        </Button>
      )}
      {game.state === "Dice" && !game.wait[game.you] && game.player[game.you].dice.length > 0 && (
        <Button variant="contained" onClick={() => play(game, { command: "Fix", player: game.you })}>
          振りなおさない
        </Button>
      )}
      {game.state === "Dice" && !game.wait[game.you] && ui.selectDice.length > 0 && ui.selectDice.length <= game.player[game.you].reroll && (
        <Button
          variant="contained"
          onClick={() =>
            play(game, { command: "Dice", player: game.you, dice: getReroll(game, game.you, ui.selectDice), reroll: ui.selectDice.length })
          }
        >
          振りなおす
        </Button>
      )}
      {game.state === "Fix" && !game.wait[game.you] && (
        <Button variant="contained" onClick={() => play(game, { command: "OK", player: game.you })}>
          OK
        </Button>
      )}
    </div>
  );
}

function CField() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);

  function chipProp(fc: FieldChip) {
    if (game.state === "Vote") {
      if (game.wait[game.you]) {
        if (fc.player.includes(game.you)) {
          return { class: "Selected" };
        }
      } else {
        if (
          game.player[game.you].chip.some((c) => chips[c].name === "ユ・バリデル") &&
          game.field.some((f) => chips[f.id].name === "壺") &&
          chips[fc.id].name !== "壺"
        )
          return {};
        const options = { onClick: () => uiDispatch({ type: "Chip", chip: fc.id }) };
        if (game.field.some((fc) => fc.id === ui.selectChip)) {
          if (ui.selectChip === fc.id) {
            return { class: "Selected", options: options };
          } else {
            return { options: options };
          }
        } else {
          return { class: "CanSelect", options: options };
        }
      }
    }
    return {};
  }
  return (
    <>
      {game.deck.length}
      <div className="Field">
        {game.field.map((fc, i) => (
          <div key={i}>
            <CChip id={fc.id} {...chipProp(fc)} />
            {game.state !== "Vote" &&
              fc.player.map((p) => (
                <div key={p} className="Name">
                  {game.player[p].name}
                </div>
              ))}
          </div>
        ))}
      </div>
    </>
  );
}

function CPlayer(props: any) {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);
  const info = game.player[props.id];

  function diceProps(i: number) {
    if (props.id === game.you) {
      const options = { onClick: () => uiDispatch({ type: "Dice", dice: i }) };
      if (ui.selectDice.includes(i)) {
        return { class: "Selected", options: options };
      } else {
        return { class: "CanSelect", options: options };
      }
    }
    return {};
  }

  return (
    <div className={"Player " + (props.id === game.you ? "You" : "Other") + (game.wait[props.id] ? "" : " Wait")}>
      <div>
        <div className="Name">{info.name}</div>
        <div>振り直し:{info.reroll}</div>
        <div className="Dices">
          {info.dice.map((_, i) => (
            <CDice key={i + "_" + info.dice[i]} player={props.id} id={i} {...diceProps(i)} />
          ))}
        </div>
        {info.score !== -1 && <div>{info.score}</div>}
      </div>
      {info.chip.map((c, i) => (
        <CChip key={i} id={c} />
      ))}
    </div>
  );
}

function CGame() {
  const game = useContext(GameContext);

  return (
    <div className="Game">
      <CField />
      <div className="Players">
        {game.player.map((_, i) => (
          <CPlayer key={i} id={i} />
        ))}
        <CMessage />
        <CAction />
      </div>
    </div>
  );
}

function startGame(game: Game, chara: number[], deck: number[]) {
  game.player = game.setting.seat.map((name) => ({
    name: name,
    reroll: 1,
    score: -1,
    chip: [],
    dice: [],
    state: "Want",
  }));
  game.field = chara.map((c) => ({
    id: c,
    player: [],
  }));
  game.deck = deck;
  game.wait = game.player.map(() => false);
  game.rollcount = 1;
  game.stage = 1;
  game.state = "Vote";
  return game;
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [] },
    state: "Init",
    stage: 0,
    you: -1,
    step: 0,
    rollcount: 0,
    player: [],
    deck: [],
    field: [],
    wait: [],
  };
}

function getDeck(game: Game) {
  let r = new Random();
  let chara = chips.flatMap((c, i) => (c.kind === "Chara" ? i : []));
  chara = shuffle(chara, r).slice(0, game.setting.seat.length);
  let deck: number[] = [];
  if (chara.some((c) => chips[c].name === "POP☆OMI")) {
    deck.push(chips.findIndex((c) => c.name === "MINT"));
  }
  if (chara.some((c) => chips[c].name === "ユ・バリデル")) {
    deck.push(chips.findIndex((c) => c.name === "壺"));
  }
  let item = chips.flatMap((c, i) => (["Item", "Dice"].includes(c.kind) && !deck.includes(i) ? i : []));
  item = shuffle(item, r);
  deck = deck.concat(item.slice(0, (game.setting.seat.length - 1) * 3 - deck.length));
  deck = shuffle(deck, r);

  return { deck: deck, chara: chara };
}

function nextStage(game: Game) {
  if (game.stage === 5) {
    game.state = "Finish";
    return game;
  }

  game.player.forEach((_, i) => {
    if (game.player[i].state === "Lose") game.player[i].reroll++;
    game.player[i].state = "Want";
    game.player[i].dice = [];
    game.player[i].score = -1;
  });
  game.stage++;
  game.wait = game.player.map(() => false);

  if (game.deck.length === 0) {
    game.field = [{ id: chips.findIndex((c) => c.kind === "Goal"), player: game.player.map((_, i) => i) }];
    game.state = "Dice";
  } else {
    const nc = game.deck.splice(0, game.player.length - 1);
    game.field = nc.map((c) => ({ id: c, player: [] }));
    game.state = "Vote";
  }
  return game;
}

function fixVote(game: Game) {
  const get = game.field.filter((fc) => fc.player.length === 1);
  game.field = game.field.filter((fc) => fc.player.length !== 1);
  get.forEach((fc) => {
    game.player[fc.player[0]].chip.push(fc.id);
    game.player[fc.player[0]].state = "Get";
  });
  if (game.field.length === 0) {
    game = nextStage(game);
  } else {
    game.wait = game.player.map((p) => p.state === "Get");
    game.state = "Dice";
  }
  return game;
}

function fixScore(game: Game, player: number) {
  game.player[player].score = game.player[player].chip.reduce(
    (acc, cv) => acc + chips[cv].func(game.player[player]),
    game.player[player].dice.reduce((acc, cv) => acc + cv)
  );
  return game;
}

function fixDice(game: Game, player: number) {
  game.wait[player] = true;
  if (game.wait.every((w) => w)) {
    game.wait = game.player.map((p) => p.state !== "Want");
    game.state = "Fix";
  }
  return game;
}

function fixCompare(game: Game) {
  let getC: number[] = [];
  game.field.forEach((fc, i) => {
    const scs = fc.player.map((p) => game.player[p].score);
    if (fc.player.length > 0) {
      const max = scs.reduce((acc, cv) => (acc > cv ? acc : cv));
      fc.player.forEach((p) => {
        if (game.player[p].score < max) game.player[p].state = "Lose";
      });
      if (fc.player.filter((p) => game.player[p].state === "Want").length === 1) {
        const getP = fc.player.find((p) => game.player[p].state === "Want") ?? -1;
        getC.push(fc.id);
        game.player[getP].chip.push(fc.id);
        game.player[getP].state = "Get";
      }
    }
  });
  game.field = game.field.filter((fc) => !getC.includes(fc.id));
  game.player.forEach((_, i) => {
    game.player[i].dice = [];
    game.player[i].score = -1;
  });
  if (game.field.length === 0) {
    game = nextStage(game);
  } else {
    if (game.field.every((fc) => fc.player.length === 0)) {
      //残った人で再投票
      game.wait = game.player.map(() => true);
      game.player.forEach((p, i) => {
        if (p.state === "Lose") {
          game.player[i].state = "Want";
          game.wait[i] = false;
        }
      });
      game.state = "Vote";
    } else {
      // ダイス判定続行
      game.wait = game.player.map((p) => p.state !== "Want");
      game.state = "Dice";
    }
  }
  return game;
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  let ng: Game = JSON.parse(JSON.stringify(game));
  ng.step++;

  console.assert(ng.step >= play.step, game.toString(), play);
  if (ng.step > play.step) return game;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    ng.state = "CloseGame";
    return ng;
  }

  switch (ng.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          ng.setting = JSON.parse(action.setting);
          ng.you = ng.setting.seat.indexOf(ng.env.name);
          return ng;

        case "Start":
          ng = startGame(ng, action.chara, action.deck);
          return ng;
      }
      break;

    case "Vote":
      switch (action.command) {
        case "Vote":
          ng.field.forEach((fc, i) => {
            if (fc.id === action.chip) {
              ng.field[i].player.push(action.player);
            }
          });
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) {
            ng = fixVote(ng);
          }
          return ng;
      }
      break;

    case "Dice":
      switch (action.command) {
        case "Dice":
          ng.rollcount++;
          ng.player[action.player].dice = action.dice;
          ng.player[action.player].reroll -= action.reroll;
          ng = fixScore(ng, action.player);
          if (ng.player[action.player].reroll === 0 || ng.stage === 1) ng = fixDice(ng, action.player);
          return ng;

        case "Fix":
          ng = fixDice(ng, action.player);
          return ng;
      }
      break;

    case "Fix":
      switch (action.command) {
        case "OK":
          ng.wait[action.player] = true;
          if (ng.wait.every((w) => w)) ng = fixCompare(ng);
          return ng;
      }
      break;
  }
  console.error("Unknown State or Action");

  return ng;
}

export const CHakyokuDice = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    console.log("Render", game);
  }, [game]);

  useEffect(() => {
    UICheck(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.state]);

  useEffect(() => {
    soundEffect();
  }, [game.state, game.rollcount]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", ...getDeck(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="HakyokuDice">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="破局ダイス" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  return (
    <div className="Help">
      <h2>破局ダイス</h2>
      <p>
        ゲームデザイン:
        <a href="https://twitter.com/uracon_" target="_blank" rel="noreferrer">
          uracon
        </a>
      </p>
      <p>
        <a href="https://gamemarket.jp/game/131894" target="_blank" rel="noreferrer">
          商品情報
        </a>
        (現在は頒布されていません) <br />
        <a href="https://dice-d4012.web.app/" target="_blank" rel="noreferrer">
          スマホアプリ版 破局ダイス
        </a>
      </p>
      <p>ルールブック</p>
      <img style={{ border: "double black 2px" }} src={process.env.PUBLIC_URL + "/hakyokudice/doc1.png"} alt="doc" />
      <img style={{ border: "double black 2px" }} src={process.env.PUBLIC_URL + "/hakyokudice/doc2.png"} alt="doc" />
      <div>
        <h4>チップ一覧</h4>
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip0.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip1.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip2.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip3.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip4.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip5.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip6.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip7.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip8.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip9.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip10.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip11.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip12.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip13.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip14.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip15.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip16.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip17.png"} />
        <img alt="helpImg" style={{ width: 150, margin: 1 }} src={process.env.PUBLIC_URL + "/hakyokudice/chip18.png"} />
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/hakyokudice/help1.png"} /> <br />
      </div>
    </div>
  );
}

export const HakyokuDice = {
  name: "破局ダイス",
  id: "HakyokuDice",
  people: [3, 5],
  settingDef: settingDef,
  component: CHakyokuDice,
  help: CHelp,
};
