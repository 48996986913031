import "./Uma.css";
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useDB, play, GameSettingButton, Env, Play, soundEffect } from "../GameCommand";
import { Button } from "@material-ui/core/";
import { Random, shuffle } from "../lib/xs";

interface Setting {
  seat: string[];
}

const settingDef = [];

interface Player {
  name: string;
  money: number;
  dice: number[];
  bet: number[];
}

interface Card {
  suit: string;
  power: number; // 0～12 = 2,3,4,,,10,J,Q,K,A
}

type CardID = number;

const suits = ["Spade", "Club", "Heart", "Diamond"];
const cards: Card[] = initDeck();

interface Hand {
  kind: string;
  power: number[];
}

interface Horse {
  card: number[];
  hand: Hand;
  win: boolean;
}

interface Game {
  env: Env;
  setting: Setting;
  state: string;
  you: number;
  step: number;
  player: Player[];
  horse: Horse[];
  community: number[];
  prediction: number[];
  race: number[];
  round: number;
  result: boolean;
  turnPlayer: number;
  wait: any[];
  tmp: any;
}

const GameContext = React.createContext({} as Game);

interface UI {
  bet: number[];
}

function initUI() {
  return { bet: [] };
}

const UIDispatchContext = React.createContext((() => true) as any);
const UIContext = React.createContext({} as UI);

function UIReducer(state: UI, action: any): UI {
  // console.log("Reducer", state, action);
  let nUI: UI = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Init":
      return initUI();

    case "Bet":
      nUI.bet = nUI.bet.includes(action.bet) ? nUI.bet.filter((b) => b !== action.bet) : nUI.bet.concat(action.bet);
      break;
  }

  return nUI;
}

function UIStepChange(game: Game, dispatch) {
  dispatch({ type: "Init" });
}

function CDice(props: { num: number; class?: string }) {
  return (
    <div className={"Dice " + (props.class ?? "")}>
      <img src={process.env.PUBLIC_URL + "/dice/" + props.num + ".png"} alt={"dice" + props.num} />
    </div>
  );
}

function card2filename(c: Card, side: boolean): string {
  let name = "";
  if (c.suit === "Jocker") name = "jk0";
  else name = ["s", "c", "h", "d"][suits.indexOf(c.suit)] + ("00" + (((c.power + 13 + 1) % 13) + 1)).slice(-2);
  if (!side) name = "bk0";
  return process.env.PUBLIC_URL + "/trump/" + name + ".png";
}

function CCard(props: { c: CardID; side: boolean; options?: any }) {
  return (
    <div className="Card">
      <img src={card2filename(cards[props.c], props.side)} alt="Card" {...props.options} />
    </div>
  );
}

function CField() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const uiDispatch = useContext(UIDispatchContext);

  function getOp(i: number) {
    let ret: any = { className: "Horse" };

    if (game.state === "Bet") {
      if (!game.wait[game.you]) {
        if (ui.bet.includes(i)) {
          ret.className += " Selected";
        }
        ret["onClick"] = () => {
          uiDispatch({ type: "Bet", bet: i });
        };
      } else {
        if (game.player[game.you].bet.includes(i)) {
          ret.className += " Selected";
        }
      }
    } else {
      if (game.player.some((p) => p.bet.includes(i))) {
        ret.className += " Selected";
      }
    }
    if (game.horse[i].win) {
      ret.className += " Win";
    }

    return ret;
  }
  return (
    <div className="Field">
      第{game.round}レース
      <div className="Horses">
        {game.horse.map((h, i) => (
          <div key={i} {...getOp(i)}>
            <div>{i + 1}番</div>
            <div className="Cards">
              {h.card.map((c, i) => (
                <CCard c={c} side={true} key={i} />
              ))}
            </div>
            {game.community.length >= 3 && h.hand.kind}
          </div>
        ))}
      </div>
    </div>
  );
}

function CPrediction() {
  const game = useContext(GameContext);

  if (game.state !== "Bet") return null;

  return (
    <div className="Prediction">
      展開予想
      <div className="Cards">
        {game.prediction.map((c, i) => (
          <div key={i}>
            <CCard c={c} side={game.player[game.you].dice.includes(i + 1)} />
            <CDice num={i + 1} />
          </div>
        ))}
      </div>
    </div>
  );
}

function CRace() {
  const game = useContext(GameContext);

  if (!["Race", "Result"].includes(game.state)) return null;
  const txt = ["レース開始", "第1コーナー", "第2コーナー", "第3コーナー", "最終コーナー", "決着"][game.community.length];
  return (
    <div className="Race">
      {txt}
      <div className="Cards">
        {game.community.map((c, i) => (
          <div>
            <CCard c={c} side={true} key={i} />
            {game.community.length === 5 && <CDice num={game.race[i] + 1} key={"D"+i}/>}
          </div>
        ))}
      </div>
    </div>
  );
}

function CPlayer(props: any) {
  const game = useContext(GameContext);
  const info = game.player[props.id];
  let cname = "Player";
  if (props.id === game.you) cname += " You";
  if (["Bet", "Result"].includes(game.state) && !game.wait[props.id]) cname += " Wait";
  if (game.state === "Race" && props.id === game.turnPlayer) cname += " Wait";

  return (
    <div key={info.name} className={cname}>
      <div className="Name">{info.name}</div>
      <div className="Dices">
        {info.dice.map((d, i) => (
          <CDice key={i + "" + d} num={d} />
        ))}
      </div>
      {game.state === "Bet" ? <div>所持金{info.money}</div> : <div>{"賭:" + info.bet.map((b) => b + 1).join()}</div>}
    </div>
  );
}

function CAction() {
  const game = useContext(GameContext);
  const ui = useContext(UIContext);
  const p = game.player[game.you];

  return (
    <div className="Action">
      {game.state === "Bet" && !game.wait[game.you] && ui.bet.length <= p.money && (
        <Button variant="contained" onClick={() => play(game, { command: "Bet", player: game.you, bet: ui.bet })}>
          賭ける馬を決定する
        </Button>
      )}
      {game.state === "Race" && game.turnPlayer === game.you && (
        <Button variant="contained" onClick={() => play(game, { command: "Go", player: game.you })}>
          レースを進める
        </Button>
      )}
      {game.state === "Result" && !game.wait[game.you] && (
        <Button variant="contained" onClick={() => play(game, { command: "OK", player: game.you, ...startProps(game) })}>
          OK
        </Button>
      )}
    </div>
  );
}

function CMessage() {
  const game = useContext(GameContext);
  let txt = "";
  if (game.state === "Bet" && !game.wait[game.you]) txt = "賭ける馬を" + game.player[game.you].money + "頭まで選択してください";
  if (game.state === "Result") txt = game.result ? "的中！" : "的中せず！ ゲームオーバー";
  if (game.state === "Result" && game.result && game.round === 3) txt += " ゲームクリア！";
  return <div className="Message">{txt}</div>;
}

function CGame() {
  const game = useContext(GameContext);

  return (
    <div className="Game">
      <div>
        <CField />
        <CPrediction />
        <CRace />
        <CMessage />
        <CAction />
      </div>
      <div className="Players">
        {game.player.map((_, i) => (
          <CPlayer key={i} id={i} />
        ))}
      </div>
    </div>
  );
}

function nextPlayer(game: Game, base: number, diff: number = 1): number {
  return (base + diff) % game.setting.seat.length;
}

function initDeck() {
  let deck = suits.map((s) => [...Array(13)].map((_, i) => ({ suit: s, power: i }))).flat();
  return deck;
}

function newDeck(game: Game) {
  let r = new Random();
  const deck = shuffle(
    [...Array(cards.length)].map((_, i) => i),
    r
  );
  return deck;
}

function newRace() {
  let r = new Random();
  return shuffle([0, 1, 2, 3, 4, 5], r);
}

function newDice(game: Game) {
  let r = new Random();
  return game.setting.seat.map(() =>
    shuffle([1, 2, 3, 4, 5, 6], r)
      .splice(0, 3)
      .sort((a, b) => a - b)
  );
}

// aの方が強いと負, bの方が強いと正
function compHand(a: Hand, b: Hand) {
  for (let i = 0; i < a.power.length; i++) {
    if (a.power[i] !== b.power[i]) return b.power[i] - a.power[i];
  }
  return 0;
}

function getHand(game: Game, cardids: CardID[]) {
  const cs = cardids.map((c) => cards[c]).sort((a, b) => b.power - a.power);

  function comb(arr: Card[], fix: Card[]) {
    let ret: Card[][] = [];
    if (fix.length === 5) {
      return [[...fix]];
    }
    if (arr.length + fix.length < 5) {
      return [];
    }
    ret = ret.concat(comb(arr.slice(1), [...fix, arr[0]]));
    ret = ret.concat(comb(arr.slice(1), [...fix]));
    return ret;
  }
  const css = comb(cs, []);
  const hands: Hand[] = css.map((hand) => {
    const flush = hand.every((h) => h.suit === hand[0].suit);
    const straight =
      hand[1].power === hand[0].power - 1 &&
      hand[2].power === hand[0].power - 2 &&
      hand[3].power === hand[0].power - 3 &&
      hand[4].power === hand[0].power - 4;
    const straight5 = hand[0].power === 12 && hand[1].power === 3 && hand[2].power === 2 && hand[3].power === 1 && hand[4].power === 0; // A,5,4,3,2

    if (flush && straight) {
      return { kind: "ストレートフラッシュ", power: [8, hand[0].power] };
    }

    if (flush && straight5) {
      return { kind: "ストレートフラッシュ", power: [8, hand[1].power] };
    }

    const ns = [...Array(13)].map((_, i) => hand.filter((h) => h.power === i).length);
    if (ns.some((n) => n === 4)) {
      const p = ns.indexOf(4);
      const kicker = hand.find((h) => h.power !== p)?.power ?? 0;
      return { kind: "フォーカード", power: [7, p, kicker] };
    }
    if (ns.some((n) => n === 3) && ns.some((n) => n === 2)) {
      const p3 = ns.indexOf(3);
      const p2 = ns.indexOf(2);
      return { kind: "フルハウス", power: [6, p3, p2] };
    }

    if (flush) {
      return { kind: "フラッシュ", power: [5, hand[0].power, hand[1].power, hand[2].power, hand[3].power, hand[4].power] };
    }

    if (straight) {
      return { kind: "ストレート", power: [4, hand[0].power] };
    }

    if (straight5) {
      return { kind: "ストレート", power: [4, hand[1].power] };
    }

    if (ns.some((n) => n === 3)) {
      const p = ns.indexOf(3);
      const kicker = hand.filter((h) => h.power !== p);
      return { kind: "スリーカード", power: [3, p, kicker[0].power, kicker[1].power] };
    }

    if (ns.filter((n) => n === 2).length === 2) {
      const p2 = ns.indexOf(2);
      const p1 = ns.findIndex((n, i) => n === 2 && i !== p2);
      const kicker = hand.find((h) => ![p1, p2].includes(h.power))?.power ?? 0;
      return { kind: "ツーペア", power: [2, p1, p2, kicker] };
    }

    if (ns.filter((n) => n === 2).length === 1) {
      const p = 12 - [...ns].reverse().indexOf(2);
      const kicker = hand.filter((h) => h.power !== p);
      return { kind: "ワンペア", power: [1, p, kicker[0].power, kicker[1].power, kicker[2].power] };
    }

    return { kind: "ハイカード", power: [0, hand[0].power, hand[1].power, hand[2].power, hand[3].power, hand[4].power] };
  });

  hands.sort(compHand);
  return hands[0];
}

function newGame(game: Game, action: any) {
  game.player.forEach((_, i) => {
    game.player[i].dice = action.dice[i];
    game.player[i].bet = [];
  });
  game.prediction = action.deck.splice(0, 6);
  game.horse = [...Array(12)].map((_, i) => ({ card: action.deck.splice(0, 2), hand: { kind: "", power: [] }, win: false }));
  game.community = [];
  game.race = action.race;
  game.wait = game.player.map(() => false);
  game.state = "Bet";

  return game;
}

function nextGame(game: Game, action: any) {
  game.round++;
  game = newGame(game, action);
  return game;
}

function startProps(game: Game) {
  return { deck: newDeck(game), race: newRace(), dice: newDice(game) };
}

const moneyTable = [6, 5, 4, 4, 3, 3, 3, 2, 2, 2, 2]; // 2-12人
function startGame(game: Game, action: any) {
  const money = moneyTable[game.setting.seat.length - 2];
  game.player = game.setting.seat.map((s, i) => ({ name: s, bet: [], dice: [], money: money }));
  game.round = 1;
  game.turnPlayer = 0;
  game = newGame(game, action);
  return game;
}

function initGame(env: any): Game {
  console.log("init");

  return {
    env: env,
    setting: { seat: [] },
    state: "Init",
    you: -1,
    step: 0,
    player: [],
    community: [],
    horse: [],
    prediction: [],
    race: [],
    round: 0,
    turnPlayer: 0,
    result: false,
    wait: [],
    tmp: undefined,
  };
}

function stepPlay(game: Game, play: Play): Game {
  console.log("step", game, play);
  game = JSON.parse(JSON.stringify(game));

  console.assert(game.step < play.step, game.toString(), play);
  if (game.step >= play.step) return game;

  game.step++;
  let action: any = JSON.parse(play.action);

  if (action.command === "CloseGame") {
    game.state = "CloseGame";
    return game;
  }

  switch (game.state) {
    case "Init":
      switch (action.command) {
        case "Setting":
          console.log("change setting", action.setting);
          game.setting = JSON.parse(action.setting);
          game.you = game.setting.seat.indexOf(game.env.name);
          return game;

        case "Start":
          game = startGame(game, action);
          return game;
      }
      break;

    case "Bet":
      switch (action.command) {
        case "Bet":
          game.player[action.player].bet = action.bet.sort((a, b) => a - b);
          game.player[action.player].money -= action.bet.length;
          game.wait[action.player] = true;
          if (game.wait.every((w) => w)) {
            game.state = "Race";
          }
          return game;
      }
      break;

    case "Race":
      switch (action.command) {
        case "Go":
          game.community.push(game.prediction[game.race[game.community.length]]);
          if (game.community.length >= 3) {
            game.horse = game.horse.map((h) => ({ ...h, hand: getHand(game, [...h.card, ...game.community]), win: false }));
            let wins = game.horse.map((h, i) => ({ id: i, hand: h.hand }));
            wins.sort((a, b) => compHand(a.hand, b.hand));
            wins
              .filter((w) => compHand(w.hand, wins[0].hand) === 0)
              .forEach((w) => {
                game.horse[w.id].win = true;
              });
          }
          game.turnPlayer = nextPlayer(game, game.turnPlayer);
          if (game.community.length === 5) {
            game.result = game.horse.every((h, i) => !h.win || game.player.some((p) => p.bet.includes(i)));
            if (game.round !== 3 && game.result) game.wait = game.player.map((_) => false);
            game.state = "Result";
          }
          return game;
      }
      break;

    case "Result":
      switch (action.command) {
        case "OK":
          game.wait[action.player] = true;
          if (game.wait.every((w) => w)) {
            game = nextGame(game, action);
          }
          return game;
      }
      break;
  }
  console.error("Unknown State or Action");

  return game;
}

const CUma = (props: any) => {
  const [game, setGame] = useState(() => initGame(props.env));
  const [ui, uiDispatch] = useReducer(UIReducer, undefined, initUI);

  useDB({ env: props.env, setGame: setGame, step: stepPlay });

  useEffect(() => {
    console.log("Render", game);
  }, [game]);

  useEffect(() => {
    UIStepChange(game, uiDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.state]);

  useEffect(() => {
    soundEffect();
  }, [game.turnPlayer, game.state]);

  useEffect(() => {
    if (game.state === "CloseGame") {
      //他コンポーネントのStateを変更するのでEffect内でやる必要がある。
      props.setEnv((s: any) => ({ ...s, game: "", seed: -1 }));
    }
    if (game.state === "Init" && game.you === 0) {
      play(game, { command: "Start", ...startProps(game) });
    }
  });
  if (game.state === "CloseGame") return <></>;

  return (
    <div className="Uma">
      <UIContext.Provider value={ui}>
        <UIDispatchContext.Provider value={uiDispatch}>
          <GameContext.Provider value={game}>{game.state === "Init" ? <div className="Game">準備中</div> : <CGame />}</GameContext.Provider>
        </UIDispatchContext.Provider>
      </UIContext.Provider>
      <GameSettingButton name="一蓮単勝" game={game} />
    </div>
  );
};

function CHelp(props: any) {
  const tableBorder = { border: "1px solid black" };
  return (
    <div className="Help">
      <h2>一蓮単勝</h2>
      <p>ゲームデザイン: TAGAオリジナル</p>
      <div>
        <h4>概要</h4>
        一蓮単勝は協力ゲームです。
        <br />
        プレイヤー達は競馬予想サークルのメンバーです。 <br />
        サークル対抗戦の為、これから行われる3レースの的中馬券がどうしても必要になりました。 <br />
        協力して全レースの勝ち馬を的中させることが目標です。
      </div>
      <div>
        <h4>ゲームの流れ</h4>
        1. 準備 <br />
        ジョーカーを除くトランプ1セット52枚が用意されます。 <br />
        以下の表に従い、全てのプレイヤーに所持金が配られます。この所持金は全3レースでそのプレイヤーが賭けられる馬の総数になります。
        <table style={{ borderCollapse: "collapse" }}>
          <tr>
            <th style={tableBorder}>人数</th>
            {moneyTable.map((_, i) => (
              <td style={tableBorder}>{i + 2}</td>
            ))}
          </tr>
          <tr>
            <th style={tableBorder}>所持金</th>
            {moneyTable.map((_, i) => (
              <td style={tableBorder}>{moneyTable[i]}</td>
            ))}
          </tr>
        </table>
        <br />
        2. レース準備 <br />
        12頭の馬に対して、トランプが2枚ずつ配られます。これが馬のコンディションを表します。 <br />
        6枚のトランプが場に伏せられます。 これがレースの展開予想を表します。順番に1-6の数字が割り当てられます。 <br />
        各プレイヤーはサイコロ3個を、すべて違う値になるまで振りなおします。 <br />
        <br />
        3. レース予想 <br />
        各プレイヤーは、自分のサイコロの目に対応した展開予想カードを見ることが出来ます。 <br />
        これはプレイヤーが独自に仕入れることができた極秘情報になります。 <br />
        各馬のコンディションと展開予想を見て、最終的な勝ち馬を予想してください。(勝ち負けについては後述) <br />
        所持金と同じ頭数まで賭けることができますが、全3レースあることに注意してください。 <br />
        プレイヤー間で相談する際には、
        <b>「展開予想の内容」「どの馬に賭けるか」を口に出してはいけません。(ライバルサークルが聴いているかもしれないため)</b>
        <br />
        抽象的な相談でなんとか協力してください。
        <br />
        賭けたい馬の枠内をクリックすると選択済み(二重枠)になります。選択が終わったら[賭ける馬を決定する]ボタンを押してください。 <br />
        賭けた馬の数だけ、所持金が引かれます。 <br />
        全プレイヤーが賭け終わると、 4. レース進行に移ります。 <br />
        <br />
        4. レース進行 <br />
        全てのプレイヤーの賭けた内容が公開されます。 <br />
        展開予想カード6枚の中からランダムに、1枚ずつ場に並べていきます。これが実際のレース展開を表します。 <br />
        [レースを進める]ボタンが表示されているプレイヤーがボタンを押すと進行していきます。 <br />
        5枚のカードが並べられると、5. 判定 に移ります。 <br />
        <br />
        5. 判定 <br />
        馬の勝ち負けは、コンディション2枚とレース展開5枚の合計7枚の中から5枚を組み合わせて作られるもっとも強いポーカーの役で決まります。 <br />
        (参考:{" "}
        <a
          href="https://ja.wikipedia.org/wiki/%E3%83%9D%E3%83%BC%E3%82%AB%E3%83%BC%E3%83%BB%E3%83%8F%E3%83%B3%E3%83%89%E3%81%AE%E4%B8%80%E8%A6%A7"
          target="_blank"
          rel="noreferrer"
        >
          ポーカー・ハンドの一覧 - Wikipedia
        </a>
        ) <br />
        一番強い役の馬(赤く表示されます)に賭けたプレイヤーが居た場合は、的中となり次のレースの(2. レース準備)に進むことができます。 <br />
        一番強い役の馬が複数居た場合は、その全てに賭けられていないと的中にはなりません。 <br />
      </div>
      <div>
        <h4>ゲーム全体の勝敗</h4>
        第3レースまですべて的中させるとプレイヤーの勝利です。 <br />
        1レースでも予想を外してしまうとゲームオーバーです。 <br />
      </div>
      <div>
        <h4>画面説明</h4>
        <img alt="helpImg" src={process.env.PUBLIC_URL + "/uma/help1.png"} /> <br />
      </div>
    </div>
  );
}

export const Uma = {
  name: "一蓮単勝",
  id: "Uma",
  people: [2, 12],
  settingDef: settingDef,
  component: CUma,
  help: CHelp,
};
